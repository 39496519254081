import React, { useState } from 'react';
import styled from 'styled-components';
import { FiSearch, FiEye, FiEdit, FiTrash2 } from 'react-icons/fi'; // Using react-icons library

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  background-color: #f5f5f5;
  box-shadow: 0px 5px 10px #00000038;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormItem = styled.div`
  flex: 1 1 300px; /* Flex-grow, flex-shrink, and minimum width */
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', 'Semibold';
  color: #2B3497;
  margin-bottom: 5px;
  font-weight: 600;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', 'Regular';
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  &:first-child {
    background-color: #FF4B4B;
    box-shadow: 0px 10px 20px #7E18182E;
  }
  &:last-child {
    background-color: #2B3497;
    box-shadow: 0px 10px 20px #2B349752;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  color: #2B3497;
  margin: 0 5px;
`;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;

const ConfigurationPage = () => {
  const [formValues, setFormValues] = useState({
    configurationName: '',
    configurationValue: '',
    configurationType: '',
  });

  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = () => {
    if (formValues.configurationName && formValues.configurationValue && formValues.configurationType) {
      setTableData((prevData) => [...prevData, formValues]);
      setFormValues({
        configurationName: '',
        configurationValue: '',
        configurationType: '',
      });
      setShowPagination(true); // Show pagination after submitting at least one row
    }
  };

  const handleDelete = (index) => {
    setTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleReset = () => {
    setFormValues({
      configurationName: '',
      configurationValue: '',
      configurationType: '',
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredData = tableData.filter((row) =>
    row.configurationName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <Container>
      <Header>
        <Heading>Configuration</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <Card>
        <FormGroup>
          <FormItem>
            <Label>
              Configuration Name<Asterisk>*</Asterisk>
            </Label>
            <Input
              type="text"
              name="configurationName"
              value={formValues.configurationName}
              onChange={handleInputChange}
              placeholder="Enter configuration name"
            />
          </FormItem>
          <FormItem>
            <Label>
              Configuration Value<Asterisk>*</Asterisk>
            </Label>
            <Input
              type="text"
              name="configurationValue"
              value={formValues.configurationValue}
              onChange={handleInputChange}
              placeholder="Enter configuration value"
            />
          </FormItem>
          <FormItem>
            <Label>
              Configuration Type<Asterisk>*</Asterisk>
            </Label>
            <Input
              type="text"
              name="configurationType"
              value={formValues.configurationType}
              onChange={handleInputChange}
              placeholder="Enter configuration type"
            />
          </FormItem>
        </FormGroup>
        <ButtonGroup>
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </ButtonGroup>
      </Card>
      {filteredData.length === 0 ? (
        <ZeroStateMessage>No data available</ZeroStateMessage>
      ) : (
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Config Name</TableHeader>
                <TableHeader>Config Value</TableHeader>
                <TableHeader>Config Type</TableHeader>
                <TableHeader>Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.configurationName}</TableCell>
                  <TableCell>{row.configurationValue}</TableCell>
                  <TableCell>{row.configurationType}</TableCell>
                  <TableCell>
                    <IconWrapper onClick={() => alert(`View ${row.configurationName}`)}>
                      <FiEye />
                    </IconWrapper>
                    <IconWrapper onClick={() => alert(`Edit ${row.configurationName}`)}>
                      <FiEdit />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleDelete(index)}>
                      <FiTrash2 />
                    </IconWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <PaginationContainer showPagination={filteredData.length > 7}>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationButton
                key={index}
                className={currentPage === index + 1 ? 'active' : ''}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </PaginationButton>
            ))}
          </PaginationContainer>
        </TableContainer>
      )}
    </Container>
  );
};

export default ConfigurationPage;
