// UsernameContext.js
// import React, { createContext, useState } from 'react';

// const UsernameContext = createContext();

// export const UsernameProvider = ({ children }) => {
//   const [username, setUsername] = useState('');

//   return (
//     <UsernameContext.Provider value={{ username, setUsername }}>
//       {children}
//     </UsernameContext.Provider>
//   );
// };

// export default UsernameContext;
// UsernameContext.js
//LocalStorage
import React, { createContext, useState, useEffect } from 'react';

const UsernameContext = createContext();

export const UsernameProvider = ({ children }) => {
  const [username, setUsername] = useState(() => {
    // Get initial value from localStorage
    return localStorage.getItem('username') || '';
  });

  useEffect(() => {
    // Save username to localStorage whenever it changes
    localStorage.setItem('username', username);
  }, [username]);

  return (
    <UsernameContext.Provider value={{ username, setUsername }}>
      {children}
    </UsernameContext.Provider>
  );
};

export default UsernameContext;
