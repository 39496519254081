import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0px);
  z-index: 1000; /* Ensure it's above other content */
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
  width: 468px;
  height: 563px;
  border-radius: 8px;
  font-family:Segoe UI, Semibold;
  opacity: 1;
  z-index: 1001; /* Ensure it's above the overlay */
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding: 20px;
  overflow: hidden; /* Ensure content does not overflow */
`;

const ModalHeader = styled.h2`
  text-align: center;
  color: #293495;
  margin-bottom: 20px;
`;

const Box = styled.div`
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  &:hover {
    border-color: #293495;
  }
`;

const BoxHeader = styled.h3`
  color: #000000;
  margin-bottom: 10px;
  font-size: 25px
`;

const AddressText = styled.p`
  color: #000000;
  margin-bottom: 10px;
 font-size: 20px;
 font-weight:600;

`;

const UrlText = styled.p`
  color: #293495;
  margin-bottom: 20px;
  font-size:24px;
  font-weight:600;
  text-decoration:underline;
`;

const Button = styled.button`
  background-color: #293495;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  display: block;
  margin: 0 auto; /* Center the button */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #293495;
`;

const AboutModal = ({ isOpen, onClose }) => {
  return (
    <ModalBackground isOpen={isOpen} onClick={onClose}>
      <ModalContainer isOpen={isOpen} onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <ModalHeader>About</ModalHeader>
        <ModalHeader>SECOREX</ModalHeader>
        <Box>
          <BoxHeader>Fidrox Technologies Pvt Ltd</BoxHeader>
          <AddressText>
            48/13, 3rd floor, 3rd main, 40th cross,<br />
            Jayanagar 8th block, Bangalore - 560082,<br />
            Phone: +91-8026540004
          </AddressText>
          <UrlText>www.fidrox.com</UrlText>
          <UrlText>info@fidrox.com</UrlText>

        </Box>
        <Button onClick={onClose}>OK</Button>
      </ModalContainer>
    </ModalBackground>
  );
};

export default AboutModal;
