import React, { useState, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Webcam from 'react-webcam'; // Import react-webcam
import { User } from 'react-feather'; // Import the User icon
import axios from 'axios';
import { fetchVisitorTypes } from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 25px;
  font-family: 'Segoe UI', Semibold;
  color: #060000;
  margin: 0;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 81px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px #2b349754;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Semibold;
`;

const ResetButton = styled(Button)`
  background-color: red;
`;

const SubmitButton = styled(Button)`
  background-color: #2b3497;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  flex: 1;
  background: radial-gradient(circle, #ffffff, #e3e3e3);
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 15px;
  padding: 27px;
  opacity: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', Semibold;
  color: #000000;
  margin-bottom: 5px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 335px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2b3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2b3497;
    }
  }
     @media (max-width: 1920px) and (min-width: 1720px) {
       width: 545px;

  }
       @media (max-width: 1720px) and (min-width: 1420px) {
       width: 390px;

  }
         @media (max-width: 1420px) and (min-width: 1220px) {
       width: 300px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
       width: 200px;

  }
`;

const ProfileCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  width: 131px;
  height: 131px;
  background: linear-gradient(#ffffff, #f6f6f6);
  border-radius: 50%;
  box-shadow: 0px 10px 10px #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0; /* Center align the circle */
  position: relative;
`;

const ProfileIcon = styled.div`
  width: 134px;
  height: 131px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b3497;
  font-size: 30px;
  font-weight: bold;
  position: relative;
`;

const PlusIcon = styled.div`
  width: 47px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BFBFBF;
  font-size: 44px;
  font-weight: 700;
`;

const ProfileText = styled.div`
  font-family: 'Segoe UI', Semibold;
  font-size: 18px;
  color: #ff3030;
  margin-bottom: 20px;
`;

const PhotoButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: #343d9b;
  box-shadow: 0px 10px 10px #2b349733;
  border: 2px solid #343d9b;
  border-radius: 8px;
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: transparent;
  border: 2px solid #343d9b;
  border-radius: 8px;
  box-shadow: 0px 10px 10px #2b349733;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-family: 'Segoe UI', Semibold;
  font-size: 14px;
`;

const PrivacyLink = styled.a`
  color: #2b3497;
  margin-left: 5px;
  text-decoration: none;
`;
const WebcamContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
`;
const ButtonRow = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
  margin-bottom: 20px; /* Optional: Adjust margin bottom */
`;

const CheckinVisitorFormC = () => {
  const location = useLocation();
  const { checkType } = location.state || {}; // Destructure
  // Retrieve mobileNumber from localStorage
  const navigate = useNavigate(); // Initialize useNavigate hook
  const storedMobileNumber = localStorage.getItem('mobileNumber');
  const storedVehicleNo = localStorage.getItem('vehicleNo');
  const storedMeetingOn = localStorage.getItem('meetingOn');
  const storedMeetingTo = localStorage.getItem('meetingTo');
  const storedGate = localStorage.getItem('gate');
  const storedBuilding = localStorage.getItem('building');
  const storedPurpose = localStorage.getItem('purpose');
  const storedEmpId = localStorage.getItem('employeeId'); // Retrieve empId

  const [checkinData, setCheckinData] = useState('');
  const [visitorTypes, setVisitorTypes] = useState([]);

  const [formData, setFormData] = useState({
    visitorName: '',
    visitorEmail: '',
    visitorMobile: '',
    selectedVisitorTypeId: '',
    company: '',
    meetingFrom: null,
    meetingTo: null,
    meetingVenue: '',
    assets: '',
    vehicleno: '',
    remarks: '',
    visitorPhoto: ''

  });

  const [formErrors, setFormErrors] = useState({
    visitorName: false,
    visitorEmail: false,
    visitorMobile: false,
  });
  useEffect(() => {
    const getVisitorTypes = async () => {
      try {
        const types = await fetchVisitorTypes();
        setVisitorTypes(types);
      } catch (error) {
        console.error('Error fetching visitor types:', error);
      }
    };
    getVisitorTypes();
  }, []);


  useEffect(() => {
    console.log("CheckType : ", checkType);

    if (storedMobileNumber) {
      // Function to fetch appointment data
      const fetchAppointmentData = async () => {
        try {
          const response = await axios.get(`/VisitorDetails/GetVisitorDetailsPrePopluate?VisitorMob=${storedMobileNumber}`);

          if (response.status === 200) {
            setCheckinData(response.data);
            console.log('Check-in details prepopulated:', response.data);
          } else {
            console.error('Failed to fetch appointment data');
          }
        } catch (error) {
          console.error('Error fetching appointment data:', error);
        }
      };

      fetchAppointmentData();
    }
  }, []); // Run this effect only once when the component mounts

  useEffect(() => {
    if (checkinData) {
      console.log("checkinData:", checkinData)
      const formatDate = (date) => {
        const padTo2Digits = (num) => num.toString().padStart(2, '0');

        return (
          date.getFullYear() +
          '-' +
          padTo2Digits(date.getMonth() + 1) +
          '-' +
          padTo2Digits(date.getDate()) +
          'T' +
          padTo2Digits(date.getHours()) +
          ':' +
          padTo2Digits(date.getMinutes()) +
          ':' +
          padTo2Digits(date.getSeconds())
        );
      };
      const currentDateTime = formatDate(new Date()); // Get the current date and time in ISO 8601 format
      // Update formData with appointmentData fields
      setFormData({
        visitorName: checkinData.visitorName || '',
        visitorEmail: checkinData.emailId || '',
        visitorMobile: checkinData.visitorMobile || '',
        selectedVisitorTypeId: checkinData.selectedVisitorTypeId || '',
        company: checkinData.visitorCompany || '',
        meetingFrom: storedMeetingOn,
        meetingTo: storedMeetingTo,
        meetingVenue: checkinData.meetingVenue || '',
        employeeName: checkinData.hostEmail || '',
        employeeMobile: checkinData.hostMobile || '',
        vehicleno: storedVehicleNo || '',
        visitorPhoto: checkinData.visitorPhoto || '',
        checkInId: checkinData.checkInId,
        appointmentId: checkinData.appointmentId,
        hostName: storedEmpId || '', // Bind empId to hostName
        //  checkinType:checkinData.checkinType,
        checkinType: checkType,
        checkInDateTime: currentDateTime,
        txtFloor: `${storedPurpose}, ${storedGate}, ${storedBuilding}` // Format txtFloor as a string
      });
    }
  }, [checkinData]); // Run this effect whenever checkinData changes
  const handleReset = () => {
    setFormData({
      // visitorName: '',
      // visitorEmail: '',
      // visitorMobile: '',
      visitorType: '',
      company: '',
      // meetingFrom: null,
      // meetingTo: null,
      meetingVenue: '',
      assets: '',
      vehicleno: '',
      remarks: '',
    });
    setFormErrors({
      visitorName: false,
      visitorEmail: false,
      visitorMobile: false,
    });
    console.log('Form reset');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    const errors = {};

    if (formData.visitorName === '') {
      errors.visitorName = true;
      valid = false;
    }
    if (formData.visitorEmail === '') {
      errors.visitorEmail = true;
      valid = false;
    }
    if (formData.visitorMobile === '') {
      errors.visitorMobile = true;
      valid = false;
    }

    if (formData.visitorEmail !== '') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.visitorEmail)) {
        errors.visitorEmail = true;
        valid = false;
      }
    }

    if (formData.visitorMobile !== '') {
      const mobilePattern = /^\d{10}$/;
      if (!mobilePattern.test(formData.visitorMobile)) {
        errors.visitorMobile = true;
        valid = false;
      }
    }

    if (!valid) {
      setFormErrors(errors);
      console.log('Form has errors:', errors);
    } else {
      try {
        // Send form data to /api/VisitorDetails
        const response = await axios.post('/VisitorDetails', formData);

        if (response.status === 200) {
          const checkInId = response.data; // Assuming response contains the check-in ID
          console.log('Check-in ID:', checkInId);
          const Stat = "IN"
          console.log("Stat", Stat);
          const AppId = checkinData.appointmentId

          const updateAppoinmentStatus = await axios.post(`/Appointment/AppointStatusUpd/${AppId},${Stat}`);
          console.log('update AppoinmentStatus API:', updateAppoinmentStatus);

          // Fetch visitor details using the check-in ID
          const visitorResponse = await axios.get(`/VisitorDetails/${checkInId}`);

          if (visitorResponse.status === 200) {
            console.log('Visitor details:', visitorResponse.data);
            navigate('/layout/CheckIn/MenuItem');
            // Handle the response data, e.g., display details or update state
          } else {
            console.error('Failed to fetch visitor details');
          }
        } else {
          console.error('Failed to submit form');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const webcamRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Take photo');

  const handleCapture = () => {
    if (!isCameraOn) {
      setIsCameraOn(true);
      setButtonLabel('Capture');
    } else {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log('Captured Image:', imageSrc);
      setIsCameraOn(false);
      setButtonLabel('Take photo');
    }
  };


  return (
    <Container>
      <Header>
        <Heading>Visitor CheckIn Form</Heading>
        <ButtonGroup>
          <ResetButton onClick={handleReset}>Reset</ResetButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </ButtonGroup>
      </Header>
      <CardContainer>
        <Card>
          <FormGroup>
            <Label>
              Visitor Name<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="visitorName"
              value={formData.visitorName}
              onChange={handleInputChange}
              placeholder="Enter visitor name"
              style={{
                borderBottomColor: formErrors.visitorName ? 'red' : '#2b3497',
              }}
              disabled={true} // Disable input based on condition

            />
          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Email<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="email"
              name="visitorEmail"
              value={formData.visitorEmail}
              placeholder="Enter Email"
              onChange={handleInputChange}
              style={{
                borderBottomColor: formErrors.visitorEmail ? 'red' : '#2b3497',
              }}
              disabled={true} // Disable input based on condition

            />
          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Mobile<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="tel"
              name="visitorMobile"
              value={formData.visitorMobile}
              placeholder="Enter 10 Digit Mobile number"
              maxLength="10"
              onChange={handleInputChange}
              style={{
                borderBottomColor: formErrors.visitorMobile ? 'red' : '#2b3497',
              }}
              disabled={true} // Disable input based on condition

            />
          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Type<Asterisk> *</Asterisk>
            </Label>
            <Select
              name="visitorType"
              value={formData.visitorType}
              onChange={handleInputChange}
              disabled={formData.visitorType ? true : false} // Disable input based on condition

            >
              <option value="">Select type</option>
              {visitorTypes.map((type) => (
                <option
                  key={type.id}
                  value={type.id}
                  selected={checkinData && checkinData && type.id === checkinData.selectedVisitorTypeId}
                >
                  {type.visitorTypeDesc}

                </option>
              ))}

            </Select>
          </FormGroup>
          <FormGroup>
            <Label>
              Company<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="company"
              placeholder="Enter Company"
              value={formData.company}
              onChange={handleInputChange}
            // disabled={formData.visitorMobile ? true : false} // Disable input based on condition

            />

          </FormGroup>
        </Card>
        <Card>
          <FormGroup>
            <Label>
              Meeting From<Asterisk> *</Asterisk>
            </Label>
            <StyledDatePicker
              selected={formData.meetingFrom}
              onChange={(date) =>
                setFormData((prevData) => ({ ...prevData, meetingFrom: date }))
              }
              showTimeSelect
              dateFormat="Pp"
              disabled={formData.meetingFrom ? true : false} // Disable input based on condition

            />
          </FormGroup>
          <FormGroup>
            <Label>
              Meeting To<Asterisk> *</Asterisk>
            </Label>
            <StyledDatePicker
              selected={formData.meetingTo}
              onChange={(date) =>
                setFormData((prevData) => ({ ...prevData, meetingTo: date }))
              }
              showTimeSelect
              dateFormat="Pp"
              disabled={formData.meetingTo ? true : false} // Disable input based on condition

            />
          </FormGroup>
          <FormGroup>
            <Label>
              Assets<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="assets"
              value={formData.assets}
              placeholder="Enter Assets count"
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Vehicle No<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="vehicleno"
              placeholder="Enter Vehicle No"
              value={formData.vehicleno}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Remarks<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="remarks"
              placeholder="Enter Remarks"
              value={formData.remarks}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Card>
        <Card>
          <FormGroup>
            <Heading>Host Details</Heading>
          </FormGroup>
          <FormGroup>
            <Label>
              Employee Name<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="employeeName"
              value={formData.employeeName}
              onChange={handleInputChange}
              placeholder="Enter employee name"
              disabled={formData.employeeName ? true : false} // Disable input based on condition

            />
          </FormGroup>
          <FormGroup>
            <Label>
              Employee Mobile<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="employeeMobile"
              value={formData.employeeMobile}
              onChange={handleInputChange}
              placeholder="Enter employee mobile"
              disabled={formData.employeeMobile ? true : false} // Disable input based on condition

            />
          </FormGroup>
          <Circle>
            <ProfileIcon>
              {formData.visitorPhoto ? (
                <img src={`data:image/png;base64,${formData.visitorPhoto}`} alt="Visitor Photo" style={{ width: 60, height: 60, borderRadius: '50%' }} />
              ) : (
                <User size={60} />
              )}
            </ProfileIcon>
          </Circle>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default CheckinVisitorFormC;
