import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchVisitorTypes, fetchIdProofTypes, fetchMeetingVenues, insertAppointment, fetchGateDetails, fetchBuildingsByGate } from '../../services/Api';
import Webcam from 'react-webcam'; // Import react-webcam
import { User } from 'react-feather'; // Import the User icon
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 25px;
  font-family: 'Segoe UI', Semibold;
  color: #060000;
  margin: 0;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 81px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px #2b349754;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Semibold;
`;

const ResetButton = styled(Button)`
  background-color: red;
`;

const SubmitButton = styled(Button)`
  background-color: #2b3497;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  flex: 1;
  background: radial-gradient(circle, #ffffff, #e3e3e3);
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 15px;
  padding: 27px;
  opacity: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', Semibold;
  color: #000000;
  margin-bottom: 5px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 335px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2b3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2b3497;
    }
  }
     @media (max-width: 1920px) and (min-width: 1720px) {
       width: 545px;

  }
       @media (max-width: 1720px) and (min-width: 1420px) {
       width: 391px;

  }
         @media (max-width: 1420px) and (min-width: 1220px) {
       width: 260px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
       width: 200px;

  }
`;

const ProfileCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  width: 131px;
  height: 131px;
  background: linear-gradient(#ffffff, #f6f6f6);
  border-radius: 50%;
  box-shadow: 0px 10px 10px #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const ProfileIcon = styled.div`
  width: 134px;
  height: 131px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b3497;
  font-size: 30px;
  font-weight: bold;
  position: relative;
`;

const PlusIcon = styled.div`
  width: 47px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BFBFBF;
  font-size: 44px;
  font-weight: 700;
`;

const ProfileText = styled.div`
  font-family: 'Segoe UI', Semibold;
  font-size: 18px;
  color: #ff3030;
  margin-bottom: 20px;
`;

const PhotoButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: #343d9b;
  box-shadow: 0px 10px 10px #2b349733;
  border: 2px solid #343d9b;
  border-radius: 8px;
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: transparent;
  border: 2px solid #343d9b;
  border-radius: 8px;
  box-shadow: 0px 10px 10px #2b349733;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 20px;
           @media (max-width: 1420px) and (min-width: 1220px) {
  width: 125px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
  width: 100px;

  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-family: 'Segoe UI', Semibold;
  font-size: 14px;
`;

const PrivacyLink = styled.a`
  color: #2b3497;
  margin-left: 5px;
  text-decoration: none;
`;
const WebcamContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
`;
const ButtonRow = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
  margin-bottom: 20px; /* Optional: Adjust margin bottom */
`;

const AppoinmentFormPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [selectedGate, setSelectedGate] = useState('');
  const [selectedVisitorType, setSelectedVisitorType] = useState('');
  const [selectedIdProofType, setSelectedIdProofType] = useState('');

  const [visitorTypes, setVisitorTypes] = useState([]);
  const [idProofTypes, setIdProofTypes] = useState([]);

  const [meetingvenues, setMeetingvenues] = useState([]);
  const [gates, setGates] = useState([]);
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    const getVisitorTypes = async () => {
      try {
        const types = await fetchVisitorTypes();
        setVisitorTypes(types);
      } catch (error) {
        console.error('Error fetching visitor types:', error);
      }
    };
    const getIdProofTypes = async () => {
      try {
        const types = await fetchIdProofTypes();
        setIdProofTypes(types);
      } catch (error) {
        console.error('Error fetching visitor types:', error);
      }
    };
    const getMeetingvenues = async () => {
      try {
        const venues = await fetchMeetingVenues();
        setMeetingvenues(venues);
      } catch (error) {
        console.error('Error fetching meeting venues:', error);
      }
    };
    const getGateDetails = async () => {
      try {
        const venues = await fetchGateDetails();
        setGates(venues);
      } catch (error) {
        console.error('Error fetching Gate venues:', error);
      }
    };

    getVisitorTypes();
    getIdProofTypes();
    getMeetingvenues();
    getGateDetails();
  }, []);
  useEffect(() => {
    if (selectedGate) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gate: selectedGate.name, // Use the gate name in the form data
      }));
    }
  }, [selectedGate]);
  const handleDateChange = (date, field) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: date,
    }));
    if (formErrors[field]) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        [field]: false,
      }));
    }
  };
  
  const currentDateTime = new Date(); // Current date and time

  // Function to filter out times earlier than the current time
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // Function to highlight the current time
  const highlightTime = (time) => {
    const now = new Date();
    const timeToHighlight = now.getHours() * 60 + now.getMinutes();
    const selectedTime = time.getHours() * 60 + time.getMinutes();

    // Highlight the time close to the current system time
    return selectedTime === timeToHighlight ? 'highlight-time' : '';
  };

  const validateMeetingTimes = () => {
    if (formData.meetingFrom && formData.meetingTo && formData.meetingFrom >= formData.meetingTo) {
      toast.error('Meeting end time must be greater than the start time');
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        meetingTo: true,
      }));
      return false;
    }
    return true;
  };
  const adjustForTimezoneOffset = (date) => {
    if (!date) return null;
    const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, -1);
    return localISOTime;
  };

  const handleGateChange = async (event) => {
    const gateID = parseInt(event.target.value, 10); // Convert to integer if gate.id is a number
    const selectedGateObject = gates.find(gate => gate.id === gateID);

    setSelectedGate(selectedGateObject); // Store the entire gate object


    try {
      const buildings = await fetchBuildingsByGate(gateID);
      setBuildings(buildings);
    } catch (error) {
      console.error('Error fetching buildings:', error);
    }
  };



  const [formData, setFormData] = useState({
    groupLeaderName: '',
    visitorEmail: '',
    visitorMobile: '',
    company: '',
    meetingFrom: null,
    meetingTo: null,
    selectedVisitorTypeId: selectedVisitorType,
    selectedIdProofTypeId: selectedIdProofType,
    idProofNo: '',
    gate: '',
    building: '',
  });

  const [formErrors, setFormErrors] = useState({
    groupLeaderName: false,
    visitorEmail: false,
    visitorMobile: false,
    meetingVenue: false,
    company: false,
    meetingFrom: false,
    meetingTo: false,
    selectedVisitorTypeId: false,
    selectedIdProofTypeId: false,
    gate: false,
    building: false,
  });

  const handleReset = () => {
    setSelectedGate()
    setFormData({
      groupLeaderName: '',
      visitorEmail: '',
      visitorMobile: '',
      company: '',
      meetingFrom: null,
      meetingTo: null,
      selectedVisitorTypeId: '',
      selectedIdProofTypeId: '',
      idProofNo: '',
      gate: '',
      building: '',
    });
    setFormErrors({
      groupLeaderName: false,
      visitorEmail: false,
      visitorMobile: false,
      meetingVenue: false,
      company: false,
      meetingFrom: false,
      meetingTo: false,
      selectedVisitorTypeId: false,
      selectedIdProofTypeId: false,
      gate: false,
      building: false,
    });
    setMeetingvenues([]);

    console.log('Form reset');
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    const errors = {};

    if (formData.groupLeaderName === '') {
      errors.groupLeaderName = true;
      valid = false;
    }
    if (formData.visitorEmail === '') {
      errors.visitorEmail = true;
      valid = false;
    }
    if (formData.visitorMobile === '') {
      errors.visitorMobile = true;
      valid = false;
    }
    if (formData.company === '') {
      errors.company = true;
      valid = false;
    }
    if (formData.selectedVisitorTypeId === '') {
      errors.selectedVisitorTypeId = true;
      valid = false;
    }
    if (formData.purpose === '') {
      errors.purpose = true;
      valid = false;
    }
    if (formData.meetingFrom === null) {
      errors.meetingFrom = true;
      valid = false;
    }
    if (formData.meetingTo === null) {
      errors.meetingTo = true;
      valid = false;
    }
    if (formData.meetingVenue === '') {
      errors.meetingVenue = true;
      valid = false;
    }
    if (formData.gate === '') {
      errors.gate = true;
      valid = false;
    }
    if (formData.building === '') {
      errors.building = true;
      valid = false;
    }

    if (formData.visitorEmail !== '') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.visitorEmail)) {
        errors.visitorEmail = true;
        valid = false;
      }
    }

    if (formData.visitorMobile !== '') {
      const mobilePattern = /^\d{10}$/;
      if (!mobilePattern.test(formData.visitorMobile)) {
        errors.visitorMobile = true;
        valid = false;
      }
    }
    if (!validateMeetingTimes()) {
      valid = false;
    }

    setFormErrors(errors);
    const toLocalISOString = (date) => {
      const tzOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
      const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1); // Remove 'Z' at the end
      return localISOTime + 'Z'; // Add 'Z' at the end
    };
  
    const formattedData = {
      ...formData,
      meetingFrom: toLocalISOString(new Date(formData.meetingFrom)),
      meetingTo: toLocalISOString(new Date(formData.meetingTo))
    };
    console.log("Form data:", formData);
  console.log("Form data:", formattedData);

    if (!valid) {
      setFormErrors(errors);
      console.log('Form has errors:', errors);
    } else {
      console.log('Form data:', formData);
      navigate('/layout/group-appoinment-details', { state: { formattedData } });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const webcamRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Take photo');

  const handleCapture = () => {
    if (!isCameraOn) {
      setIsCameraOn(true);
      setButtonLabel('Capture');
    } else {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log('Captured Image:', imageSrc);
      setIsCameraOn(false);
      setButtonLabel('Take photo');
    }
  };


  return (
    <Container>
      <Header>
        <Heading>Group Appointment</Heading>
        <ButtonGroup>
          <ResetButton onClick={handleReset}>Reset</ResetButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </ButtonGroup>
      </Header>
      <CardContainer>
        <Card>
          <FormGroup>
            <Label>
              Group Leader Name<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="groupLeaderName"
              value={formData.groupLeaderName}
              onChange={handleInputChange}
              placeholder="Enter Group Leader Name"
              style={{
                borderBottomColor: formErrors.groupLeaderName ? 'red' : '#2b3497',
              }}
            />
            {formErrors.groupLeaderName && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please fill in the group leader name</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Email<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="email"
              name="visitorEmail"
              value={formData.visitorEmail}
              placeholder="Enter Email"
              onChange={handleInputChange}
              style={{
                borderBottomColor: formErrors.visitorEmail ? 'red' : '#2b3497',
              }}
            />
            {formErrors.visitorEmail && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                {formData.visitorEmail ? 'Invalid email format' : 'Please fill in the visitor email'}
              </span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Mobile<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="visitorMobile"
              value={formData.visitorMobile}
              placeholder="Enter 10-digit mobile number"
              maxLength="10"
              onChange={handleInputChange}
              style={{
                borderBottomColor: formErrors.visitorMobile ? 'red' : '#2b3497',
              }}
            />
            {formErrors.visitorMobile && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                {formData.visitorMobile ? 'Invalid mobile number' : 'Please fill in the mobile number'}
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Meeting Venue
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="meetingvenue"
              value={formData.meetingVenue}
              onChange={handleChange}
            >
              <option value="">Select Meeting Venue</option>
              {meetingvenues.map((type) => (
                <option key={type.locationName} value={type.locationName}>
                  {type.locationName}
                </option>
              ))}
            </Select>
            {formErrors.meetingVenue && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a meeting venue</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Company<Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="company"
              placeholder="Enter Company"
              value={formData.company}
              onChange={handleInputChange}
            />
            {formErrors.company && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please fill in the company name</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Type
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="selectedVisitorTypeId"
              value={formData.selectedVisitorTypeId}
              onChange={handleChange}
            >
              <option value="">Select type</option>
              {visitorTypes.map((type) => (
                <option key={type.id} value={type.id}>{type.visitorTypeDesc}</option>
              ))}
            </Select>
            {formErrors.selectedVisitorTypeId && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a visitor type</span>
            )}
          </FormGroup>
        </Card>
        <Card>
          <FormGroup>
            <Label>
              Meeting On
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <StyledDatePicker
              selected={formData.meetingFrom}
              onChange={(date) => handleDateChange(date, 'meetingFrom')}
              showTimeSelect
              dateFormat="Pp"
              minDate={currentDateTime}
              filterTime={filterPassedTime}
              timeClassName={highlightTime}
              placeholderText="Select Meeting Start Date and Time"

            />
            {formErrors.meetingFrom && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a meeting start time</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Meeting To
              <span style={{ color: 'red' }}> *</span>
            </Label>
            <StyledDatePicker
              selected={formData.meetingTo}
              onChange={(date) => handleDateChange(date, 'meetingTo')}
              showTimeSelect
              dateFormat="Pp"
              minDate={formData.meetingFrom || currentDateTime} // Ensure meetingTo is after meetingOn
              filterTime={filterPassedTime}
              timeClassName={highlightTime}
              placeholderText="Select Meeting End Date and Time"

            />
            {formErrors.meetingTo && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a meeting end time</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Gate
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="gate"
              value={selectedGate ? selectedGate.id : ''}
              onChange={handleGateChange}
            >
              <option value="">Select Gate</option>
              {gates.map((gate) => (
                <option key={gate.id} value={gate.id}>
                  {gate.name}
                </option>
              ))}
            </Select>
            {formErrors.gate && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a gate</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>
              Building
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="building"
              value={formData.building}
              onChange={handleChange}
            >
              <option value="">Select Building</option>
              {Array.isArray(buildings) && buildings.map((building) => (
                <option key={building.id} value={building.name}>
                  {building.name}
                </option>
              ))}
            </Select>
            {formErrors.building && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a building</span>
            )}
          </FormGroup>

          <FormGroup>
            <Label>
              Id Proof Type
            </Label>
            <Select
              name="selectedIdProofTypeId"
              value={formData.selectedIdProofTypeId}
              onChange={handleChange}
            >
              <option value="">Select ID Proof Type</option>
              {idProofTypes.map((type) => (
                <option key={type.id} value={type.id}>{type.idProofDescription}</option>
              ))}
            </Select>
            
          </FormGroup>
          <FormGroup>
            <Label>
              Id Proof Number
            </Label>
            <Input
              type="text"
              name="idProofNo"
              placeholder="Enter Id Proof Number"
              value={formData.idProofNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Card>
        <ProfileCard>
          <ProfileText>(Only png images)</ProfileText>
          <Circle>
            <WebcamContainer>
              {isCameraOn && (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  width="100%"
                  height="100%"
                  videoConstraints={{
                    width: 200,
                    height: 200,
                    facingMode: 'user',
                  }}
                />
              )}
              {!isCameraOn && (
                <ProfileIcon>
                  <User size={40} />
                </ProfileIcon>
              )}
            </WebcamContainer>
            {/* <ProfileIcon>
                            <User size={40} />
                        </ProfileIcon>
                        <PlusIcon>+</PlusIcon> */}
          </Circle>
          <PhotoButton onClick={handleCapture}>{buttonLabel}</PhotoButton>
          <ButtonRow>
            <ActionButton>Photograph</ActionButton>
            <ActionButton>Upload images</ActionButton>
          </ButtonRow>

          <CheckboxContainer>
            <input type="checkbox" />
            <span>
              I agree to the
              <PrivacyLink href="#">privacy policy</PrivacyLink>
            </span>
          </CheckboxContainer>
        </ProfileCard>
      </CardContainer>
      <ToastContainer />
    </Container>
  );
};

export default AppoinmentFormPage;