import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown, FiEdit, FiTrash2 } from 'react-icons/fi'; // Using react-icons library

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
  position: relative;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  position: relative;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3D46B4;
  padding: 5px;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  position: relative;
`;

const DropdownText = styled.span`
  color: #293495;
  font-size: 16px;
`;

const DropdownIcon = styled(FiChevronDown)`
  color: #3D46B4;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: calc(100% + 5px); /* Positioning panel just below the dropdown */
  left: 0;
  width: 100%;
  max-height: 150px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  background: white;
  border: 1px solid #3D46B4;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const FoodapprovalPage = () => {
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [data, setData] = useState([
    { id: 1, name: 'John Doe', mobile: '123-456-7890', checkin: '10:00 AM', actualCheckout: '02:00 PM', approvalType: 'Type A' },
    { id: 2, name: 'Alice Johnson', mobile: '987-654-3210', checkin: '11:00 AM', actualCheckout: '03:00 PM', approvalType: 'Type B' },
    // Add more dummy data as needed
  ]);
  const [searchTerm, setSearchTerm] = useState('');

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const dropdownRef = useRef(null);

  const toggleDropdown = (id) => {
    if (openDropdownId === id) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(id);
    }
  };

  const handleOptionClick = (id, option) => {
    setData(data.map(item => item.id === id ? { ...item, approvalType: option } : item));
    setOpenDropdownId(null);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  const handleDelete = (id) => {
    const newData = data.filter(item => item.id !== id);
    setData(newData);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset pagination to first page on search
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Container>
      <Header>
        <Heading>Pending Approval</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Mobile Number</TableHeaderCell>
              <TableHeaderCell>Checkin</TableHeaderCell>
              <TableHeaderCell>Actual Checkout</TableHeaderCell>
              <TableHeaderCell>Approval Type</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentData.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.mobile}</TableCell>
                <TableCell>{item.checkin}</TableCell>
                <TableCell>{item.actualCheckout}</TableCell>
                <TableCell>
                  <DropdownContainer ref={dropdownRef}>
                    <Dropdown onClick={() => toggleDropdown(item.id)}>
                      <DropdownText>{item.approvalType}</DropdownText>
                      <DropdownIcon size={20} />
                    </Dropdown>
                    {openDropdownId === item.id && (
                      <DropdownPanel>
                        <DropdownOption onClick={() => handleOptionClick(item.id, 'Type A')}>Type A</DropdownOption>
                        <DropdownOption onClick={() => handleOptionClick(item.id, 'Type B')}>Type B</DropdownOption>
                      </DropdownPanel>
                    )}
                  </DropdownContainer>
                </TableCell>
                <TableCell>
                  <IconContainer>
                    <FiEdit size={20} style={{ cursor: 'pointer', color: '#293495' }} />
                    <FiTrash2 size={20} style={{ cursor: 'pointer', color: '#293495' }} onClick={() => handleDelete(item.id)} />
                  </IconContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <PaginationContainer showPagination>
          {Array.from({ length: totalPages }, (_, index) => (
            <PaginationButton
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </PaginationButton>
          ))}
        </PaginationContainer>
      )}
    </Container>
  );
};

export default FoodapprovalPage;
