import styled from 'styled-components';
import cardone from '../../assets/cardone.png';
import cardtwo from '../../assets/cardtwo.png';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
  margin-bottom: 20px;
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 39px;
`;

const Card = styled.div`
  width: 288px;
  height: 101px;
  background-color: ${(props) => props.bgColor || '#fff'};
  box-shadow: 0px 10px 10px #00000038;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 320px) {
    width: 90%;
  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

const CardImage = styled.img`
  width: 35px;
  height: 40px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Segoe UI', 'Semibold';
`;

const CardTitle = styled.h2`
  font-size: 18px;
  color: ${(props) => props.textColor || '#000'};
`;

const CardSubtitle = styled.p`
  font-size: 14px;
  color: ${(props) => props.textColor || '#000'};
  margin-top: -1px;
`;

const CardCount = styled.div`
  font-size: 24px;
  color: ${(props) => props.textColor || '#000'};
  font-family: 'Segoe UI', 'Semibold';
`;

const InputCard = styled.div`
  width: 374px;
  height: 182px;
  box-shadow: 0px 10px 20px #00000038;
  background: #E6E6E6;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin: 20px auto 0;
  position: relative;
`;

const InputField = styled.input`
  background-color: transparent;
  width: 80%;
  height: 20px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #2B3497;
  border-radius: 0;
  text-align: left;
  outline: none;

  &::placeholder {
    color: #000000;
  }

  /* Remove up and down arrows for number input */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-moz-appearance {
    textfield;
  }
`;

const Button = styled.button`
  background-color: #2B3497;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 10px 20px #2B349747;
  opacity: 1;
  cursor: pointer;
  width: 150px;
`;

const ReportBugPage = () => {
  return (
    <Container>
      <Heading>Group Check-Out Count</Heading>
      <CardWrapper>
        <Card bgColor="#A2BECB">
          <CardContent>
            <CardImage src={cardone} alt="Card Image" />
            <CardText>
              <CardTitle>Mobile Check-Out</CardTitle>
              <CardSubtitle>Today's Count</CardSubtitle>
            </CardText>
            <CardCount>450</CardCount>
          </CardContent>
        </Card>
        <Card bgColor="#A2BECB">
          <CardContent>
            <CardImage src={cardtwo} alt="Card Image" />
            <CardText>
              <CardTitle>QR Code Check-Out</CardTitle>
              <CardSubtitle>Today's Count</CardSubtitle>
            </CardText>
            <CardCount>777</CardCount>
          </CardContent>
        </Card>
      </CardWrapper>
      <InputCard>
        <InputField
          type="number"
          placeholder="Enter Mobile Number"
          maxLength="10"
        />
        <Button>Next</Button>
      </InputCard>
    </Container>
  );
};

export default ReportBugPage;
