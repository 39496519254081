// src/pages/otpverification/OTPVerification.jsx
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import centerImage from '../../assets/centerImage.png'; // adjust the path as necessary
import { verifyOTP, resendOtp } from '../../services/Api';
import { AppIdContext } from './AppIdContext';
import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
import UsernameContext from '../../components/auth/UsernameContext';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(#FBFBFB, #E6E6E6);
`;

const Image = styled.img`
    width: 170px;
    height: 46px;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font: normal normal 600 38px/54px Segoe UI;
  letter-spacing: 0px;
  color: #060000;
  text-transform: capitalize;
  opacity: 1;
  margin: 0;
  margin-bottom: 10px;
`;

const SubHeading = styled.p`
  text-align: left;
  font: normal normal bold 21px/35px Segoe UI;
  letter-spacing: 0px;
  color: #2B3497;
  opacity: 1;
  margin: 0;
  margin-bottom: 10px;
`;

const Text = styled.p`
  text-align: left;
  font: normal normal 600 30px/40px Segoe UI;
  letter-spacing: 0px;
  color: #060000;
  text-transform: capitalize;
  opacity: 1;
  margin: 0;
  margin-bottom: 10px;
`;

const OTPText = styled.p`
  font: normal normal 600 27px/36px Segoe UI;
  letter-spacing: 0px;
  color: #2B3497;
  text-transform: capitalize;
  opacity: 1;
  margin: 0;
  margin-bottom: 10px;
`;

const OTPInput = styled.input`
  border: none;
  border-bottom: 2px solid #2B3497;
  background: transparent;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 24px;
  text-align: center;
  color: #2B3497;

  &:focus {
    outline: none;
  }
`;

const Timer = styled.p`
  text-align: left;
  font: normal normal normal 21px/28px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  margin: 10px 0;
`;

const ResendText = styled.p`
  text-align: left;
  font: normal normal normal 21px/28px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;

  a {
    color: #2B3497;
    cursor: pointer;
  }
`;

const VerifyButton = styled.button`
  background: linear-gradient(#2B3497, #7177B7, #2B3497);
  box-shadow: 0px 10px 20px #2B34976E;
  border-radius: 6px;
  width: 163px;
  height: 37px;
  font: normal normal 600 21px/26px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const OTPVerification = () => {
  const now = new Date().toISOString();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [decryptedAppId, setDecryptedAppId] = useState(null);

  const location = useLocation();
  const [username, setUsername] = useState(null); // State to hold the username
  
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [resendStatus, setResendStatus] = useState(null);
  const [appId, setAppId] = useState(null);
  const [urlAppointmentId, setAppointmentId] = useState(null);
  const [urlType, setType] = useState(null); // Assuming 'type' indicates the type of OTP to send
  const [appointmentData, setAppointmentData] = useState(null); // Assuming 'type' indicates the type of OTP to send
  const [hostName, setHostName] = useState(null); // Assuming 'type' indicates the type of OTP to send
  const [otpData, setOtpData] = useState(null);

  //Current Date time
  const formatDateTime = (date) => {
    const pad = (number) => (number < 10 ? '0' : '') + number;
    const padMilliseconds = (number) => {
      if (number < 10) return '00' + number;
      if (number < 100) return '0' + number;
      return number;
    };
  
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = padMilliseconds(date.getMilliseconds());
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  };
      
  // Decrypt function to decode the appointment ID
  function decrypt(encodedText) {
    let result = '';
    for (let i = 0; i < encodedText.length; i += 2) {
      const pair = encodedText.substring(i, i + 2);
      const number = parseInt(pair, 10);
      result += String.fromCharCode(number);
    }
    const decryptedNumber = parseInt(result.replace(/\D/g, ''), 10);
    return decryptedNumber;
  }



  // Fetch appointment data based on decrypted ID
  const fetchAppointmentData = async (id) => {
    try {
      const response = await axios.get(`/Appointment/${id}`);
      const data = response.data;

      if (!data || data.length === 0) {
        toast.error('No appointment data found. Please check the link or try again later.');
        return;
      }

      setAppointmentData(data);
      const employeeId = data[0]?.employeeId;
      if (employeeId) {
        fetchHostName(employeeId);
      }
    } catch (error) {
      console.error('Error fetching appointment data:', error);
      toast.error('An error occurred while fetching the appointment data.');
    }
  };

  // Fetch host name based on employee ID
  const fetchHostName = async (employeeId) => {
    try {
      const response = await axios.get(`/Employee/${employeeId}`);
      const hostName = response.data?.employeeName || response.data?.[0]?.employeeName;
      if (hostName) {
        setHostName(hostName);
      } else {
        console.error('Host name not found in response');
      }
    } catch (error) {
      console.error('Error fetching Host Name:', error);
    }
  };

  // Send OTP data to server
  const sendOTP = async (data) => {
    try {
      const response = await axios.post('/EPassRegistration/SendOTP', data);
      console.log('OTP sent response:', response.data);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  // useEffect to handle URL parameters and decrypt the appointment ID
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlType = searchParams.get('type');
    const urlAppointmentId = searchParams.get('appointmentId');

    if (urlAppointmentId) {
      const decryptedId = decrypt(urlAppointmentId);
      setDecryptedAppId(decryptedId);
      console.log('Decrypted Appointment ID:', decryptedId);

      // Fetch appointment data after decrypting the ID
      fetchAppointmentData(decryptedId);
    }
  }, [location]);

  // useEffect to prepare and send OTP data once appointment data and host name are available
  useEffect(() => {
    if (appointmentData && hostName) {
      const formattedNow = formatDateTime(new Date());
      const data = {
        emailId: appointmentData[0].visitorEmail,
        visitorName: appointmentData[0].visitorName,
        mobileNo: appointmentData[0].visitorMobile,
        meetingvenue: appointmentData[0].meetingvenue,
        meetingOn: appointmentData[0].meetingOn,
        createdOn: formattedNow,
        modifiedOn: formattedNow,
        verificationType: 'EmailId',
        hostName: hostName,
      };

      setOtpData(data);
      sendOTP(data);
    }
  }, [appointmentData, hostName]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(timer);
          navigate('/otp-expired'); // Redirect to OTP expired page
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [navigate]);

  const handleChange = (element, index) => {
    const val = element.value;
    if (/^[0-9]$/.test(val)) {
      let newOtp = [...otp];
      newOtp[index] = val;
      setOtp(newOtp);
      if (index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    } else if (val === "") {
      let newOtp = [...otp];
      newOtp[index] = val;
      setOtp(newOtp);
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }

  };

  const handleResend = async () => {
    setTimeRemaining(120); // Reset the timer to 120 seconds
    setOtp(Array(6).fill("")); // Clear the OTP input fields
    setResendStatus(null); // Reset the resend status
  
    try {
      if (otpData) {
        await sendOTP(otpData); // Reuse sendOTP with the stored otpData
        setResendStatus('OTP resent successfully'); // Optionally, update the status to inform the user
      } else {
        console.error('OTP data is not available.');
        setResendStatus('Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      setResendStatus('Failed to resend OTP. Please try again.'); // Update status on error
    }
  };
  
  const handleVerify = async () => {
    if (!appointmentData || appointmentData.length === 0) {
      console.error('Appointment data is missing');
      toast.error('Appointment data is missing. Please try again.');
      return;
    }

    const appointment = appointmentData[0];
    const otpCode = otp.join('');
    const verificationType = 'EmailId';

    try {
      const response = await verifyOTP(
        otpCode,
        verificationType,
        appointment.visitorEmail,
        appointment.visitorMobile,
        appointment.visitorName,
        appointment.meetingvenue,
        appointment.meetingOn,
        now,
        now,
        hostName
      );
      console.log('OTP Verified response:', response.data);
      if (response.data === true) {

        navigate('/visitor-form', { state: {  appointmentData, decryptedAppId,hostName,  } });
        console.log('decryptedAppId', decryptedAppId);
      } else {
        toast.error('The OTP entered is invalid. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('An error occurred while verifying the OTP. Please try again.');
    }
  };

  return (
    <Container>
      <Image src={centerImage} alt="OTP Image" />
      <Heading>OTP Verification</Heading>
      <SubHeading>OTP sent to your Mobile and Email entered by host</SubHeading>
      <OTPText>One Time Password</OTPText>
      <div>
        {otp.map((data, index) => (
          <OTPInput
            key={index}
            type="text"
            maxLength="1"
            id={`otp-${index}`}
            value={data}
            onChange={(e) => handleChange(e.target, index)}
          />
        ))}
      </div>
      <Timer>{timeRemaining} seconds remaining</Timer>
      <ResendText>
        Did not get your OTP? <a onClick={handleResend}>Resend it</a>
      </ResendText>
      <VerifyButton onClick={handleVerify}>Verify</VerifyButton>
      {verificationStatus && <p>{verificationStatus}</p>}
      {resendStatus && <p>{resendStatus}</p>}

      <ToastContainer />

    </Container>
  );
};

export default OTPVerification;
