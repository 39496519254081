import axios from "axios";
// Set the baseURL to the appropriate API endpoint
axios.defaults.baseURL = "https://vmsdevapi.azurewebsites.net/api";

// Function to login user
export const LoginApi = (inputs) => {
  const username = inputs.username;
  const password = inputs.password;
  const LOGIN_URL = `/User/ValidateUser/${username},${password}`;

  return axios.get(LOGIN_URL);
};

export const fetchVisitorTypes = async () => {
  const VISITOR_TYPES_URL = '/VisitorType';

  try {
    const response = await axios.get(VISITOR_TYPES_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching visitor types:', error);
    throw error;
  }
};
export const fetchIdProofTypes = async () => {
  const Id_TYPES_URL = '/idproof';

  try {
    const response = await axios.get(Id_TYPES_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching visitor types:', error);
    throw error;
  }
};
export const fetchMeetingVenues = async () => {
  const MEETING_VENUES_URL = '/Location';

  try {
    const response = await axios.get(MEETING_VENUES_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching Meeting Venue:', error);
    throw error;
  }
};

export const fetchGateDetails = async () => {
  const GATE_DETAILS_URL = '/Location/GetAllGates';

  try {
    const response = await axios.get(GATE_DETAILS_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching Gate Venue:', error);
    throw error;
  }
};

export const fetchBuildingsByGate = async (gateID) => {
  const BUILDINGS_BY_GATE_URL = '/Location/GetBuidingsByGateID';

  try {
    const response = await axios.get(BUILDINGS_BY_GATE_URL, {
      params: { gateID }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Building Venue:', error);
    throw error;
  }
};

export const insertAppointment = async (formData) => {
  const APPOINTMENT_URL = '/Appointment';

  try {
    const response = await axios.post(APPOINTMENT_URL, formData, {
    });
    return response.data;

    console.log(response.data);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const linkMailAndSms = async (appId) => {
  const MAILCONFIG_URL = `/Appointment/Linkmailandsms/${appId}`;
  try {
    const response = await axios.post(MAILCONFIG_URL);
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const verifyOTP = async (otpCode, verificationType,emailId,mobileNo,visitorName,meetingvenue,createdOn,meetingOn,modifiedOn,hostName) => {
  const OTP_VERIFICATION_URL = '/EPassRegistration/OTPVerification';
  try {
    const response = await axios.put(OTP_VERIFICATION_URL, {
      otpValue: otpCode,
      verificationType: verificationType,
      emailId:emailId,
      mobileNo:mobileNo,
      visitorName:visitorName,
      meetingvenue:meetingvenue,
      createdOn:createdOn,
      meetingOn:meetingOn,
      modifiedOn:modifiedOn,
      hostName:hostName
    });
    console.log(response.data);
    return response; // Return the response so it can be handled in the calling function
  } catch (error) {
    console.error('Error:', error);
  }
};

export const resendOtp = async () => {
  const RESEND_OTP_URL = '/EPassRegistration/SendOTP';

  try {
    const response = await axios.post(RESEND_OTP_URL);
    console.log(response.data);
    return response; // Return the response so it can be handled in the calling function
  } catch (error) {
    console.error('Error:', error);
  }
};

