import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown } from 'react-icons/fi';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa'; // Import an edit icon from react-icons
import Modal from 'react-modal'; // Import the modal component

const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  font-family:'Segoe UI', Regular;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1;
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000;
`;

const Card = styled.div`
  box-shadow: 0px 5px 10px #00000038;
  background: #f5f5f5;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  margin-bottom: 20px;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top:13px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: #3d46b4;
  margin-bottom: 5px;
`;

const Input = styled.input`
  border: none;
  border-bottom: 2px solid #3d46b4;
  background: transparent;
  padding: 10px;
  font-size: 16px;
  outline: none;
  flex: 1;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3d46b4;
  padding: 10px;
  cursor: pointer;
  position: relative;
  background: transparent;
`;

const DropdownText = styled.span`
  color: #293495;
  font-size: 16px;
`;

const DropdownIcon = styled(FiChevronDown)`
  color: #3d46b4;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #3d46b4;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(
    to bottom,
    #e6e6e6,
    #f2f2f2,
    #f5f5f5,
    #f3f3f3,
    #e9e9e9
  );
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 6px;
`;



const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;


const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 28px;
  margin-left: auto;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  height: 37px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 10px 20px #2b349752;
  &:first-child {
    background-color: #ff4b4b;
    box-shadow: 0px 10px 20px #7e18182e;
    color: #fff;
  }
  &:last-child {
    background-color: #3d46b4;
    color: #fff;
  }
`;

const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  margin-top: 40px; /* Adjust the margin-top for spacing */
  font-size: 16px;
  color: #3d46b4;
`;

const RadioButtonInput = styled.input`
  margin-right: 8px;
`;
const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  color: #2b3497;
  margin: 0 5px;
`;
const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;
const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 1.2em;
  display: flex;
  align-items: center;
`;
// Define the styled modal content
const ModalContent = styled.div`
  width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
`;

// Define the buttons with the same styling
const submitButton = styled.button`
    background-color: #3d46b4;
    color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

// Define the CancelButton with the same styling as Button
const CancelButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;



const BlackListPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [visitorName, setVisitorName] = useState('');
  const [visitorMobile, setVisitorMobile] = useState('');
  const [visitorCompany, setVisitorCompany] = useState('');
  const [visitDate, setVisitDate] = useState('');
  const [hostName, setHostName] = useState('');
  const [blackList, setBlackllist] = useState(false); // State for radio button
  const [tableData, setTableData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // Store original data separately

  const [totalItems, setTotalItems] = useState(0); // Define totalItems in the state
  const [selectedRow, setSelectedRow] = useState(null); // State for selected row
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [blacklistReason, setBlacklistReason] = useState('');

  const itemsPerPage = 10; // Number of items per page
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;

  const dropdownRef = useRef(null);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setVisitorName(option); // Update visitorName state when an option is clicked
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const fetchBlackListData = async () => {
    try {
      const payload = {
        
          id: 0,
          visitorName: null,
          visitorMobile: null,
          visitorCompany: null,
          host: null,
          blackList: false,
          reasonforBlackListing: null,
          visitDate: null
        
        
      }; // Add any required payload data here

      const response = await axios.post('/BlackList', payload, {
        headers: {
          'Content-Type': 'application/json', // Set appropriate content type if needed
        },
      });
      setTableData(response.data);
      console.log('blacklist view',response.data)
    } catch (error) {
      console.error('Error fetching Black list Report data:', error);

      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request data:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
    }
  };

  useEffect(() => {
    fetchBlackListData();
  }, []);

  const handleReset = () => {
    setVisitorName('');
    setVisitorMobile('');
    setVisitorCompany('');
    setVisitDate('');
    setHostName('');
    setBlackllist(false); // Reset radio button state
    setCurrentPage(1); // Reset pagination to the first page
    setSearchQuery(''); // Reset the search query
    fetchBlackListData(); // Fetch the original data
  
  };

  const handleSearch = async() => {
    const normalizedVisitDate = visitDate
    ? new Date(visitDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    : null;

  const payload = {
    id: 0,
    visitorName: visitorName || null,
    visitorMobile: visitorMobile || null,
    visitorCompany: visitorCompany || null,
    host: hostName || null,
    blackList: blackList || false,
    reasonforBlackListing: null,
    visitDate: normalizedVisitDate
  };

  try {
    const response = await axios.post('/BlackList', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.data && response.data.length > 0) {
      setTableData(response.data);
      setCurrentPage(1); // Reset pagination to the first page after search
    } else {
      setTableData([]);
    }
  } catch (error) {
    console.error('Error searching blacklist data:', error);
  }
};

  const handleRadioChange = (e) => {
    setBlackllist(e.target.checked);
  };
  const filteredData = tableData.filter((row) =>
    row.visitorName && row.visitorName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setIsBlacklisted(row.blackList || false);
    setBlacklistReason(row.reasonforBlackListing || '');
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSelectedRow(null);
    setIsBlacklisted(false);
    setBlacklistReason('');
  };

  const handleSubmit = async () => {
    if (selectedRow) {
      try {
        await axios.put('/BlackList/BlackListUpd', {
          visitorName: selectedRow.visitorName,
          visitorMobile: selectedRow.visitorMobile,
          visitorCompany: selectedRow.visitorCompany,
          host: selectedRow.host,
          blackList: isBlacklisted,
          reasonforBlackListing: blacklistReason,
          visitDate: selectedRow.visitDate
        });
        // Update the table data with the new blacklisted status and reason
        const updatedTableData = tableData.map(row =>
          row.id === selectedRow.id
            ? { ...row, blackList: isBlacklisted, reasonforBlackListing: blacklistReason }
            : row
        );
        setTableData(updatedTableData);
        console.log('Updated Table Data:', updatedTableData);

        handleCloseModal();

      } catch (error) {
        console.error('Error updating blacklist:', error);
      }
    }
  };


  return (
    <Container>
      <Header>
        <Heading>Black List </Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <Card>
        <FormRow>
          <FormField style={{ flex: '1 1 200px' }}>
            <Label>Visitor Name</Label>
            <Input
              type="text"
              placeholder="Enter Visitor Name"
              value={visitorName}
              onChange={(e) => setVisitorName(e.target.value)}
            />
          </FormField>
          <FormField style={{ flex: '1 1 160px' }}>
            <Label>Visitor Mobile</Label>
            <Input
              type="tel"
              placeholder="Enter 10-digit mobile number"
              maxLength="10"
              value={visitorMobile}
              onChange={(e) => {
                const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
                setVisitorMobile(onlyNumbers);
              }}
            />
          </FormField>
          <FormField style={{ flex: '1 1 160px' }}>
            <Label>Visitor Company</Label>
            <Input
              type="text"
              placeholder="Enter Company Name"
              value={visitorCompany}
              onChange={(e) => setVisitorCompany(e.target.value)}
            />
          </FormField>
          <FormField style={{ flex: '1 1 160px' }}>
            <Label>Visit Date</Label>
            <Input
              type="date"
              value={visitDate}
              onChange={(e) => setVisitDate(e.target.value)}
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <Label>Host Name</Label>
            <Input
              type="text"
              placeholder="Enter Host Name"
              value={hostName}
              onChange={(e) => setHostName(e.target.value)}
            />
          </FormField>
          <FormField>
            <RadioButtonLabel>
              <RadioButtonInput
                type="radio"
                name="blacklist"
                checked={blackList}
                onChange={handleRadioChange}
              />
              Black List
            </RadioButtonLabel>
          </FormField>
          <ButtonGroup>
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={handleSearch}>Search</Button>
          </ButtonGroup>
        </FormRow>
      </Card>
      {filteredData.length === 0 && (
        <ZeroStateMessage>No data found.</ZeroStateMessage>
      )}
      {filteredData.length > 0 && (

        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Visitor Name</TableHeader>
                <TableHeader>Visitor Mobile</TableHeader>
                <TableHeader>Visitor Company</TableHeader>
                <TableHeader>Last Visit Date</TableHeader>
                <TableHeader>Black Listed</TableHeader>
                <TableHeader>Reason for Blocking</TableHeader>
                <TableHeader>Actions</TableHeader>

              </tr>
            </thead>
            <tbody>
              {currentRows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.visitorName}</TableCell>
                  <TableCell>{row.visitorMobile}</TableCell>
                  <TableCell>{row.visitorCompany}</TableCell>
                  <TableCell>{row.visitDate}</TableCell>
                  <TableCell>{row.blackList ? "Yes" : "No"}</TableCell>
                  <TableCell>{row.reasonforBlackListing}</TableCell>
                  <TableCell>
                    <ActionButton onClick={() => handleEditClick(row)}>
                      <FaEdit /> {/* Use the imported EditIcon */}
                    </ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>


        </TableContainer>
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Edit Row Data"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            maxWidth: '100%',  // Ensure modal doesn't exceed the screen width
            overflowX: 'hidden',  // Prevent horizontal scroll
            overflowY: 'auto',  // Allow vertical scroll if needed

          }
        }}
      >
        <ModalContent>
          <h2>Edit Row Data</h2>
          {selectedRow && (
            <div>
              <p>Visitor Name: {selectedRow.visitorName}</p>
              <p>Visitor Mobile: {selectedRow.visitorMobile}</p>
              <p>Visitor Company: {selectedRow.visitorCompany}</p>
              <p>Visit Date: {selectedRow.visitDate}</p>
              <p>Host Name: {selectedRow.host}</p>
              <label>
                <input
                  type="checkbox"
                  checked={isBlacklisted}
                  onChange={(e) => setIsBlacklisted(e.target.checked)}
                />
                Black List
              </label>
              <br></br>
              <br></br>
              {isBlacklisted && (
                <div>
                  <label>Reason for BlackListing :</label>
                  <br></br>
                  <br></br>
                  <textarea
                    value={blacklistReason}
                    onChange={(e) => setBlacklistReason(e.target.value)}
                    rows="4"
                    maxLength="100"
                  />
                  <br></br>
                  <br></br>
                </div>
              )}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: 'blue',
                color: 'white',
                marginRight: '10px'
              }}>
              Submit
            </Button>
            <Button
              onClick={handleCloseModal}
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid black'
              }}>
              Cancel
            </Button>
          </div>
        </ModalContent>
      </Modal>


    </Container>
  );
};

export default BlackListPage;
