import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Webcam from 'react-webcam'; // Import react-webcam
import { User } from 'react-feather'; // Import the User icon
import VisitorContext from './VisitorContext';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { fetchVisitorTypes } from '../../services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import * as bodyPix from '@tensorflow-models/body-pix';
import '@tensorflow/tfjs';




const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 25px;
  font-family: 'Segoe UI', Semibold;
  color: #060000;
  margin: 0;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 81px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px #2b349754;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Semibold;
`;

const ResetButton = styled(Button)`
  background-color: red;
`;

const SubmitButton = styled(Button)`
  background-color: #2b3497;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  flex: 1;
  background: radial-gradient(circle, #ffffff, #e3e3e3);
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 15px;
  padding: 27px;
  opacity: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', Semibold;
  color: #000000;
  margin-bottom: 5px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 335px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2b3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2b3497;
    }
  }
     @media (max-width: 1920px) and (min-width: 1720px) {
       width: 545px;

  }
       @media (max-width: 1720px) and (min-width: 1420px) {
       width: 480px;

  }
         @media (max-width: 1420px) and (min-width: 1220px) {
       width: 400px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
       width: 200px;

  }
`;

const ProfileCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  width: 131px;
  height: 131px;
  background: linear-gradient(#ffffff, #f6f6f6);
  border-radius: 50%;
  box-shadow: 0px 10px 10px #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const ProfileIcon = styled.div`
  width: 134px;
  height: 131px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b3497;
  font-size: 30px;
  font-weight: bold;
  position: relative;
`;

const PlusIcon = styled.div`
  width: 47px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BFBFBF;
  font-size: 44px;
  font-weight: 700;
`;

const ProfileText = styled.div`
  font-family: 'Segoe UI', Semibold;
  font-size: 18px;
  color: #ff3030;
  margin-bottom: 20px;
`;

const PhotoButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: #343d9b;
  box-shadow: 0px 10px 10px #2b349733;
  border: 2px solid #343d9b;
  border-radius: 8px;
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: transparent;
  border: 2px solid #343d9b;
  border-radius: 8px;
  box-shadow: 0px 10px 10px #2b349733;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-family: 'Segoe UI', Semibold;
  font-size: 14px;
`;

const PrivacyLink = styled.a`
  color: #2b3497;
  margin-left: 5px;
  text-decoration: none;
`;
const WebcamContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
`;
const ButtonRow = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
  margin-bottom: 20px; /* Optional: Adjust margin bottom */
`;

const VisitorApplication = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation(); // Initialize useNavigate hook

    const [visitormobile, setVisitorMobile] = useState(null);
    const [username, setUsername] = useState(null); // State to hold the username
    const [visitorTypes, setVisitorTypes] = useState([]);
    const [mailData, setMailData] = useState(null);
    const [managerMail, setManagerMail] = useState('');
    const [managerName, setManagerName] = useState('');
    const [employeeMail, setEmployeeMail] = useState('');
      const { decryptedAppId,hostName } = location.state || {};
    const [appointmentData, setAppointmentData] = useState('');
    const [isChecked, setIsChecked] = useState(false); // Add state to track checkbox

    useEffect(() => {
        const getVisitorTypes = async () => {
            try {
                const types = await fetchVisitorTypes();
                setVisitorTypes(types);
            } catch (error) {
                console.error('Error fetching visitor types:', error);
            }
        };
        getVisitorTypes();
    }, []);



    const [formData, setFormData] = useState({
        visitorName: '',
        visitorEmail: '',
        visitorMobile: '',
        visitorType: '',
        visitorCompany: '',
        meetingOn: null,
        meetingTo: null,
        visitorPhoto: '',
        visitorTypeDesc:  '',

    });

    const [formErrors, setFormErrors] = useState({
        visitorName: false,
        visitorEmail: false,
        visitorMobile: false,
        visitorCompany: false,
    });

    const [appId, setAppId] = useState(null);
    const [employeeName, setEmployeeName] = useState('');
    const [employeeId, setEmployeeId] = useState('');


    useEffect(() => {
        if (decryptedAppId) {
            console.log('Decrypted appId:', decryptedAppId);
            // Fetch appointment data
            const fetchAppointmentData = async (id) => {
                try {
                    const response = await axios.get(`/Appointment/${id}`);
                    if (response.status === 200) {
                        console.log('Appointment data fetched:', response.data);
                        setAppointmentData(response.data);
                        const empData = response.data;
                        console.log('empData',empData);
                        
                            if (empData[0].employeeId) {
                                fetchManagerMail(empData[0].employeeId);
                            }
                        

                    } else {
                        console.error('Failed to fetch appointment data');
                    }
                } catch (error) {
                    console.error('Error fetching appointment data:', error);
                }
            };
            fetchAppointmentData(decryptedAppId);
        }
    }, [decryptedAppId]);
    
    useEffect(() => {
        if (appointmentData && appointmentData.length > 0) {
            console.log('Prepopulated appointment data:', appointmentData);
            setFormData((prevData) => ({
                ...prevData,
                visitorName: appointmentData[0].visitorName || '',
                visitorEmail: appointmentData[0].visitorEmail || '',
                visitorMobile: appointmentData[0].visitorMobile || '',
                visitorType: appointmentData[0].selectedVisitorTypeId || '',
                visitorTypeDesc: visitorTypes.find(type => type.id === appointmentData[0].selectedVisitorTypeId)?.visitorTypeDesc || '',
                meetingTo: appointmentData[0].meetingTo || null,
                meetingvenue: appointmentData[0].meetingvenue || null,
                vehicleNo: formData.vehicleno,
                visitorCompany: formData.visitorCompany,
                id: appointmentData[0].id,
                meetingOn: appointmentData[0].meetingOn,
                employeeId: appointmentData[0].employeeId,
                appointmentstatus: appointmentData[0].appointmentstatus,
                userId: appointmentData[0].userId,
                Gate: appointmentData[0].gate,
                Building: appointmentData[0].building,
                purpose: appointmentData[0].purpose
            }));
            console.log('Updated formData:', formData);
        }
    }, [appointmentData]);
    

    const handleReset = () => {
        setFormData({
            visitorCompany: '',
            vehicleno: '',
            visitorPhoto: '',


        });
        setFormErrors({
            visitorName: false,
            visitorMobile: false,
            visitorCompany:false,
        });
        console.log('Form reset');
    };
    const handleSelectChange = (e) => {
        const selectedId = e.target.value;
        const selectedType = visitorTypes.find(type => type.id === parseInt(selectedId));

        setFormData(prevState => ({
            ...prevState,
            visitorType: selectedId,
            visitorTypeDesc: selectedType ? selectedType.visitorTypeDesc : ''
        }));
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    
    const handleSubmit = async () => {
        let valid = true;
        const errors = {};

        if (formData.visitorName === '') {
            errors.visitorName = true;
            valid = false;
        }
        if (formData.visitorEmail === '') {
            errors.visitorEmail = true;
            valid = false;
        }
        if (formData.visitorMobile === '') {
            errors.visitorMobile = true;
            valid = false;
        }
        if (formData.visitorCompany === '') {
            errors.visitorCompany = true;
            valid = false;
        }

        if (formData.visitorEmail !== '') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.visitorEmail)) {
                errors.visitorEmail = true;
                valid = false;
            }
        }

        if (formData.visitorMobile !== '') {
            const mobilePattern = /^\d{10}$/;
            if (!mobilePattern.test(formData.visitorMobile)) {
                errors.visitorMobile = true;
                valid = false;
            }
        }

        if (!valid) {
            setFormErrors(errors);
            console.log('Form has errors:', errors);
        } else {
            try {
                const data = {
                    ...formData,
                    IsCoupon: 3, // Ensure the hard-coded value is included
                };

                const response = await axios.put('/Appointment/AppointmentUpdated', data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    toast.success('Successfully Updated!');
                    console.log('Appointment updated successfully:', response.data);
                    // Call sendEmail with the updated appointment data
                    await sendEmail(appointmentData);

                    navigate('/form-submitted');
                    // Store the appointment data from the response
                    // setMailData(response.data);

                } else {
                    console.error('Failed to update appointment');
                }
            } catch (error) {
                console.error('Error updating appointment:', error);
            }
        }
    };

    
    
    
    const fetchManagerMail = async (id) => {
        try {
            const response = await axios.get(`/Employee/${id}`);
            console.log('response',response);
            setEmployeeMail(response.data.employeeMailId); // Assuming API returns empMail property

            setManagerMail(response.data.managerMailID); // Assuming API returns empMail property
            console.log('managerMailID', response.data.managerMailID);
            const managerMailId = response.data.managerMailID;

            if (managerMailId) {
                fetchManagerName(managerMailId);
            }
    
        } catch (error) {
            console.error('Error fetching fetchManagerMail:', error);
        }
    };
    

    const fetchManagerName = async (empEmail) => {
        try {
            const response = await axios.get(`/Employee/EmployeeMail/${empEmail}`);
            setManagerName(response.data.employeeName); // Assuming API returns empMail property



            console.log('ManagerName', response.data.employeeName);

        } catch (error) {
            console.error('Error fetching employee employeeName:', error);
        }
    };


    const sendEmail = async (appointmentData) => {
        try {
            if (!appointmentData || !appointmentData[0]) {
                console.error('Invalid appointment data:', appointmentData);
                return;
            }
            // Convert meetingTo to the required format
            const validTillDate = new Date(appointmentData[0].meetingTo);
            const validTillFormatted = validTillDate.toISOString();

            const model = {
                mailType: "MAILTOVISITORBYSEC",
                mailSubject: `Info : Your ePass to visit Fidrox ${appointmentData[0].meetingvenue}`,
                toAddress: appointmentData[0].visitorEmail,
                docId: appointmentData[0].id,
                employeeMailId: employeeMail,
                visitorMobile: appointmentData[0].visitorMobile,
                hostName: hostName,
                meetingOn: appointmentData[0].meetingOn,// Assuming MeetingOn is a date or datetime
                visitorType: formData.visitorTypeDesc, // Use visitorTypeDesc here
                visitorPhoto: formData.visitorPhoto,
                //visitorPhoto:'',
                Gate: appointmentData[0].gate,
                Building: appointmentData[0].building,
                visitorCompany: formData.visitorCompany,
                visitorName: appointmentData[0].visitorName,
                meetingVenue: appointmentData[0].meetingvenue,
                managerName: managerName,
                managerMail: managerMail, // Use managerMail from state
                visitorEmail: appointmentData[0].visitorEmail,
                meetingTo: appointmentData[0].meetingTo,
                purpose: appointmentData[0].purpose,
                validTill: validTillFormatted,
                foodCoupon:'',
                txtManagersID:'',
                secsEmailId:'',
                duration:'',
                link:'',
                rejectionNotes:'',
                securityMail:'',
                securityName:'',
                qrCode:'',


            };
            console.log('appointmentData[0].visitorEmail', appointmentData[0].visitorEmail);

            const response = await axios.post('/Mail', model, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Email sent successfully:', response.data);
            } else {
                console.error('Failed to send email');
            }

        } catch (error) {
            console.error('Error sending email:', error);
        }

    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const webcamRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Take photo');
    const [profileImage, setProfileImage] = useState(null);


    const handleCapture = () => {
        if (!isCameraOn) {
            setIsCameraOn(true);
            setButtonLabel('Capture');
        } else {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log('Captured Image:', imageSrc);

            // Remove the data URL part if necessary
            const base64Image = imageSrc.split(',')[1]; // Get the base64 part only

            setFormData((prevData) => ({
                ...prevData,
                visitorPhoto: base64Image, // Save only the base64 string to formData
            }));

            setProfileImage(imageSrc); // Set the profile image source

            setIsCameraOn(false);
            setButtonLabel('Take photo');
        }
    };

    return (
        <Container>
            <Header>
                <Heading>Visitor Application Form</Heading>
                <ButtonGroup>
                    <ResetButton onClick={handleReset}>Reset</ResetButton>
                    <SubmitButton onClick={handleSubmit}disabled={!isChecked}>Submit</SubmitButton>
                </ButtonGroup>
            </Header>
            <CardContainer>
                <Card>
                    <FormGroup>
                        <Label>
                            Visitor Name<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            name="visitorName"
                            value={formData.visitorName}
                            onChange={handleInputChange}
                            placeholder="Enter visitor name"
                            style={{
                                borderBottomColor: formErrors.visitorName ? 'red' : '#2b3497',
                                opacity: formData.visitorName ? 1 : 0.5, // Adjust opacity based on condition

                            }}
                            disabled={formData.visitorName ? true : false} // Disable input based on condition

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Email<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="email"
                            name="visitorEmail"
                            value={formData.visitorEmail}
                            placeholder="Enter Email"
                            onChange={handleInputChange}
                            style={{
                                borderBottomColor: formErrors.visitorEmail ? 'red' : '#2b3497',
                                opacity: formData.visitorEmail ? 1 : 0.5, // Adjust opacity based on condition

                            }}
                            disabled={formData.visitorEmail ? true : false} // Disable input based on condition

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Mobile<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="tel"
                            name="visitorMobile"
                            value={formData.visitorMobile}
                            placeholder="Enter Mobile number"
                            onChange={handleInputChange}
                            style={{
                                borderBottomColor: formErrors.visitorMobile ? 'red' : '#2b3497',
                                opacity: formData.visitorMobile ? 1 : 0.5, // Adjust opacity based on condition
                            }}
                            disabled={formData.visitorMobile ? true : false} // Disable input based on condition

                        />
                    </FormGroup>
                    <FormGroup>
                    <Label>
                        Visitor Type <Asterisk> *</Asterisk>
                    </Label>
                    <Select
                        name="visitorType"
                        value={formData.visitorType}
                        onChange={handleSelectChange}
                        disabled={formData.visitorType ? true : false} // Disable input based on condition
                    >
                        <option value="">Select type</option>
                        {visitorTypes.map((type) => (
                            <option
                                key={type.id}
                                value={type.id}
                                selected={type.id === formData.visitorType} // Set pre-selection
                            >
                                {type.visitorTypeDesc}
                            </option>
                        ))}
                    </Select>
                </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Company<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            name="visitorCompany"
                            placeholder="Enter Visitor Company"
                            value={formData.visitorCompany}
                            onChange={handleInputChange}
                            style={{ borderColor: formErrors.visitorName ? 'red' : '#2B3497' }}
                            />
                                        {formErrors.visitorCompany && (
                              <span style={{ color: 'red', fontSize: '12px' }}>Please fill in the Company name</span>
                            )}
                
                    </FormGroup>
                </Card>
                <Card>
                    <FormGroup>
                        <Label>
                            Meeting From<Asterisk> *</Asterisk>
                        </Label>
                        <StyledDatePicker
                            selected={formData.meetingOn}
                            onChange={(date) =>
                                setFormData((prevData) => ({ ...prevData, meetingOn: date }))
                            }
                            showTimeSelect
                            dateFormat="Pp"
                            disabled={formData.meetingOn ? true : false} // Disable input based on condition

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Meeting To<Asterisk> *</Asterisk>
                        </Label>
                        <StyledDatePicker
                            selected={formData.meetingTo}
                            onChange={(date) =>
                                setFormData((prevData) => ({ ...prevData, meetingTo: date }))
                            }
                            showTimeSelect
                            dateFormat="Pp"
                            disabled={formData.meetingTo ? true : false} // Disable input based on condition

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Host Name<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            name="hostname"
                            value="Abdul"
                            placeholder="Enter Host name"
                            onChange={handleInputChange}
                            disabled={true} // Disable input based on condition

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Vehicle No<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            name="vehicleno"
                            placeholder="Enter Vehicle No"
                            value={formData.vehicleno}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                </Card>
                <ProfileCard>
                    <ProfileText>(Only png images)</ProfileText>
                    <Circle>
                        <WebcamContainer>
                            {isCameraOn && (
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/png"
                                    width="100%"
                                    height="100%"
                                    videoConstraints={{
                                        width: 200,
                                        height: 200,
                                        facingMode: 'user',
                                    }}
                                />
                            )}
                            {!isCameraOn && profileImage && (
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                                />
                            )}
                            {!isCameraOn && !profileImage && (
                                <ProfileIcon>
                                    <User size={40} />
                                </ProfileIcon>
                            )}
                        </WebcamContainer>
                    </Circle>
                    <PhotoButton onClick={handleCapture}>{buttonLabel}</PhotoButton>
                    <ButtonRow>
                        <ActionButton>Photograph</ActionButton>
                        <ActionButton>Upload images</ActionButton>
                    </ButtonRow>

                    <CheckboxContainer>
                    <input type="checkbox" onChange={handleCheckboxChange} />
                    <span>
                            I agree to the
                            <PrivacyLink href="#">privacy policy</PrivacyLink>
                        </span>
                    </CheckboxContainer>
                </ProfileCard>
            </CardContainer>
            <ToastContainer />

        </Container>
    );
};

export default VisitorApplication;
