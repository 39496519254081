import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiSearch } from 'react-icons/fi'; // Using react-icons library

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  box-shadow: 0px 5px 10px #00000038;
  background: #f5f5f5;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const Label = styled.label`
  color: #3d46b4;
  margin-bottom: 5px;
`;

const CustomDatePicker = styled(DatePicker)`
  border: none;
  border-bottom: 2px solid #3d46b4;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  color: #293495;
  background: transparent;
  outline: none;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  &:first-child {
    background-color: #ff4b4b;
    box-shadow: 0px 10px 20px #7e18182e;
  }
  &:last-child {
    background-color: #2b3497;
    box-shadow: 0px 10px 20px #2b349752;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

const DatewiseFeedbackPage = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    return (
        <Container>
            <Header>
                <Heading>Date Wise Feedback Report</Heading>
                <SearchBox>
                    <SearchIcon size={20} />
                    <SearchInput type="text" placeholder="Search..." />
                </SearchBox>
            </Header>
            <Card>
                <DatePickerContainer>
                    <Label>Select Date</Label>
                    <CustomDatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        placeholderText="Select a date"
                        dateFormat="MMMM d, yyyy"
                    />
                </DatePickerContainer>
                <ButtonGroup>
                    <Button>Reset</Button>
                    <Button>Search</Button>
                </ButtonGroup>
            </Card>
            <TableContainer>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Device Name</TableHeaderCell>
                            <TableHeaderCell>Employee No</TableHeaderCell>
                            <TableHeaderCell>Option Selected</TableHeaderCell>
                            <TableHeaderCell>Comments</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>John Doe</TableCell>
                            <TableCell>123-456-7890</TableCell>
                            <TableCell>Acme Corp</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Alice Johnson</TableCell>
                            <TableCell>987-654-3210</TableCell>
                            <TableCell>Global Inc</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default DatewiseFeedbackPage;
