import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiEye, FiEdit, FiTrash2, FiPlus, FiMinus } from 'react-icons/fi'; // Using react-icons library
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications


const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  background-color: #f5f5f5;
  box-shadow: 0px 5px 10px #00000038;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormItem = styled.div`
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', 'Semibold';
  color: #2B3497;
  margin-bottom: 5px;
  font-weight: 600;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', 'Regular';
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Segoe UI', 'Semibold';
  border-radius: 27px;
  opacity: 1;
  color: #fff;
  margin-right: 30px; // Space to the right of the button

  &.add-location {
    background-color: #293495;
  }

  &.reset {
    background-color: red;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  &.submit {
    background-color: #3d46b4;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  & > svg {
    margin-left: 8px;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  background-color: #2B3497; /* Dark blue */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
`;

const ResetButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  background-color: #FF4B4B; /* Red */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
`;

const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  color: #2B3497;
  margin: 0 5px;
`;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;



const VisitorTypeMapPage = () => {
  const [formValues, setFormValues] = useState({
    visitorType: '',
  });

  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;
  const [showInput, setShowInput] = useState(false);


  // Fetch Visitor type data from API
  useEffect(() => {
    const fetchEmployeeRoleMapData = async () => {
      try {
        const response = await axios.get('/VisitorType');
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching visitor type data:', error);
      }
    };

    fetchEmployeeRoleMapData();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleAddLocationClick = () => {
    setShowInput(!showInput);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = async () => {
    if (formValues.visitorType) {
      try {
        // Send formValues to API endpoint
        const response = await axios.post('/VisitorType', { visitorTypeDesc: formValues.visitorType });
              // Check if the response indicates the visitor type already exists
      if (response.data === "Visitor type already exists") {
        toast.error("Visitor Type already Exists");
      } else {


        console.log('Visitor type added successfully:', response.data);

      // Refetch the updated table data from the API
      const updatedData = await axios.get('/VisitorType');
      setTableData(updatedData.data);

        // Reset form state
        setFormValues({
          visitorType: '',
        });

        // Show pagination after submitting at least one row
        setShowPagination(true);
        // Show success toast message
        toast.success("Visitor Type added successfully");
      }
    } catch (error) {
      console.error('Error adding visitor type:', error);
      // Handle error state or UI updates
      toast.error('Error adding visitor type');
    }
  }
};


  const handleDelete = async (id) => {
    try {
      // Sending the PUT request to mark the Proof as deleted
      await axios.put('/VisitorType', {
        id: id,
        visitorTypeDesc: tableData.find(tableData => tableData.id === id)?.visitorTypeDesc,
        isDeleted: true
      });

      // Update the local state to remove the deleted proof from the UI
      setTableData((prevData) => prevData.filter(tableData => tableData.id !== id));
      // Show success toast message
      toast.success('Visitor Deleted Successfully');

    } catch (error) {
      console.error('Error deleting Visitor Type:', error);
      // Handle the error (e.g., show a notification to the user)
      // Show error toast message
      toast.error('Failed to Delete Visitor Type');

    }
  };


  const handleReset = () => {
    setFormValues({
      visitorType: '',
    });
    setCurrentPage(1); // Reset pagination to the first page
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredData = tableData.filter((row) =>
    row.visitorTypeDesc && row.visitorTypeDesc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };

  return (
    <Container>
      <Header>
        <Heading>Visitor Type </Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <Card>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button className="add-location" onClick={handleAddLocationClick}>
            Add Visitor {showInput ? <FiMinus size={20} /> : <FiPlus size={20} />}
          </Button>
          {showInput && (
            <FormGroup>
              <FormItem>
                <Label>
                  Visitor Type<Asterisk>*</Asterisk>
                </Label>
                <Input
                  type="text"
                  name="visitorType"
                  value={formValues.visitorType}
                  onChange={handleInputChange}
                  placeholder="Enter visitor type"
                />
              </FormItem>
            </FormGroup>
          )}
        </div>
        <ButtonGroup>
          <ResetButton onClick={handleReset}>Reset</ResetButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </ButtonGroup>

      </Card>
      {filteredData.length === 0 ? (
        <ZeroStateMessage>No data available</ZeroStateMessage>
      ) : (
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Visitor Type</TableHeader>
                <TableHeader>Actions</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.visitorTypeDesc}</TableCell>
                  <TableCell>
                  <IconWrapper onClick={() => handleDelete(row.id)}>
                  <FiTrash2 />
                    </IconWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
          <ToastContainer />
        </TableContainer>
      )}
            <ToastContainer /> 

    </Container>
  );
};

export default VisitorTypeMapPage;
