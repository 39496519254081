import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown } from 'react-icons/fi'; // Using react-icons library
import axios from 'axios';
import { FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';


const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  box-shadow: 0px 5px 10px #00000038;
  background: #fff;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  &:first-child {
    background-color: #FF4B4B;
    box-shadow: 0px 10px 20px #7E18182E;
  }
  &:last-child {
    background-color: #2B3497;
    box-shadow: 0px 10px 20px #2B349752;
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
`;

const DropdownGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Label = styled.label`
  color: #3D46B4;
  margin-bottom: 5px;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3D46B4;
  padding: 10px;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  position: relative;
  background: transparent;
`;

const DropdownText = styled.span`
  color: #293495;
  font-size: 16px;
`;

const DropdownIcon = styled(FiChevronDown)`
  color: #3D46B4;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: calc(100% + 5px); /* Positioning panel just below the dropdown */
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #3D46B4;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;
const ExportIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: #28a745; /* Green color for Excel icon */
`;

const MonthwiseVisitorPage = () => {
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const monthDropdownRef = useRef(null);
  const yearDropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMonthOptionClick = (option) => {
    setSelectedMonth(option);
    setIsMonthOpen(false);
  };

  const handleYearOptionClick = (option) => {
    setSelectedYear(option);
    setIsYearOpen(false);
  };

  const handleClickOutside = (event) => {
    if (monthDropdownRef.current && !monthDropdownRef.current.contains(event.target)) {
      setIsMonthOpen(false);
    }
    if (yearDropdownRef.current && !yearDropdownRef.current.contains(event.target)) {
      setIsYearOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchVisitorTypeData = async () => {
    try {
      const response = await axios.get('/VisitorDetails/GetVisitorReports');
      setTableData(response.data);
      setFilteredData(response.data); // Initialize filteredData with full data
    } catch (error) {
      console.error('Error fetching Visitor Report data:', error);
    }
  };

  useEffect(() => {
    fetchVisitorTypeData();
  }, []);

  useEffect(() => {
    let data = tableData;
    if (selectedMonth) {
      data = data.filter((row) => new Date(row.checkInDateTime).toLocaleString('default', { month: 'long' }) === selectedMonth);
    }
    if (selectedYear) {
      data = data.filter((row) => new Date(row.checkInDateTime).getFullYear().toString() === selectedYear);
    }
    if (searchQuery) {
      data = data.filter((row) =>
        row.visitorName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredData(data);
    setCurrentPage(1); // Reset to first page on filter change
  }, [selectedMonth, selectedYear, searchQuery, tableData]);

  const handleSearch = () => {
    // Trigger filtering when search button is clicked
    setFilteredData(filteredData); // Ensure filteredData is updated
  };

  const handleReset = () => {
    setSelectedMonth('');
    setSelectedYear('');
    setSearchQuery('');
    fetchVisitorTypeData(); // Refresh data to show the full list
  };

  const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  const formatDateTime = (dateTime) => {
    return dateTime.replace('T', ' ');
  };
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData.map(row => ({
      'Visitor Name': row.visitorName,
      'Visitor Mobile': row.visitorMobile,
      'Visitor Company': row.visitorCompany,
      'Host Name': row.host,
      'Location Name': row.meetingVenue,
      'Appointment Date': formatDateTime(row.appointmentDate)
    })));
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Visitor Reports');
  
    XLSX.writeFile(wb, 'VisitorReports.xlsx');
  };

  return (
    <Container>
      <Header>
        <Heading>Monthwise Visitor Report</Heading>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <SearchBox>
          <SearchIcon />
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by Visitor Name"
          />
        </SearchBox>
        <ExportIconContainer onClick={exportToExcel} title="Export to Excel">
            <FaFileExcel size={20} />
          </ExportIconContainer>
</div>

      </Header>
      <Card>
        <DropdownGroup>
          <DropdownContainer ref={monthDropdownRef}>
            <Label>Month</Label>
            <Dropdown onClick={() => setIsMonthOpen(!isMonthOpen)}>
              <DropdownText>{selectedMonth || 'Select Month'}</DropdownText>
              <DropdownIcon />
            </Dropdown>
            {isMonthOpen && (
              <DropdownPanel>
                {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
                  <DropdownOption key={month} onClick={() => handleMonthOptionClick(month)}>
                    {month}
                  </DropdownOption>
                ))}
              </DropdownPanel>
            )}
          </DropdownContainer>
          <DropdownContainer ref={yearDropdownRef}>
            <Label>Year</Label>
            <Dropdown onClick={() => setIsYearOpen(!isYearOpen)}>
              <DropdownText>{selectedYear || 'Select Year'}</DropdownText>
              <DropdownIcon />
            </Dropdown>
            {isYearOpen && (
              <DropdownPanel>
                {['2020', '2021', '2022', '2023', '2024'].map((year) => (
                  <DropdownOption key={year} onClick={() => handleYearOptionClick(year)}>
                    {year}
                  </DropdownOption>
                ))}
              </DropdownPanel>
            )}
          </DropdownContainer>
        </DropdownGroup>
        <ButtonGroup>
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleSearch}>Search</Button>
        </ButtonGroup>
      </Card>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <TableHeader>Visitor Name</TableHeader>
              <TableHeader>Location Name</TableHeader>
              <TableHeader>Employee Name</TableHeader>
              <TableHeader>Check-In Date Time</TableHeader>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.visitorName}</TableCell>
                  <TableCell>{row.locationName}</TableCell>
                  <TableCell>{row.employeeName}</TableCell>
                  <TableCell>{formatDateTime(row.checkInDateTime)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="4">No data available</TableCell>
              </TableRow>
            )}
          </tbody>
        </StyledTable>
        <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
      </TableContainer>
    </Container>
  );
};

export default MonthwiseVisitorPage;
