import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import UsernameContext from '../auth/UsernameContext';
import axios from 'axios'; // Import Axios or use the configured instance
import { useNavigate } from 'react-router-dom';

const TopbarContainer = styled.div`
  width: 100%;
  height: 52px;
  background-color: #F3F3F3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #293495;
  position: fixed;
  top: 0;
  z-index: 1000;
  padding: 0 0px;
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 10px;
  }
`;

const LeftText = styled.div`
  color: #fff;
  font-family: Lucid sans-serif;
  font-size: 38px;
  width: 200px;
  font-weight:500;
  background-color: rgb(24, 62, 159)!important;
  padding: 6px 1px 4px 22px;
`;

const CenterText = styled.div`
  flex: 1;
  text-align: center;
  font-size: 20px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  position:relative;
  right:37px;
`;

const Badge = styled.div`
  background: linear-gradient(135deg, #FFFFFF, #E9E9E9);
  color: #293495;
  border: 2px solid #293495;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 10px;
`;

const UserName = styled.div`
  font-size: 16px;
  line-height: 1;
  font-family: 'Segoe UI', Semibold;
`;

const SelectedRoleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const SelectedRole = styled.div`
  font-size: 12px;
  color: #707070;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  font-size: 13px;
  padding: 10px;
`;

const DropdownButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const DropdownIcon = styled.span`
  font-size: 10px;
  margin-left: 8px;
`;

const TopNavBar = ({ setSelectedRole: setAppSelectedRole }) => {
  const { username } = useContext(UsernameContext);
  const [employeeName, setEmployeeName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [roles, setRoles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [localSelectedRole, setLocalSelectedRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedRole = localStorage.getItem('selectedRole');
    if (savedRole) {
      setLocalSelectedRole(savedRole);
      setAppSelectedRole(savedRole);
      console.log('Retrieved role from localStorage:', savedRole);
    }
  }, [setAppSelectedRole]);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleRoleSelect = (role) => {
    setLocalSelectedRole(role.txtRoles); // Set the role name
    setAppSelectedRole(role.txtRoles); // Update the selected role in the parent component
    setShowDropdown(false);
    localStorage.setItem('selectedRole', role.txtRoles);
    localStorage.setItem('selectedRoleId', role.roleId); // Save the selected role to localStorage
    // Save the selected role to localStorage

    // Log selected role details
    console.log('Selected role object:', role); // Log both txtRoles and roleId
    navigate('/layout/Dashboard/Dashboard'); // Redirect to dashboard
  };

  useEffect(() => {
    if (username) {
      fetchEmployeeMail(username);
    }
  }, [username]);

  const fetchEmployeeMail = async (empEmail) => {
    try {
      const response = await axios.get(`/Employee/EmployeeMail/${empEmail}`);
      const empId = response.data.id;

      setEmployeeName(response.data.employeeName);
      setEmployeeId(empId);
      fetchEmployeeRoles(empId);

      console.log('employeeName', response.data.employeeName);
      localStorage.setItem('employeeName', response.data.employeeName);

      console.log('employeeId', empId);
      localStorage.setItem('employeeId', empId);
    } catch (error) {
      console.error('Error fetching employee email:', error);
    }
  };

  const fetchEmployeeRoles = async (empId) => {
    try {
      const response = await axios.get(`/RoleManagement/GetRoles`, { params: { empId } });
      const rolesData = response.data;

      if (rolesData && rolesData.length > 0) {
        setRoles(rolesData); // Set the entire role objects in the state

        if (rolesData.length > 0 && !localStorage.getItem('selectedRole')) {
          setLocalSelectedRole(rolesData[0].txtRoles);
          setAppSelectedRole(rolesData[0].txtRoles);
          localStorage.setItem('selectedRole', rolesData[0].txtRoles);
          console.log('Setting initial role:', rolesData[0].txtRoles);
        }
      } else {
        setRoles([]);
        setLocalSelectedRole('');
      }
    } catch (error) {
      console.error('Error fetching employee roles:', error);
    }
  };

  useEffect(() => {
    console.log('Roles:', roles);
    console.log('Selected Role:', localSelectedRole);
    localStorage.setItem('roleID',1)
  }, [roles, localSelectedRole]);

  return (
    <TopbarContainer>
      <LeftText>Infosys</LeftText>
      <CenterText>Visitor Management System</CenterText>
      <RightContainer>
        <Badge>A</Badge>
        <Dropdown>
          <DropdownButton onClick={handleDropdownToggle}>
            <UserName>{employeeName}</UserName>
            <SelectedRoleContainer>
              <SelectedRole>{localSelectedRole}</SelectedRole>
              <DropdownIcon>&#9660;</DropdownIcon>
            </SelectedRoleContainer>
          </DropdownButton>
          <DropdownButton>
            <DropdownContent show={showDropdown ? 'true' : undefined}>
              {roles.map(role => (
                <p key={role.roleId} onClick={() => handleRoleSelect(role)}>
                  {role.txtRoles}
                </p>
              ))}
            </DropdownContent>
          </DropdownButton>
        </Dropdown>
      </RightContainer>
    </TopbarContainer>
  );
};

export default TopNavBar;
