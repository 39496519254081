import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiEye, FiEdit, FiTrash2, FiPlus, FiMinus } from 'react-icons/fi'; // Using react-icons library
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import for confirmation dialog
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the confirmation dialog CSS

import axios from 'axios';

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;


const Card = styled.div`
  background-color: #f5f5f5;
  box-shadow: 0px 5px 10px #00000038;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically */
  margin-bottom: 20px;
  width: 100%;
  gap: 10px;  // Add this line to create space between items

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormItem = styled.div`
  flex: 1 1 300px; /* Flex-grow, flex-shrink, and minimum width */
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', 'Semibold';
  color: #2B3497;
  margin-bottom: 5px;
  font-weight: 600;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', 'Regular';
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Segoe UI', 'Semibold';
  border-radius: 27px;
  opacity: 1;
  color: #fff;
  margin-right: 30px; // Space to the right of the button



&.add-location {
  background-color: #293495;
  white-space: nowrap; /* Prevents text from wrapping */

}
    &.reset {
    background-color: red;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  &.submit {
    background-color: #3d46b4;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  & > svg {
    margin-left: 8px;
  }`
  ;


const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  color: #2B3497;
  margin: 0 5px;
`;
const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;
const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;
const Dropdown = styled.select`
  border: none;
  border-bottom: 1px solid #2B3497;
  padding: 8px 0;
  background: transparent;
  font-family: 'Segoe UI', Regular;
  outline: none; /* Remove default focus outline */
  margin-right: 20px; // Space to the right of the dropdown
  flex: 1; // Makes the dropdown take up more space
`;



const EmployeeRoleMapPage = () => {
  const [showInput, setShowInput] = useState(false);

  const [formValues, setFormValues] = useState({
    employeeRole: '',
    employeeName: '',
  });

  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [empNames, setEmpName] = useState([]);


  const rowsPerPage = 7;
    // Fetch Employee Role Map data from API
      const fetchEmployeeRoleMapData = async () => {
        try {
          const response = await axios.get('/RoleManagement/GetEmployeeRoleMapping');
          setTableData(response.data);
        } catch (error) {
          console.error('Error fetching Employee Role Map data:', error);
        }
      };
  
  // Call fetchEmployeeRoleMapData once on component mount
  useEffect(() => {
    fetchEmployeeRoleMapData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddLocationClick = () => {
    setShowInput(!showInput);
  };

  const handleSubmit = async () => {
    if (formValues.employeeRole && formValues.employeeName) {

      try {
        const currentDate = new Date().toISOString(); // Get current date in ISO string format
        const response = await axios.post('/RoleManagement/AddEmployeeRoleMapping', {
          intRoleId:formValues.employeeRole,
          intEmpId:formValues.employeeName,
          isActive:true,
          txtCreatedBy:'string',
          dtCreatedOn:currentDate,
          txtModifiedBy:'string',
          dtModifiedOn:currentDate
        });

      // Check if the response indicates that the details already exist
      if (response.data === 'Detail already exists') {
        // Show a toast message for duplicate entry
        toast.error('Detail already exists. Please enter different values.');
        return; // Exit the function, do not add data to the table
      }

      // Handle successful response
      if (response.status === 200) {
        toast.success('Employee role mapping added successfully!');

        // Re-fetch the updated table data to ensure it's up-to-date
        fetchEmployeeRoleMapData(); // Re-fetch the entire table data
        setFormValues({
          employeeRole: '',
          employeeName: '',
        });
        setShowPagination(true); // Show pagination after submitting at least one row
      } else {
        console.error('Failed to add employee role mapping:', response.data);
      }
    } catch (error) {
      console.error('Error adding employee role mapping:', error);
      toast.error('Error occurred while adding employee role mapping.');
    }
  } else {
    toast.warning('Please fill out both employee role and employee name.');
  }
};


const handleDelete = (id) => {
  // Show confirmation dialog
  confirmAlert({
    title: 'Confirm to delete',
    message: 'Are you sure you want to delete this row?',
    buttons: [
      {
        label: 'Yes',
        onClick: async () => {
          try {
            // Get current date in ISO string format
            const currentDate = new Date().toISOString();
            
            // Call the API to update the row to mark as inactive
            const response = await axios.delete(`/RoleManagement/DeleteEmployeeRoleMap/${id}`, {
              isActive: false, // Mark as inactive
              dtModifiedOn: currentDate
            });

            if (response.status === 200) {
                            // Show success toast message
                            toast.success('Deleted successfully');

              // Remove the row from tableData after successful API response
              setTableData((prevData) => prevData.filter((row) => row.id !== id));

            } else {
              // Handle failure response from the API
              toast.error('Failed to delete the item');
            }
          } catch (error) {
            console.error('Error deleting data:', error);
            toast.error('There was an error deleting the item');
          }
        }
      },
      {
        label: 'No',
        onClick: () => {
          // Do nothing if "No" is clicked
          toast.info('Delete cancelled');
        }
      }
    ]
  });
};

  const handleReset = () => {
    setFormValues({
      employeeName: '',
      employeeRole: '',
    });
    setCurrentPage(1); // Reset pagination to the first page

  };
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const filteredData = tableData.filter((row) =>
    row.empName && row.empName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const roleNameResponse = await axios.get('/RoleManagement/GetRolesDetails');
        setRoles(roleNameResponse.data);

         const employeeNameResponse = await axios.get('/employee');
         setEmpName(employeeNameResponse.data);


      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);
  

  return (
    <Container>
      <Header>
        <Heading>Employee Role Map</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <Card>     
           <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button className="add-location" onClick={handleAddLocationClick}>
            Add Role {showInput ? <FiMinus size={20} /> : <FiPlus size={20} />}
          </Button>
          {showInput && (

          <FormItem>
            <Label>
              Employee Role<Asterisk>*</Asterisk>
            </Label>
            <Dropdown
            name="employeeRole"
            value={formValues.employeeRole}
            onChange={handleInputChange}
            style={{ fontSize:"12px" }}

          >
            <option value="">Select Employee Role</option>
            {roles.map((role) => (
              <option key={role.id} value={role.roleId}>
                {role.txtRoles}
              </option>
            ))}
          </Dropdown>                  
          </FormItem>
        
)}
{showInput && (

          <FormItem>
            <Label>
              Employee Name<Asterisk>*</Asterisk>
            </Label>
            <Dropdown
            name="employeeName"
            value={formValues.employeeName}
            onChange={handleInputChange}
            style={{ fontSize:"12px" }}

          >
            <option value="">Select Employee Name</option>
            {empNames.map((empName) => (
              <option key={empName.id} value={empName.id}>
                {empName.employeeName}
              </option>
            ))}
          </Dropdown>                  
          </FormItem>

    )}
    </div>

        <ButtonGroup>
          <Button className="reset" onClick={handleReset}>
            Reset
          </Button>
          <Button className="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </ButtonGroup>
      </Card>
      {filteredData.length === 0 ? (
        <ZeroStateMessage>No data available</ZeroStateMessage>
      ) : (
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Employee Role</TableHeader>
                <TableHeader>Employee Name</TableHeader>
                <TableHeader>Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>{row.empName}</TableCell>
                  <TableCell>
                    <IconWrapper onClick={() => alert(`View ${row.employeeName}`)}>
                      <FiEye />
                    </IconWrapper>
                    <IconWrapper onClick={() => alert(`Edit ${row.employeeName}`)}>
                      <FiEdit />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleDelete(row.id, index)}>
                    <FiTrash2 />
                    </IconWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
        </TableContainer>
      )}
            <ToastContainer />
    </Container>
  );
};

export default EmployeeRoleMapPage;
