import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchVisitorTypes, fetchMeetingVenues, insertAppointment, fetchGateDetails, fetchBuildingsByGate } from '../../services/Api';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { linkMailAndSms } from '../../services/Api';
import UsernameContext from '../../components/auth/UsernameContext';
import axios from 'axios';
import { AppIdContext } from './AppIdContext';
import CryptoJS from 'crypto-js';
import moment from 'moment/moment';
import { format } from 'date-fns'; // Import the format function from date-fns


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 25px;
  font-family: 'Segoe UI', Semibold;
  color: #060000;
  margin: 0;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 81px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px #2B349754;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Semibold;
`;

const ResetButton = styled(Button)`
  background-color: red;
`;

const SubmitButton = styled(Button)`
  background-color: #2B3497;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
    @media (max-width: 1920px) {
    display:flex;
    justify-content: space-evenly;
  }
`;

const Card = styled.div`
  width: 420px;
  background: radial-gradient(circle, #FFFFFF, #E3E3E3);
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 15px;
  padding: 27px;
  opacity: 1;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
    @media (max-width: 1920px) {
     width: 100%;
    height: auto;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', Semibold;
  color:#000000;
  margin-bottom: 5px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 415px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2B3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2B3497;
    }
  }
    @media (max-width: 1920px) {
       width: 745px;

  }
       @media (max-width: 1820px) {
       width: 689px;

  }
       @media (max-width: 1720px) {
       width: 644px;

  }
       @media (max-width: 1620px ) {
       width: 587px;

  }
           @media (max-width: 1520px ) {
       width: 587px;

  }
           @media (max-width: 1420px ) {
       width: 487px;

  }
`;

const BookAppointment = () => {
  const { username } = useContext(UsernameContext); // Access username from context
  const [appId, setAppId] = useState(null);

  const [linkMailAndSmsResponse, setLinkMailAndSmsResponse] = useState(null);

  const [selectedGate, setSelectedGate] = useState('');
  const [selectedVisitorType, setSelectedVisitorType] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [userId, setUserId] = useState('');


  const [formData, setFormData] = useState({
    visitorName: '',
    visitorEmail: '',
    visitorMobile: '',
    selectedVisitorTypeId: selectedVisitorType,
    purpose: '',
    meetingOn: null,
    meetingTo: null,
    meetingvenue: '',
    gate: '',
    building: '',
    appointmentstatus: 'Scheduled',
    userId: '',
    employeeId: '',
    isCoupon: '3',
  });
  useEffect(() => {
    if (username) {
      fetchEmployeeMail(username);
      localStorage.setItem('username', username); // Store the username in local storage

    }
  }, [username]);

  const fetchEmployeeMail = async (username) => {
    try {
      const response = await axios.get(`/User/UserDets/${username}`);
      console.log('response', response.data);
      if (response.data.length > 0) {

        setUserId(response.data[0].id);
        setEmployeeId(response.data[0].empId);
      } else {
        console.error('No user data found for username:', username);
        // Optionally handle case where no data is found
      }

    } catch (error) {
      console.error('Error fetching employee email:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId,
      }));
    }
    console.log('userId', userId);
  }, [userId]);

  useEffect(() => {
    if (employeeId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeId,
      }));
    }
    console.log('employeeId', employeeId);
  }, [employeeId]);

  const [visitorTypes, setVisitorTypes] = useState([]);
  const [meetingvenues, setMeetingvenues] = useState([]);
  const [gates, setGates] = useState([]);
  const [buildings, setBuildings] = useState([]);


  const [formErrors, setFormErrors] = useState({
    visitorName: false,
    visitorEmail: false,
    visitorMobile: false,
    selectedVisitorTypeId: false,
    purpose: false,
    meetingOn: false,
    meetingTo: false,
    meetingvenue: false,
    gate: false,
    building: false,
  });
  
  const handleReset = () => {
    setFormData({
      visitorName: '',
      visitorEmail: '',
      visitorMobile: '',
      selectedVisitorTypeId: '',
      purpose: '',
      meetingOn: null,
      meetingTo: null,
      meetingvenue: '',
      gate: '',
      building: '',
    });
    setFormErrors({
      visitorName: false,
      visitorEmail: false,
      visitorMobile: false,
      selectedVisitorTypeId: false,
      purpose: false,
      meetingOn: false,
      meetingTo: false,
      meetingvenue: false,
      gate: false,
      building: false,
    });
    setSelectedGate('');
    setBuildings([]);

    console.log("Form reset");
  };
  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    // Perform validation before submitting
    let valid = true;
    const errors = {};

    // Validate required fields
    if (formData.visitorName === '') {
      errors.visitorName = true;
      valid = false;
    }
    if (formData.visitorEmail === '') {
      errors.visitorEmail = true;
      valid = false;
    }
    if (formData.visitorMobile === '') {
      errors.visitorMobile = true;
      valid = false;
    }
    if (formData.selectedVisitorTypeId === '') {
      errors.selectedVisitorTypeId = true;
      valid = false;
    }
    if (formData.purpose === '') {
      errors.purpose = true;
      valid = false;
    }
    if (formData.meetingOn === null) {
      errors.meetingOn = true;
      valid = false;
    }
    if (formData.meetingTo === null) {
      errors.meetingTo = true;
      valid = false;
    }
    if (formData.meetingvenue === '') {
      errors.meetingvenue = true;
      valid = false;
    }
    if (formData.gate === '') {
      errors.gate = true;
      valid = false;
    }
    if (formData.building === '') {
      errors.building = true;
      valid = false;
    }


    // Validate email format
    if (formData.visitorEmail !== '') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.visitorEmail)) {
        errors.visitorEmail = true;
        valid = false;
      }
    }

    // Validate mobile number format
    if (formData.visitorMobile !== '') {
      const mobilePattern = /^\d{10}$/;
      if (!mobilePattern.test(formData.visitorMobile)) {
        errors.visitorMobile = true;
        valid = false;
      }
    }
      // Validate meetingOn and format it to "yyyy-MM-dd HH:mm:ss"
  if (formData.meetingOn !== null) {
    const formattedMeetingOn = format(new Date(formData.meetingOn), 'yyyy-MM-dd HH:mm:ss');
    formData.meetingOn = formattedMeetingOn;
  } else {
    errors.meetingOn = true;
    valid = false;
  }
// Validate meetingTo and format it to "yyyy-MM-dd HH:mm:ss"
if (formData.meetingTo !== null) {
  const formattedMeetingTo = format(new Date(formData.meetingTo), 'yyyy-MM-dd HH:mm:ss');
  formData.meetingTo = formattedMeetingTo;
} else {
  errors.meetingTo = true;
  valid = false;
}

        // Validate meeting times
        if (!validateMeetingTimes()) {
          valid = false;
        }
    
  // Update form errors state
  setFormErrors(errors);

  // Prevent form submission if validation fails
  if (!valid) {
    console.log("Form has errors:", errors);
    return; // Exit function if form is invalid
  }
// Check if the appointment can be booked for the selected time
try {
  const response = await axios.get(`/Appointment/GetAppointmentbyHost/${employeeId},${formData.meetingOn}`);
  
  if (response.data && response.data.some(appointment => {
    const formattedExistingMeetingOn = new Date(appointment.meetingOn).toISOString().slice(0, 19).replace('T', ' ');
    const formattedExistingValidTill = new Date(appointment.validTill).toISOString().slice(0, 19).replace('T', ' ');
    const formattedFormMeetingOn = new Date(formData.meetingOn).toISOString().slice(0, 19).replace('T', ' ');

    // Check if the selected meetingOn time matches an existing appointment's meetingOn or validTill
    return (
      formattedExistingMeetingOn === formattedFormMeetingOn || 
      formattedExistingValidTill === formattedFormMeetingOn
    );
  })) {
    toast.error('An appointment already exists for the selected time. Please choose another time slot.');
    return; // Exit function if the appointment cannot be booked
  }
} catch (error) {
  console.error('Error checking appointment availability:', error);
  toast.error('Failed to check appointment availability. Please try again.');
  return; // Exit function if there's an error in checking availability
}
  
  // Convert date to ISO 8601 format with local time
  const toLocalISOString = (date) => {
    const tzOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1); // Remove 'Z' at the end
    return localISOTime + 'Z'; // Add 'Z' at the end
  };

  const formattedData = {
    ...formData,
    meetingOn: toLocalISOString(new Date(formData.meetingOn)),
    meetingTo: toLocalISOString(new Date(formData.meetingTo))
  };


  console.log("Form data:", formData);
  console.log("Form data:", formattedData);
  // Proceed with form submission using the formattedData


    try {
      const appId = await insertAppointment(formattedData);
      setAppId(appId);
      const secretKey = CryptoJS.lib.WordArray.random(128 / 8).toString();
      console.log('Generated Secret Key:', secretKey);

      const encryptedAppId = CryptoJS.AES.encrypt(appId, 'secretKey').toString(); // Encrypt the appId
      localStorage.setItem('appId', encryptedAppId); // Store encrypted appId in local storage

      //localStorage.setItem('appId', appId); // Store appId in local storage
      console.log('encryptedAppId', encryptedAppId)

      console.log('appointment id', appId)

      // Call the second API with appointmentId
      const linkMailAndSmsResponse = await linkMailAndSms(appId);
      setLinkMailAndSmsResponse(linkMailAndSmsResponse)
      console.log('Link Mail and SMS Response:', linkMailAndSmsResponse);


      if (appId) {
        toast.success('Form Submitted successfully Please check the Email for Appointment!');
        handleReset();

      } else {
        toast.error('Failed to book appointment');
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: false,
      });
    }
  };

  // const handleDateChange = (date, name) => {
  //   setFormData({
  //     ...formData,
  //     [name]: date,
  //   });
  // };
  const handleDateChange = (date, field) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: date,
    }));
    if (formErrors[field]) {
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        [field]: false,
      }));
    }
  };

  const currentDateTime = new Date(); // Current date and time

  // Function to filter out times earlier than the current time
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // Function to highlight the current time
  const highlightTime = (time) => {
    const now = new Date();
    const timeToHighlight = now.getHours() * 60 + now.getMinutes();
    const selectedTime = time.getHours() * 60 + time.getMinutes();

    // Highlight the time close to the current system time
    return selectedTime === timeToHighlight ? 'highlight-time' : '';
  };

  const validateMeetingTimes = () => {
    if (formData.meetingOn && formData.meetingTo && formData.meetingOn >= formData.meetingTo) {
      toast.error('Meeting end time must be greater than the start time');
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        meetingTo: true,
      }));
      return false;
    }
    return true;
  };

  const adjustForTimezoneOffset = (date) => {
    if (!date) return null;
    const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, -1);
    return localISOTime;
  };


  useEffect(() => {
    const getVisitorTypes = async () => {
      try {
        const types = await fetchVisitorTypes();
        setVisitorTypes(types);
      } catch (error) {
        console.error('Error fetching visitor types:', error);
      }
    };

    const getMeetingvenues = async () => {
      try {
        const venues = await fetchMeetingVenues();
        setMeetingvenues(venues);
      } catch (error) {
        console.error('Error fetching meeting venues:', error);
      }
    };
    const getGateDetails = async () => {
      try {
        const venues = await fetchGateDetails();
        setGates(venues);
      } catch (error) {
        console.error('Error fetching Gate venues:', error);
      }
    };

    getVisitorTypes();
    getMeetingvenues();
    getGateDetails();
  }, []);
  useEffect(() => {
    if (selectedGate) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        gate: selectedGate.name, // Use the gate name in the form data
      }));
    }
  }, [selectedGate]);

  const handleGateChange = async (event) => {
    const gateID = parseInt(event.target.value, 10); // Convert to integer if gate.id is a number
    const selectedGateObject = gates.find(gate => gate.id === gateID);

    setSelectedGate(selectedGateObject); // Store the entire gate object


    try {
      const buildings = await fetchBuildingsByGate(gateID);
      setBuildings(buildings);
    } catch (error) {
      console.error('Error fetching buildings:', error);
    }
  };

  return (
    <Container>
      <Header>
        <Heading>Book Appointment</Heading>
        <ButtonGroup>
          <ResetButton onClick={handleReset}>Reset</ResetButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </ButtonGroup>
      </Header>
      <CardContainer>
        <Card>
          <FormGroup>
            <Label>
              Visitor Name
              <Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="visitorName"
              value={formData.visitorName}
              onChange={handleChange}
              placeholder="Enter Visitor Name"
              style={{ borderColor: formErrors.visitorName ? 'red' : '#2B3497' }}
            />
                        {formErrors.visitorName && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please fill in the visitor name</span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Email
              <Asterisk> *</Asterisk>
            </Label>
            <Input
              type="email"
              name="visitorEmail"
              value={formData.visitorEmail}
              onChange={handleChange}
              placeholder="Enter Visitor E-mail"
              style={{ borderColor: formErrors.visitorEmail ? 'red' : '#2B3497' }}
            />
                        {formErrors.visitorEmail && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                {formData.visitorEmail ? 'Invalid email format' : 'Please fill in the visitor email'}
              </span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Mobile
              <Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="visitorMobile"
              value={formData.visitorMobile}
              onChange={handleChange}
              placeholder="Enter 10-digit Mobile Number"
              maxLength="10"
              style={{ borderColor: formErrors.visitorMobile ? 'red' : '2B3497' }}
            />
                        {formErrors.visitorMobile && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                {formData.visitorMobile ? 'Invalid mobile number' : 'Please fill in the mobile number'}
              </span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Visitor Type
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="selectedVisitorTypeId"
              value={formData.selectedVisitorTypeId}
              onChange={handleChange}
            >
              <option value="">Select Visitor Type</option>
              {visitorTypes.map((type) => (
                <option key={type.id} value={type.id}>{type.visitorTypeDesc}</option>
              ))}
            </Select>
            {formErrors.selectedVisitorTypeId && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a visitor type</span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Purpose
              <Asterisk> *</Asterisk>
            </Label>
            <Input
              type="text"
              name="purpose"
              value={formData.purpose}
              onChange={handleChange}
              placeholder="Enter Purpose"
            />
                        {formErrors.purpose && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please fill in the purpose</span>
            )}

          </FormGroup>
        </Card>
        <Card>
        <FormGroup>
        <Label>
          Meeting On
          <span style={{ color: 'red' }}> *</span>
        </Label>
        <StyledDatePicker
          selected={formData.meetingOn}
          onChange={(date) => handleDateChange(date, 'meetingOn')}
          showTimeSelect
          dateFormat="Pp"
          minDate={currentDateTime}
          filterTime={filterPassedTime}
          timeClassName={highlightTime}
          placeholderText="Select Meeting Start Date and Time"

        />
        {formErrors.meetingOn && (
          <span style={{ color: 'red', fontSize: '12px' }}>Please select a meeting start time</span>
        )}
      </FormGroup>
      <FormGroup>
        <Label>
          Meeting To
          <span style={{ color: 'red' }}> *</span>
        </Label>
        <StyledDatePicker
          selected={formData.meetingTo}
          onChange={(date) => handleDateChange(date, 'meetingTo')}
          showTimeSelect
          dateFormat="Pp"
          minDate={formData.meetingOn || currentDateTime} // Ensure meetingTo is after meetingOn
          filterTime={filterPassedTime}
          timeClassName={highlightTime}
          placeholderText="Select Meeting End Date and Time"

        />
        {formErrors.meetingTo && (
          <span style={{ color: 'red', fontSize: '12px' }}>Please select a meeting end time</span>
        )}
      </FormGroup>
          <FormGroup>
            <Label>
              Meeting Venue
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="meetingvenue"
              value={formData.meetingvenue}
              onChange={handleChange}
            >
              <option value="">Select Meeting Venue</option>
              {meetingvenues.map((type) => (
                <option key={type.id} value={type.locationName}>
                  {type.locationName}
                </option>
              ))}
            </Select>
            {formErrors.meetingvenue && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a meeting venue</span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Gate
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="gate"
              value={selectedGate ? selectedGate.id : ''}
              onChange={handleGateChange}
            >
              <option value="">Select Gate</option>
              {gates.map((gate) => (
                <option key={gate.id} value={gate.id}>
                  {gate.name}
                </option>
              ))}
            </Select>
            {formErrors.gate && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a gate</span>
            )}

          </FormGroup>
          <FormGroup>
            <Label>
              Building
              <Asterisk> *</Asterisk>
            </Label>
            <Select
              name="building"
              value={formData.building}
              onChange={handleChange}
            >
              <option value="">Select Building</option>
              {Array.isArray(buildings) && buildings.map((building) => (
                <option key={building.id} value={building.name}>
                  {building.name}
                </option>
              ))}
            </Select>
            {formErrors.building && (
              <span style={{ color: 'red', fontSize: '12px' }}>Please select a building</span>
            )}

          </FormGroup>
        </Card>
      </CardContainer>
      <ToastContainer />

    </Container>
  );

};

export default BookAppointment;
