// ForgotPasswordModal.js

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { FaUser } from 'react-icons/fa';


const MainHeading = styled.h1`
  margin-top: 0px;
  color: #293495;
  text-align: center; /* Ensure the heading is centered */
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  padding-left: 40px; /* Added padding to accommodate icon */
  border: none;
  border-bottom: 2px solid rgba(43, 52, 151, 0.5); /* Bottom border color with 50% opacity */
  background: none;
  outline: none;
  font-family: 'Segoe UI', sans-serif; /* Set font family for input fields */
  &::placeholder {
    color: rgba(43, 52, 151, 0.5); /* Placeholder color with 50% opacity */
  }
`;

const Button = styled.button`
  background: linear-gradient(to right, #575FC1, #1F2884); /* Linear gradient background */
  color: #fff;
  padding: 0.5rem 1rem;
  border: 2px solid #fff;
  border-radius: 8px;
  cursor: pointer;
  width: 212px; /* Width of the button */
  height: 39px; /* Height of the button */
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.65); /* Drop shadow */
  &:disabled {
    background: gray; /* Disabled button background */
    cursor: not-allowed; /* Disabled button cursor */
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9em;
`;
const Icon = styled.div`
  position: absolute;
  left: 40px; /* Position the icon inside the input */
  top: 50%;
  transform: translateY(-50%);
  color: rgba(43, 52, 151, 0.5); /* Icon color with 50% opacity */
`;

const ForgotPasswordModal = ({ isOpen, setIsOpen }) => {
  const [formValues, setFormValues] = useState({
    username: '',
  });

  const [formErrors, setFormErrors] = useState({
    username: '',
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    let errors = {};
    if (!formValues.username.trim()) {
      errors.username = 'Username is required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const response = await axios.put(`/User/ForgotPassword/${formValues.username}`);
      console.log(response); // Log the entire response to debug

      if (response.status === 200) {
        toast.success('Password reset link sent successfully');
      } else {
        toast.error('Failed to send password reset link');
      }
    } catch (error) {
      console.error('Error:', error); // Log the error for debugging
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        toast.error(`Failed to send password reset link: ${error.response.data.message || error.response.statusText}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request data:', error.request);
        toast.error('No response from the server. Please try again later.');
      } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error message:', error.message);
        toast.error('An error occurred. Please try again.');
      }
    }

    // Reset form values and close modal
    setFormValues({
      username: '',
    });
    setFormErrors({});
    setIsOpen(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <MainHeading>Forgot Password</MainHeading>

        <InputContainer style={{ justifyContent: 'center' }}>
        <Icon><FaUser /></Icon>

          <Input
            type="text"
            placeholder="User Name"
            name="username"
            value={formValues.username}
            onChange={handleInput}
            style={{ width: '70%' }}
          />
        </InputContainer>
        {formErrors.username && <ErrorMessage>{formErrors.username}</ErrorMessage>}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button type="submit" style={{ marginRight: '10px' }}>Submit</Button>
          <Button type="button" onClick={() => setIsOpen(false)}>Cancel</Button>
        </div>
      </form>
    </ReactModal>
  );
};

export default ForgotPasswordModal;
