import styled from 'styled-components';
import cardone from '../../assets/cardone.png';
import cardtwo from '../../assets/cardtwo.png';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';

const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
  margin-bottom: 20px;
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 39px;
`;

const Card = styled.div`
  width: 288px;
  height: 101px;
  background-color: ${(props) => props.bgColor || '#fff'};
  box-shadow: 0px 10px 10px #00000038;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 320px) {
    width: 90%;
  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

const CardImage = styled.img`
  width: 35px;
  height: 40px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Segoe UI', 'Semibold';
`;

const CardTitle = styled.h2`
  font-size: 18px;
  color: ${(props) => props.textColor || '#000'};
`;

const CardSubtitle = styled.p`
  font-size: 14px;
  color: ${(props) => props.textColor || '#000'};
  margin-top: -1px;
`;

const CardCount = styled.div`
  font-size: 24px;
  color: ${(props) => props.textColor || '#000'};
  font-family: 'Segoe UI', 'Semibold';
`;

const SubHeading = styled.h2`
  font-size: 20px;
  font-family: 'Segoe UI', 'Semibold';
  margin: 5px 0;
`;


const RadioGroup = styled.div`
  display: flex;
  margin: 20px 0;
`;

const RadioLabel = styled.label`
  font-size: 16px;
  font-family: 'Segoe UI', 'Semibold';
  color: #293495;
  margin-right: 20px;
  font-weight: 600;
`;

const RadioInput = styled.input`
  margin-right: 10px;
`;


const InputCard = styled.div`
  width: 374px;
  height: 182px;
  box-shadow: 0px 10px 20px #00000038;
  background: #E6E6E6;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin: 20px auto 0;
  position: relative;
`;

const InputField = styled.input`
  background-color: transparent;
  width: 80%;
  height: 20px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #2B3497;
  border-radius: 0;
  text-align: left;
  outline: none;

  &::placeholder {
    color: #000000;
  }

  /* Remove up and down arrows for number input */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-moz-appearance {
    textfield;
  }
`;

const Button = styled.button`
  background-color: #2B3497;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 10px 20px #2B349747;
  opacity: 1;
  cursor: pointer;
  width: 150px;
`;
const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  background: linear-gradient(#FFFFFF, #F5F5F5, #FFFFFF);
  box-shadow: 0px 0px 45px #0000004A;
  border-radius: 30px;
  padding: 30px;
  text-align: center;
  opacity: 1;
  z-index: 1000;
  width: 400px;
  height: 125px;
`;

const PopupText = styled.p`
  font-size: 18px;
  color: #2B3497;
  margin-bottom: 20px;
`;

const PopupButton = styled.button`
  background-color: #2B3397;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 10px 20px #2B349754;
  opacity: 1;
  cursor: pointer;
`;

const GroupCheckoutPage = () => {
  const [selectedOption, setSelectedOption] = useState('mobile');
  const [mobileNumber, setMobileNumber] = useState('');
  const [qrumber, setQrNumber] = useState('');
  const [cardCounts, setCardCounts] = useState({
    mobileCheckOutCount: 0,
    qrcodeCheckOutCount: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupSuc, setShowPopupSuc] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };


  useEffect(() => {
    // Fetch the card counts from the API
    const fetchCardCounts = async () => {
      try {
        const response = await axios.get('https://vmsdevapi.azurewebsites.net/api/Dashboard');
        setCardCounts({
          mobileCheckOutCount: response.data.groupChkout,
          qrcodeCheckOutCount: response.data.groupWQChkout,
        });
      } catch (error) {
        console.error('Error fetching card counts:', error);
      }
    };

    fetchCardCounts();
  }, []);
  const handleInputChange = (e) => {
          // Only allow numeric input
  if (/^\d*$/.test(e.target.value)) {
    setMobileNumber(e.target.value);
  }

   // setMobileNumber(e.target.value);
  };
  const handleInputQrChange = (e) => {
    setQrNumber(e.target.value);
  };
  const handleNextButtonClick = async () => {
    try {

      const responseAppointment = await axios.get(`/GroupCheckIn/GroupCheckIn/${mobileNumber}`);
      console.log('Checkout Appointment API:', responseAppointment.data);
      if (responseAppointment.data.checkOutType === "OUT       ") {
        setMobileNumber('');
        setShowPopup(true);
        return;
      }
      if (responseAppointment.data.checkOutType == null) {
        const responseVisitorDeatils = await axios.put(`/GroupCheckIn/${mobileNumber}`);
        console.log("responseVisitorDeatils",responseVisitorDeatils.data);
        
        const AppId = responseAppointment.data.groupAppointmentId
        console.log("AppId", AppId);
        const Stat = "OUT"
        console.log("Stat", Stat);
        const updateAppoinmentStatus = await axios.post(`/GroupAppointment/AppointStatusUpd/${AppId},${Stat}`);
        console.log('update GroupAppointment API:', updateAppoinmentStatus);
        const empID = localStorage.getItem('employeeId');
        const getManagerDetails = await axios.get(`/Employee/${empID}`);
        const updatedFormData = {
          VisitorName: responseAppointment.data.visitorName,
          MailSubject: 'Visitor CheckOut Notification',
          MailType: 'CHKOUTVISITOR',
          MeetingOn: responseAppointment.data.checkInDateTime,
          VisitorMobile: responseAppointment.data.visitorMobile,
          MeetingVenue: responseAppointment.data.meetingvenue || '',
          txtManagersID: null,
          ManagerMail: getManagerDetails.data.managerMailID,
          SecsEmailId: null,
          VisitorCompany: responseAppointment.data.visitorCompany,
          HostName: responseAppointment.data.hostName,
          ToAddress: responseAppointment.data.hostEmail,
        }
        console.log("updatedFormData", updatedFormData);
        const updateMail = await axios.post('/Mail', updatedFormData);
        console.log("updateMail", updateMail);
        const sendSmsData = {
          AppointmentDtTm:responseAppointment.data.checkInDateTime,
          AppointmentVenue:responseAppointment.data.meetingvenue || '',
          ContactNo:"+91"+responseAppointment.data.hostMobile ,
          HostName:responseAppointment.data.hostName,
          SMSType:"MsgToHostCHKOUT",
          VisitorName:responseAppointment.data.visitorName
        }
        console.log("sendSmsData",sendSmsData);
        const updateSendSms = await axios.post('/SendSMS', sendSmsData);
        console.log("updateSendSms",updateSendSms);
        setMobileNumber('');
        const fetchCardCounts = async () => {
          try {
            const response = await axios.get('https://vmsdevapi.azurewebsites.net/api/Dashboard');
            setCardCounts({
              mobileCheckOutCount: response.data.groupChkout,
              qrcodeCheckOutCount: response.data.groupWQChkout,
            });
          } catch (error) {
            console.error('Error fetching card counts:', error);
          }
        };

        fetchCardCounts();
        setShowPopupSuc(true)
        

      }



    } catch (error) {
      console.error('Error fetching appointment details:', error);
      // Handle error scenarios
    }
  };

  const handleNextButtonQRClick = async () => {
    const decryptedNumber = decryptQRCode(qrumber);
    console.log("decryptedNumber", decryptedNumber);
    console.log("QrCode", qrumber);
    try {

      const responseAppointment = await axios.get(`/GroupCheckIn/GroupCheckIn/${decryptedNumber}`);
      console.log('Checkout Appointment API:', responseAppointment.data);
      if (responseAppointment.data.checkOutType === "WQ        ") {
        setQrNumber('');
        setShowPopup(true);
        return;
      }
      if (responseAppointment.data.checkOutType == null) {
        const responseVisitorDeatils = await axios.put(`/GroupCheckIn/QRCheckOut/${decryptedNumber}`);
        console.log("responseVisitorDeatils",responseVisitorDeatils.data);
        
        const AppId = responseAppointment.data.groupAppointmentId
        console.log("AppId", AppId);
        const Stat = "OUT"
        console.log("Stat", Stat);
        const updateAppoinmentStatus = await axios.post(`/GroupAppointment/AppointStatusUpd/${AppId},${Stat}`);
        console.log('update GroupAppointment API:', updateAppoinmentStatus);
        const empID = localStorage.getItem('employeeId');
        const getManagerDetails = await axios.get(`/Employee/${empID}`);
        const updatedFormData = {
          VisitorName: responseAppointment.data.visitorName,
          MailSubject: 'Visitor CheckOut Notification',
          MailType: 'CHKOUTVISITOR',
          MeetingOn: responseAppointment.data.checkInDateTime,
          VisitorMobile: responseAppointment.data.visitorMobile,
          MeetingVenue: responseAppointment.data.meetingvenue || '',
          txtManagersID: null,
          ManagerMail: getManagerDetails.data.managerMailID,
          SecsEmailId: null,
          VisitorCompany: responseAppointment.data.visitorCompany,
          HostName: responseAppointment.data.hostName,
          ToAddress: responseAppointment.data.hostEmail,
        }
        console.log("updatedFormData", updatedFormData);
        const updateMail = await axios.post('/Mail', updatedFormData);
        console.log("updateMail", updateMail);
        const sendSmsData = {
          AppointmentDtTm:responseAppointment.data.checkInDateTime,
          AppointmentVenue:responseAppointment.data.meetingvenue || '',
          ContactNo:"+91"+responseAppointment.data.hostMobile ,
          HostName:responseAppointment.data.hostName,
          SMSType:"MsgToHostCHKOUT",
          VisitorName:responseAppointment.data.visitorName
        }
        console.log("sendSmsData",sendSmsData);
        const updateSendSms = await axios.post('/SendSMS', sendSmsData);
        console.log("updateSendSms",updateSendSms);
        setQrNumber('');
        const fetchCardCounts = async () => {
          try {
            const response = await axios.get('https://vmsdevapi.azurewebsites.net/api/Dashboard');
            setCardCounts({
              mobileCheckOutCount: response.data.groupChkout,
              qrcodeCheckOutCount: response.data.groupWQChkout,
            });
          } catch (error) {
            console.error('Error fetching card counts:', error);
          }
        };

        fetchCardCounts();
        setShowPopupSuc(true)
        

      }


    } catch (error) {
      console.error('Error fetching appointment details:', error);
      // Handle error scenarios
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleClosePopupSuc = () => {
    setShowPopupSuc(false);
  };


  function decryptQRCode(qrCode) {
    let decryptedNumber = '';
    for (let i = 0; i < qrCode.length; i += 2) {
      const asciiValue = qrCode.substring(i, i + 2);
      decryptedNumber += String.fromCharCode(parseInt(asciiValue, 10));
    }

    // Use a regular expression to extract only the digits from the decrypted string
    const mobileNumber = decryptedNumber.match(/\d+/)[0];
    return mobileNumber;
  }


  return (
    <Container>
      <Heading>Group Check-Out Count</Heading>
      <CardWrapper>
        <Card bgColor="#A2BECB">
          <CardContent>
            <CardImage src={cardone} alt="Card Image" />
            <CardText>
              <CardTitle>Mobile Check-Out</CardTitle>
              <CardSubtitle>Today's Count</CardSubtitle>
            </CardText>
            <CardCount>{cardCounts.mobileCheckOutCount}</CardCount>
          </CardContent>
        </Card>
        <Card bgColor="#A2BECB">
          <CardContent>
            <CardImage src={cardtwo} alt="Card Image" />
            <CardText>
              <CardTitle>QR Code Check-Out</CardTitle>
              <CardSubtitle>Today's Count</CardSubtitle>
            </CardText>
            <CardCount>{cardCounts.qrcodeCheckOutCount}</CardCount>
          </CardContent>
        </Card>
      </CardWrapper>
      <SubHeading>Check-Out</SubHeading>
      <RadioGroup>
        <RadioLabel>
          <RadioInput
            type="radio"
            value="mobile"
            checked={selectedOption === 'mobile'}
            onChange={handleOptionChange}
          />
          Using Mobile Number
        </RadioLabel>
        <RadioLabel>
          <RadioInput
            type="radio"
            value="qr"
            checked={selectedOption === 'qr'}
            onChange={handleOptionChange}
          />
          Using QR Code
        </RadioLabel>
      </RadioGroup>
      <InputCard>
        {selectedOption === 'mobile' && (
          <>
            <InputField
              type="text"
              placeholder="Enter 10 Digit Mobile Number"
              maxLength="10"
              value={mobileNumber}
              onChange={handleInputChange}
            />
            <Button onClick={handleNextButtonClick}>Next</Button>
          </>
        )}
        {selectedOption === 'qr' && (
          <>
            <InputField
              type="number"
              placeholder="Enter QR Code"
              maxLength="20"
              value={qrumber}
              onChange={handleInputQrChange}
            />
            <Button onClick={handleNextButtonQRClick}>Next</Button>
          </>
        )}
      </InputCard>
      {showPopup && (
        <Popup>
          <PopupText>Checkout already done for this Visitor</PopupText>
          <PopupButton onClick={handleClosePopup}>OK</PopupButton>
        </Popup>
      )}
      {showPopupSuc && (
        <Popup>
          <PopupText> Visitor Left</PopupText>
          <PopupButton onClick={handleClosePopupSuc}>OK</PopupButton>
        </Popup>
      )}

    </Container>
  );
};

export default GroupCheckoutPage;
