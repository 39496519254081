import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGroup, FaChevronDown, FaChevronUp, FaSignOutAlt } from 'react-icons/fa';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // Import Axios or use the configured instance
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';// Import the CSS for react-toastify
import { faLifeRing, faChevronDown, faChevronUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

library.add(fas);


const SidebarContainer = styled.div`
  width: 225px;
  height: 91vh;
  background-color: rgb(24, 62, 159);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 66px;
  position: fixed;
  border-right: 3px solid #FFFFFF;
  box-shadow: 0px 71px 2px #293495;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 4px;
    &:hover {
      background-color: #888;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #293495;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 0;
  }
`;

const MenuItem = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  margin: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.active {
    background-color: rgb(5, 75, 214);
    color: #FFF;
  }

  &:hover {
    background-color: rgb(5, 75, 214);
    color: #FFF;
  }

  div {
    display: flex;
    align-items: center;
    flex-grow: 1;
    svg {
      margin-right: 10px;
    }
  }

  svg:last-child {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 12px;
  }
`;

const SubMenuItem = styled.div`
  // width: calc(100% - 40px);
  height: 50px;
  padding: 0 15px;
  margin: 4px 0 4px 7px;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.active {
    background-color: rgb(5, 75, 214);
    color: #FFF;
  }

  &:hover {
    background-color: rgb(5, 75, 214);
    color: #FFF;
  }

  div {
    display: flex;
    align-items: center;
    flex-grow: 1;
    svg {
      margin-right: 8px;
    }
  }

  svg:last-child {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 20px;
    padding: 0 8px;
  }
`;

const LogoutItem = styled.div`
  width: 100%;
  height: 50px;
  padding: 21px;
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: rgb(5, 75, 214);
    color: #FFF;
  }

  svg {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 12px;
  }
`;
const LeftText = styled.div`

  color: #fff;
    font-family: Lucid sans-serif;
    font-size: 33px;
    margin-bottom: 18px;
    position: relative;
    left: 29px;
`;

const SideNavbar = ({ openModal, openSupportModal,openAboutModal, selectedRole }) => {
  const [menuMappings, setMenuMappings] = React.useState([]);
  const [expandedMenu, setExpandedMenu] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();


  React.useEffect(() => {
    const fetchMenuMappings = async () => {
      try {
        const roleId = localStorage.getItem('selectedRoleId');
        const empId = localStorage.getItem('employeeId');
        console.log("roleId before defaulting:", roleId);

        // Use a default roleId if it's null
        // Use a default roleId if it's null, undefined, or empty
        if (!roleId || roleId.trim() === "") {
          console.log("roleId after defaulting:", roleId);
          const response = await axios.get(`/RoleManagement/GetRoleDetails?EmpId=${empId}&RoleId=${1}`);
          setMenuMappings(response.data);// Ensure roleId is a string
        }

        // Log roleId after defaulting
        console.log("roleId after defaulting:", roleId);
        const response = await axios.get(`/RoleManagement/GetRoleDetails?EmpId=${empId}&RoleId=${roleId}`);
        setMenuMappings(response.data);
      } catch (error) {
        console.error('Error fetching menu mappings:', error);
      }
    };

    fetchMenuMappings();
  }, [selectedRole]);

  const toggleMenu = (parentMenu) => {
    setExpandedMenu(prev => (prev === parentMenu ? null : parentMenu));
  };
  // List of valid URLs based on the App.js routes
  const validUrls = [
    "/login",
    "Dashboard/Dashboard",
    "BookAppointment/BookAppointment",
    "LinkToVisitor/LinkToVisitor",
    "CheckIn/MenuItem",
    "check-visitoriForm",
    "Reports/Report",
    "AppointmentReport/AppointmentReport",
    "groupAppointmentReport/groupAppointmentReport",
    "Reports/groupAppointmentReport",
    "blacklist-reports",
    "Reports/FrequentVisitorReport",
    "Reports/MonthWiseVisitorReport",
    "Reports/DayWiseVisitorReport",
    "monthwise-feedback",
    "datewise-feedback",
    "BlackList/BlackList",
    "AddLocation/AddLocation",
    "AddIdentityProof/AddIdentityProof",
    "AddEmployee/AddEmployee",
    "configuration",
    "EmployeeRoleMapping/EmployeeRoleMapping",
    "RoleMenuMap/RoleMenuMap",
    "VisitorType/VisitorType",
    "Checkout/MenuItem",
    "food-approval",
    "Reprint/ReprintVisitor",
    "report-bug",
    "group-appoinment-details",
    "GroupAppointment/GroupAppointment",
    "AppointmentStatus/AppointmentStatus",
    "GroupCheckIn/MenuItem",
    "GroupCheckIn/GroupCheckout",
    "group-CheckIn-form",
    "group-member-page"
  ];

  const handleNavigation = (url,menuName) => {
    console.log("menuName",menuName);
    
    if (menuName === 'About') {
      openAboutModal(); // Open the modal when "About" is clicked
      return;
    }
    if (url === "Help/ReportaBug") {
      openModal();
      return;
    }
    if (url === "Help/Help") {
      openSupportModal();
      return;
    }

    const isValid = validUrls.includes(url);

    if (isValid) {
      navigate(`/layout/${url}`);
    } else {
      toast.warn("This menu is under development");
    }

  };

  const isActive = (url) => location.pathname === `/layout/${url}`;

  const renderMenuItems = () => {
    const groupedMenus = menuMappings.reduce((acc, menu) => {
      const parent = menu.txtParentMenu || menu.txtMenu;
      if (!acc[parent]) acc[parent] = [];
      acc[parent].push(menu);
      return acc;
    }, {});

    return Object.keys(groupedMenus).map(parentMenu => {
      const subMenus = groupedMenus[parentMenu];
      const hasSubmenu = subMenus.length > 1;
      const isParentActive = subMenus.some(subMenu => isActive(subMenu.txtMenuUrl));

      return (
        <div key={parentMenu}>
          <MenuItem
            onClick={() => hasSubmenu ? toggleMenu(parentMenu) : handleNavigation(subMenus[0].txtMenuUrl,parentMenu)}
            className={isParentActive || expandedMenu === parentMenu ? 'active' : ''}
          >
            <div>
              {parentMenu === "Help" ? (
                <FontAwesomeIcon icon="question-circle" /> // Hardcoded icon for Help
              ) : (
                <FontAwesomeIcon icon={subMenus[0].txtIcon || 'question-circle'} /> // Dynamic icon for others
              )}
              {parentMenu}
            </div>
            {hasSubmenu && (expandedMenu === parentMenu ? <FaChevronUp /> : <FaChevronDown />)}
          </MenuItem>

          {expandedMenu === parentMenu && hasSubmenu && subMenus.map(subMenu => (
            <SubMenuItem
              key={subMenu.txtMenuUrl}
              onClick={() => handleNavigation(subMenu.txtMenuUrl,subMenu.txtMenu)}
              className={isActive(subMenu.txtMenuUrl) ? 'active' : ''}
            >
              <div>
                {subMenu.txtMenu === 'About' ? (
                  <FontAwesomeIcon icon="question-circle" />
                ) : subMenu.txtMenu === 'Group Appointment Reports' || subMenu.txtMenu === 'Group Reports' ? (
                  <FontAwesomeIcon icon="users" />
                ) : subMenu.txtMenu === 'Appointment Reports' ? (
                  <FontAwesomeIcon icon="calendar-check" />
                )
                : subMenu.txtMenu === 'Daywise Visitors' ? (
                  <FontAwesomeIcon icon="calendar-day" />
                )
                  :
                  (
                    <FontAwesomeIcon icon={subMenu.txtIcon || 'question-circle'} />
                  )}
                {subMenu.txtMenu}
              </div>
            </SubMenuItem>
          ))}
        </div>
      );
    });
  };

  const handleLogout = () => {
    console.log('Logged out');
    navigate('/login');
  };

  return (
    <SidebarContainer>
      {renderMenuItems()}

      <LogoutItem onClick={handleLogout}>
        <FaSignOutAlt />
        Logout
      </LogoutItem>
      <LeftText>SecoreX</LeftText>

    </SidebarContainer>
  );
};

export default SideNavbar;
