import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown } from 'react-icons/fi';
import axios from 'axios';
import { FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';


// Styled components
const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1;
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

// Pagination Styled Components
const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;
const ExportIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: #28a745; /* Green color for Excel icon */
`;


const FrequentVisitorPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [visitorData, setVisitorData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const rowsPerPage = 7;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchVisitorTypeData();
  }, []);

  useEffect(() => {
    setFilteredData(
      visitorData.filter((visitor) =>
        visitor.visitorName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, visitorData]);

  const fetchVisitorTypeData = async () => {
    try {
      const response = await axios.get('/VisitorDetails/GetVisitorDetails');
      setVisitorData(response.data);
    } catch (error) {
      console.error('Error fetching Visitor Report data:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData.map(row => ({
      'Visitor Name': row.visitorName,
      'Visitor Mobile': row.visitorMobile,
      'Visitor Company': row.visitorCompany,
      'Host Name': row.host,
      'Location Name': row.meetingVenue,
    })));
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Visitor Reports');
  
    XLSX.writeFile(wb, 'VisitorReports.xlsx');
  };
  
  
  return (
    <Container>
      <Header>
        <Heading>Frequent Visitor Report</Heading>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchBox>
        <ExportIconContainer onClick={exportToExcel} title="Export to Excel">
            <FaFileExcel size={20} />
          </ExportIconContainer>
</div>
      </Header>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Visitor Name</TableHeaderCell>
              <TableHeaderCell>Visitor Mobile</TableHeaderCell>
              <TableHeaderCell>Visitor Company</TableHeaderCell>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Host Name</TableHeaderCell>
              <TableHeaderCell>Count</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentRows.map((visitor, index) => (
              <TableRow key={index}>
                <TableCell>{visitor.visitorName}</TableCell>
                <TableCell>{visitor.visitorMobile}</TableCell>
                <TableCell>{visitor.visitorCompany}</TableCell>
                <TableCell>{visitor.locationName}</TableCell>
                <TableCell>{visitor.hostName}</TableCell>
                <TableCell>{visitor.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
    </Container>
  );
};

export default FrequentVisitorPage;
