import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown } from 'react-icons/fi'; // Using react-icons library
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';


const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  box-shadow: 0px 5px 10px #00000038;
  background:#F5F5F5;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
`;

const Label = styled.label`
  color: #3D46B4;
  margin-bottom: 5px;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3D46B4;
  padding: 10px;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  position: relative;
  background:transparent;
`;

const DropdownText = styled.span`
  color: #293495;
  font-size: 16px;
`;

const DropdownIcon = styled(FiChevronDown)`
  color: #3D46B4;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: calc(100% + 5px); /* Positioning panel just below the dropdown */
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #3D46B4;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  &:first-child {
    background-color: #FF4B4B;
    box-shadow: 0px 10px 20px #7E18182E;
  }
  &:last-child {
    background-color: #2B3497;
    box-shadow: 0px 10px 20px #2B349752;
  }
`;


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;



const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;
const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  color: #2b3497;
  margin: 0 5px;
`;
const StyledDropdown = styled.select`
  border: none;
  border-bottom: 2px solid #293495;
  padding: 5px;
  font-size: 16px;
  background: #fff;
  outline: none; /* Remove default focus outline */
`;
const PassReturnInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
`;
const CustomInput = styled.input`
  width: 200px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;
const EmployeeNameInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
`;

const EmployeeNameInput = styled.input`
  width: 200px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;

const LocationInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
`;

const LocationInput = styled.input`
  width: 200px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }
`;
const CompanyInputContainer = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
margin-left: 20px;
`;

const CompanyInput = styled.input`
width: 200px;
padding: 8px 0;
border: none;
border-bottom: 1px solid #2B3497;
font-family: 'Segoe UI', Regular;
background: transparent;
&:focus {
  outline: none;
  border-bottom: 2px solid #2B3497;
}
`;
const StyledDatePicker = styled(DatePicker)`
  width: 200px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2B3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2B3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2B3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2B3497;
    }
  }`

  const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

`;
const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-left: 20px;

`;
const ExportIconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: #28a745; /* Green color for Excel icon */
`;


const AppoinmentReportPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const dropdownRef = useRef(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [passReturn, setPassReturn] = useState('');
  const [visitorReports, setVisitorReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0); // Define totalItems in the state

  const itemsPerPage = 10; // Number of items per page
  const [tableData, setTableData] = useState([]); // Initialize without dummy data
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;
  const [locations, setLocations] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [formValues, setFormValues] = useState({
    employeeName: '',
    locationName: ''

  }

  );


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const locationsResponse = await axios.get('/Location');
        setLocations(locationsResponse.data);

        const employeesResponse = await axios.get('/employee');
        setEmployees(employeesResponse.data);

      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchVisitorTypeData = async () => {
    try {
      const response = await axios.get('/Appointment/GetAppointmentReports');
      setTableData(response.data);
    } catch (error) {
      console.error('Error fetching Visitor Report data:', error);
    }
  };

  useEffect(() => {
    fetchVisitorTypeData();
  }, []);

  const handleSearchData = () => {
    let filtered = [...tableData]; // Start with all data

    switch (selectedOption) {
      case 'Date Wise':
        if (fromDate && toDate) {
          // Normalize fromDate and toDate to remove time
          const from = new Date(fromDate);
          from.setHours(0, 0, 0, 0);
      
          const to = new Date(toDate);
          to.setHours(23, 59, 59, 999); // Set the end of the day
      
          filtered = filtered.filter(row => {
            // Normalize the checkInDate to remove time
            const checkInDate = new Date(row.meetingOn);
            checkInDate.setHours(0, 0, 0, 0);
            return checkInDate >= from && checkInDate <= to;
          });
        }
        break;
        
      case 'Company Wise':
        if (companyName) {
          filtered = filtered.filter(row => 
            row.visitorCompany && row.visitorCompany.toLowerCase().includes(companyName.toLowerCase())
          );
        }
        break;
        case 'Employee Name Wise':
          if (formValues.employeeName) {
            filtered = filtered.filter(row => 
              row.employeeName && row.employeeName.toLowerCase().includes(formValues.employeeName.toLowerCase())
            );
          }
          break;
      default:
        break;
    }

    setTableData(filtered);
  };

  const filteredData = tableData.filter((row) =>
    row.visitorName && row.visitorName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const formatDateTime = (dateTime) => {
    return dateTime.replace('T', ' ');
  };
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData.map(row => ({
      'Visitor Name': row.visitorName,
      'Visitor Mobile': row.visitorMobile,
      'Visitor Company': row.visitorCompany,
      'Host Name': row.host,
      'Location Name': row.meetingVenue,
      'Appointment Date': formatDateTime(row.appointmentDate)
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Visitor Reports');

    XLSX.writeFile(wb, 'VisitorReports.xlsx');
  };



  return (
    <Container>
      <Header>
        <Heading>Appointment Report</Heading>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
        <ExportIconContainer onClick={exportToExcel} title="Export to Excel">
            <FaFileExcel size={20} />
          </ExportIconContainer>
</div>

      </Header>
      <Card>
        <DropdownContainer ref={dropdownRef}>
          <Label>Report Type</Label>
          <Dropdown onClick={toggleDropdown}>
            <DropdownText>{selectedOption || 'Date Wise'}</DropdownText>
            <DropdownIcon size={20} />
          </Dropdown>
          {isOpen && (
            <DropdownPanel>
              {['Date Wise', 'Company Wise', 'Employee Name Wise'].map((option) => (
                <DropdownOption key={option} onClick={() => handleOptionClick(option)}>
                  {option}
                </DropdownOption>
              ))}
            </DropdownPanel>
          )}
        </DropdownContainer>
        {selectedOption === 'Date Wise' && (
          <DateContainer>
            <DateInputContainer>
              <Label>From Date</Label>
              <StyledDatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                dateFormat="dd-MM-yyyy"
              />
            </DateInputContainer>
            <DateInputContainer>
              <Label>To Date</Label>
              <StyledDatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                dateFormat="dd-MM-yyyy"
              />
            </DateInputContainer>
          </DateContainer>
        )}
        {selectedOption === 'Company Wise' && (
          <CompanyInputContainer>
            <Label>Company Name</Label>
            <CompanyInput
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Enter company name"
            />
          </CompanyInputContainer>
        )}
        {selectedOption === 'Employee Name Wise' && (
          <EmployeeNameInputContainer>
            <Label>Employee Name</Label>
            <StyledDropdown
              name="employeeName"
              value={formValues.employeeName}
              onChange={handleInputChange}
            >
              <option value="">Select Employee Name</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.employeeName}>
                  {employee.employeeName}
                </option>
              ))}
            </StyledDropdown>
          </EmployeeNameInputContainer>
        )}
        <ButtonGroup>

          <Button onClick={() => {
            setFromDate(null);
            setToDate(null);
            setCompanyName('');
            setFormValues({
              employeeName: '',
              locationName: ''
            });
            setPassReturn('');
            setSelectedOption('');
            setTableData([]);
            fetchVisitorTypeData();
            setCurrentPage(1); // Reset pagination to the first page
          }}>Reset</Button>
          <Button onClick={handleSearchData}>Search</Button>
        </ButtonGroup>
      </Card>
      {filteredData.length === 0 && (
        <ZeroStateMessage>No data found.</ZeroStateMessage>
      )}
      {filteredData.length > 0 && (
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Visitor Name</TableHeader>
                <TableHeader>Visitor Mobile</TableHeader>
                <TableHeader>Visitor Company</TableHeader>
                <TableHeader>Employee Name</TableHeader>
                <TableHeader>Location Name</TableHeader>
                <TableHeader>Appointment Date</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.visitorName}</TableCell>
                  <TableCell>{row.visitorMobile}</TableCell>
                  <TableCell>{row.visitorCompany}</TableCell>
                  <TableCell>{row.employeeName}</TableCell>
                  <TableCell>{row.meetingVenue}</TableCell>
                  <TableCell>{formatDateTime(row.meetingOn)}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>

        </TableContainer>
      )}
    </Container>
  );
};
export default AppoinmentReportPage;
