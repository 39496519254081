import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiEdit, FiTrash2 } from 'react-icons/fi';
import axios from 'axios';

const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;

const AppoinmentStatusPage = () => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const fetchVisitorTypeData = async () => {
    try {
      // const response = await axios.get('/GroupAppointment/GetGroupAppointmentReports');
      const empID = localStorage.getItem('employeeId');
      const responseOne = await axios.get(`/GroupAppointment/AppUser/${empID}`);
      console.log("responseOne",responseOne);
      setTableData(responseOne.data);

      // setTableData(response.data);
    } catch (error) {
      console.error('Error fetching Visitor Report data:', error);
    }
  };

  useEffect(() => {
    fetchVisitorTypeData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = tableData.filter((item) => {
    const groupLeaderName = item.groupLeaderName || '';
    return groupLeaderName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };

  return (
    <Container>
      <Header>
        <Heading>Appointment Status</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Group Leader Name</TableHeaderCell>
              <TableHeaderCell>Visitor Company</TableHeaderCell>
              <TableHeaderCell>Visitor Email</TableHeaderCell>
              <TableHeaderCell>Visitor Mobile</TableHeaderCell>
              <TableHeaderCell>Appointment Date</TableHeaderCell>
              <TableHeaderCell>Appointment Status</TableHeaderCell>
              <TableHeaderCell>Valid Up to</TableHeaderCell>
              {/* <TableHeaderCell>Action</TableHeaderCell> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentRows.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.groupLeaderName}</TableCell>
                <TableCell>{item.visitorCompany}</TableCell>
                <TableCell>{item.visitorEmail}</TableCell>
                <TableCell>{item.visitorMobile}</TableCell>
                <TableCell>{item.appointmentDate}</TableCell>
                <TableCell>{item.appointmentStatus}</TableCell>
                <TableCell>{item.validTill}</TableCell>
                {/* <TableCell>
                  <IconContainer>
                    <FiEdit size={20} style={{ cursor: 'pointer', color: '#293495' }} />
                    <FiTrash2 size={20} style={{ cursor: 'pointer', color: '#293495' }} />
                  </IconContainer>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
        <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
          &laquo; Prev
        </PaginationButton>
        {renderPageNumbers()}
        <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next &raquo;
        </PaginationButton>
      </PaginationContainer>
    </Container>
  );
};

export default AppoinmentStatusPage;
