import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Navigate, Outlet } from 'react-router-dom';
import SideNavbar from './SideNavBar';
import TopNavBar from './TopNavBar';
import ReportBugModal from './ReportBugModal'; // Import the modal component
import AboutModal from './AboutModal'; // Import the modal component
import SupportModal from './SupportModal'; // Import the modal component

const LayoutContainer = styled.div`
  display:flex;
  position: relative;
`;

const MainContent = styled.div`
  margin-left: 170px;
  padding: 36px;
  width: calc(100% - 200px);
  margin-top: 22px;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    margin-top: 70px; /* Adjusted to ensure it doesn't overlap with the responsive top navbar */
  }
`;

const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAboutOpen, setIsModalAboutOpen] = useState(false);
  const [isModalSupportOpen, setIsModalSupportOpen] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [selectedRole, setSelectedRole] = useState(''); // State for selected role

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openAboutModal = () => {
    setIsModalAboutOpen(true);
  };

  const closeAboutModal = () => {
    setIsModalAboutOpen(false);
  };

  const openSupportModal = () => {
    setIsModalSupportOpen(true);
  };

  const closeSupportModal = () => {
    setIsModalSupportOpen(false);
  };

  useEffect(() => {
    const auth = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(auth === 'true');
  }, []);

  if (isAuthenticated === null) {
    // While authentication status is being determined, you can show a loading state or return null
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutContainer>
      <TopNavBar setSelectedRole={setSelectedRole} /> {/* Pass setSelectedRole to TopNavBar */}
      <SideNavbar
        openModal={openModal}
        openAboutModal={openAboutModal}
        openSupportModal={openSupportModal}
        selectedRole={selectedRole} // Pass selectedRole to SideNavbar
      />
      <MainContent>
        <Outlet />
      </MainContent>

      {/* Modals */}
      {isModalOpen && <ReportBugModal isOpen={isModalOpen} onClose={closeModal} />}
      {isModalAboutOpen && <AboutModal isOpen={isModalAboutOpen} onClose={closeAboutModal} />}
      {isModalSupportOpen && <SupportModal isOpen={isModalSupportOpen} onClose={closeSupportModal} />}
    </LayoutContainer>
  );
};

export default Layout;
