import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiChevronDown } from 'react-icons/fi'; // Using react-icons library

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 3px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  box-shadow: 0px 5px 10px #00000038;
  background: #F5F5F5;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
`;

const DropdownGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Label = styled.label`
  color: #3D46B4;
  margin-bottom: 5px;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3D46B4;
  padding: 10px;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  position: relative;
  background: transparent;
`;

const DropdownText = styled.span`
  color: #293495;
  font-size: 16px;
`;

const DropdownIcon = styled(FiChevronDown)`
  color: #3D46B4;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: calc(100% + 5px); /* Positioning panel just below the dropdown */
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #3D46B4;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  &:first-child {
    background-color: #FF4B4B;
    box-shadow: 0px 10px 20px #7E18182E;
  }
  &:last-child {
    background-color: #2B3497;
    box-shadow: 0px 10px 20px #2B349752;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

const MonthwiseFeedbackPage = () => {
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const monthDropdownRef = useRef(null);
  const yearDropdownRef = useRef(null);

  const toggleMonthDropdown = () => {
    setIsMonthOpen(!isMonthOpen);
  };

  const toggleYearDropdown = () => {
    setIsYearOpen(!isYearOpen);
  };

  const handleMonthOptionClick = (option) => {
    setSelectedMonth(option);
    setIsMonthOpen(false);
  };

  const handleYearOptionClick = (option) => {
    setSelectedYear(option);
    setIsYearOpen(false);
  };

  const handleClickOutside = (event) => {
    if (monthDropdownRef.current && !monthDropdownRef.current.contains(event.target)) {
      setIsMonthOpen(false);
    }
    if (yearDropdownRef.current && !yearDropdownRef.current.contains(event.target)) {
      setIsYearOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <Header>
        <Heading>Month Wise Feedback Report</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput type="text" placeholder="Search..." />
        </SearchBox>
      </Header>
      <Card>
        <DropdownGroup>
          <DropdownContainer ref={monthDropdownRef}>
            <Label>Select Month</Label>
            <Dropdown onClick={toggleMonthDropdown}>
              <DropdownText>{selectedMonth || 'Select Month'}</DropdownText>
              <DropdownIcon size={20} />
            </Dropdown>
            {isMonthOpen && (
              <DropdownPanel>
                {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((option) => (
                  <DropdownOption key={option} onClick={() => handleMonthOptionClick(option)}>
                    {option}
                  </DropdownOption>
                ))}
              </DropdownPanel>
            )}
          </DropdownContainer>
          <DropdownContainer ref={yearDropdownRef}>
            <Label>Select Year</Label>
            <Dropdown onClick={toggleYearDropdown}>
              <DropdownText>{selectedYear || 'Select Year'}</DropdownText>
              <DropdownIcon size={20} />
            </Dropdown>
            {isYearOpen && (
              <DropdownPanel>
                {['2023', '2024', '2025', '2026'].map((option) => (
                  <DropdownOption key={option} onClick={() => handleYearOptionClick(option)}>
                    {option}
                  </DropdownOption>
                ))}
              </DropdownPanel>
            )}
          </DropdownContainer>
        </DropdownGroup>
        <ButtonGroup>
          <Button>Reset</Button>
          <Button>Search</Button>
        </ButtonGroup>
      </Card>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Device Name</TableHeaderCell>
              <TableHeaderCell>Employee No</TableHeaderCell>
              <TableHeaderCell>Option Selected</TableHeaderCell>
              <TableHeaderCell>Comments</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>John Doe</TableCell>
              <TableCell>123-456-7890</TableCell>
              <TableCell>Acme Corp</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Alice Johnson</TableCell>
              <TableCell>987-654-3210</TableCell>
              <TableCell>Global Inc</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default MonthwiseFeedbackPage;
