import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiTrash2, FiEdit, FiEye, FiX ,FiUpload,FiDownload} from 'react-icons/fi'; // Import necessary icons
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import for confirmation dialog
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the confirmation dialog CSS

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;


const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1; /* Ensure input takes up remaining space */
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000; /* Adjust color as needed */
`;

const Card = styled.div`
  background-color: #f5f5f5;
  box-shadow: 0px 5px 10px #00000038;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row; /* Align radio buttons horizontally */
  margin-bottom: 20px; /* Provide some spacing between radio group and other elements */
`;

const RadioLabel = styled.label`
  margin-right: 20px; /* Adjust spacing between radio button and label */
  display: flex;
  align-items: center;
  color: #293495; /* Text color */
  font-weight: 600;
`;

const RadioInput = styled.input`
  margin-right: 5px; /* Adjust spacing between radio buttons */
  color: #293495; /* Radio button color */
`;

const TableContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  color: #2b3497;
  margin: 0 5px;
`;


const PaginationContainer = styled.div`
  display: ${({ showPagination }) => (showPagination ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;
const ZeroStateMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #666;
  text-align: center;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 29px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1000;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: #293495;
`;

const CloseIcon = styled(FiX)`
  cursor: pointer;
  color: #293495;
`;

const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputField = styled.div`
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: 600;
  color: #293495;
  &::after {
    content: '*';
    color: red;
    margin-left: 5px;
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 2px solid #293495;
  padding: 5px;
  font-size: 16px;
  outline: none; /* Remove default focus outline */
`;

const Dropdown = styled.select`
  border: none;
  border-bottom: 2px solid #293495;
  padding: 5px;
  font-size: 16px;
  background: #fff;
  outline: none; /* Remove default focus outline */
`;

const DatePicker = styled.input.attrs({ type: 'date' })`
  border: none;
  border-bottom: 2px solid #293495;
  padding: 5px;
  font-size: 16px;
  outline: none; /* Remove default focus outline */
`;

const SubmitButton = styled.button`
  background-color: #293495;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  align-self: flex-end;
  margin-top: 20px;
  font-size: 16px;
`;

const AddEmployeePage = () => {
  const [tableData, setTableData] = useState([]); // Initialize without dummy data
  const [searchQuery, setSearchQuery] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(''); // Track selected radio button
  const [formValues, setFormValues] = useState({
    employeeNo: '',
    employeeName: '',
    employeeMailId: '',
    employeeMobile: '',
    employeeLocation: '',
    employeeDepartment: '',
    employeeDesignation: '',
    buHeadMailId: '',
    employeeDOJ: '',
    managerMailID: '',
    buHeadId: '',
    employeeReportingTo: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [locations, setLocations] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [managerMails, setManagerMail] = useState([]);

  const rowsPerPage = 7;

  // Fetch employee data from API
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('/employee');
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  // Fetch locations and designations data
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const locationsResponse = await axios.get('/Location');
        setLocations(locationsResponse.data);

        const designationsResponse = await axios.get('/Designation');
        setDesignations(designationsResponse.data);

        const departmentsResponse = await axios.get('/Department');
        setDepartments(departmentsResponse.data);

        const managerMailResponse = await axios.get('/employee');
        setManagerMail(managerMailResponse.data);


      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDelete = (employeeNo) => {
    // Show confirmation dialog
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this row?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              // Construct the request body with necessary fields
              const existingEmployee = tableData.find((employee) => employee.employeeNo === employeeNo);
  
              const requestBody = {
                ...existingEmployee,
                isDeleted: true, // Mark the employee as deleted
                modifiedOn: new Date().toISOString(), // Current timestamp
                modifiedBy: '', // Replace with actual user performing the delete
              };
  
              // Call the API to delete the row
              const response = await axios.put(`/employee`, requestBody);
              
              if (response.status === 200) {
                // Remove the row from the tableData
                setTableData((prevData) => prevData.filter((row) => row.employeeNo !== employeeNo));
                
                // Show success toast message
                toast.success('Deleted successfully');
              } else {
                // Handle any errors from the API
                toast.error('Failed to delete the item');
              }
            } catch (error) {
              console.error('Error deleting data:', error);
              toast.error('There was an error deleting the item');
            }
          }
        },
        {
          label: 'No',
          onClick: () => {
            // Show cancel message
            toast.info('Delete cancelled');
          }
        }
      ]
    });
  };
    const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormValues('');
    setSelectedRadio(''); // Reset selected radio button
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the input is for mobile number and contains only digits
    if (name === "employeeMobile") {
      if (/^\d*$/.test(value)) { // Regex to allow only digits
        setFormValues({
          ...formValues,
          [name]: value
        });
      }
    } else {
      // For other fields, update normally
      setFormValues({
        ...formValues,
        [name]: value
      });
    }
  };
  
  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/; // Adjust according to your mobile number format

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        errors[key] = 'This field is required';
      }
    });
    if (formValues.employeeMailId && !emailRegex.test(formValues.employeeMailId)) {
      errors.employeeMailId = 'Invalid email format';
    }

    if (formValues.employeeMobile && !mobileRegex.test(formValues.employeeMobile)) {
      errors.employeeMobile = 'Invalid mobile number format';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post('/employee', formValues);
        console.log('Employee added successfully:', response.data);
  
        toast.success('Employee Added Successfully');
        setTableData((prevData) => [...prevData, formValues]);
        setShowPagination(true);
  
        handleCloseModal(); // Use the function to reset the form and close the modal
      } catch (error) {
        console.error('Error adding employee:', error);
        toast.error('Failed to add employee');
      }
    } else {
      toast.error('Please fill out all required fields');
    }
  };
    const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // const filteredData = tableData.filter((row) =>
  //   row.employeeName.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const filteredData = tableData.filter((row) =>
    row.employeeName && row.employeeName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentRows = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Handle the file upload logic here
    console.log("Selected file:", file);
  };
  
  const handleDownloadTemplate = () => {
    // Logic to handle downloading the sample template
    const link = document.createElement('a');
    link.href = '/path/to/sample_template.xlsx'; // Replace with the actual path to your sample template
    link.download = 'Employee_Sample_Template.xlsx';
    link.click();
  };
  
  
  return (
    <Container>
      <Header>
        <Heading>Add Employee</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
      </Header>
      <Card>
        <RadioGroup>
          <RadioLabel>
            <RadioInput
              type="radio"
              name="visitorType"
              value="addHost"
              checked={selectedRadio === 'addHost'}
              onChange={() => {
                setSelectedRadio('addHost');
                setIsModalOpen(true);
              }}
            />
            Add Employee
          </RadioLabel>
          <RadioLabel>
            <RadioInput
              type="radio"
              name="visitorType"
              value="anotherOption"
              checked={selectedRadio === 'anotherOption'}
              onChange={() => setSelectedRadio('anotherOption')}
            />
            Bulk Upload
          </RadioLabel>
        </RadioGroup>
        {selectedRadio === 'anotherOption' && (

        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <label htmlFor="upload-file" style={{ cursor: 'pointer', marginRight: '15px' }}>
          <FiUpload style={{ marginRight: '5px' }} />
          <span>Choose File</span>
        </label>
        <input
          type="file"
          id="upload-file"
          accept=".csv, .xlsx"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />

        <button onClick={handleDownloadTemplate} style={{ marginLeft: '15px' }}>
          <FiDownload style={{ marginRight: '5px' }} />
          Download Sample Template
        </button>
      </div>
    
  )}


      </Card>
      {filteredData.length === 0 && (
        <ZeroStateMessage>No data found.</ZeroStateMessage>
      )}
      {filteredData.length > 0 && (
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Employee No</TableHeader>

                <TableHeader>Employee Name</TableHeader>
                <TableHeader>Mail Id</TableHeader>
                <TableHeader>Mobile</TableHeader>
                <TableHeader>Manager Mail Id</TableHeader>
                <TableHeader>Actions</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.employeeNo}</TableCell>

                  <TableCell>{row.employeeName}</TableCell>
                  <TableCell>{row.employeeMailId}</TableCell>
                  <TableCell>{row.employeeMobile}</TableCell>
                  <TableCell>{row.managerMailID}</TableCell>


                  <TableCell>
                    <IconWrapper><FiEye /></IconWrapper>
                    <IconWrapper><FiEdit /></IconWrapper>
                    <IconWrapper onClick={() => handleDelete(row.employeeNo)}><FiTrash2 /></IconWrapper>
                    </TableCell>
                </TableRow>
              ))}
            </tbody>
          </StyledTable>
          <PaginationContainer showPagination={filteredData.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
        </TableContainer>
      )}
      {isModalOpen && (
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Add Employee</ModalTitle>
            <CloseIcon size={24} onClick={handleCloseModal} />
          </ModalHeader>
          <InputGroup>
            <InputField>
              <Label>Employee No</Label>
              <Input
                type="text"
                name="employeeNo"
                value={formValues.employeeNo}
                onChange={handleInputChange}
                placeholder="Enter Employee No"
                style={{ fontSize:"12px",borderColor: formErrors.employeeNo ? 'red' : '#2B3497' }}
              />
              {formErrors.employeeNo && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeNo && <span>{formErrors.employeeNo}</span>}
                </span>
              )}


            </InputField>
            <InputField>
              <Label>Employee Name</Label>
              <Input
                type="text"
                name="employeeName"
                value={formValues.employeeName}
                onChange={handleInputChange}
                placeholder="Enter Employee Name"
                
                style={{fontSize:"12px", borderColor: formErrors.employeeName ? 'red' : '#2B3497' }}
              />
              {formErrors.employeeName && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeName && <span>{formErrors.employeeName}</span>}
                </span>
              )}
            </InputField>
            <InputField>
              <Label>Employee Email</Label>
              <Input
                type="email"
                name="employeeMailId"
                value={formValues.employeeMailId}
                onChange={handleInputChange}
                placeholder="Enter Employee Email"
                style={{ fontSize:"12px",borderColor: formErrors.employeeMailId ? 'red' : '#2B3497' }}
              />
              {formErrors.employeeMailId && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeMailId && <span>{formErrors.employeeMailId}</span>}
                </span>
              )}
            </InputField>
            <InputField>
              <Label>Mobile Number</Label>
              <Input
                type="text"
                name="employeeMobile"
                maxLength="10"
                value={formValues.employeeMobile}
                onChange={handleInputChange}
                // onKeyPress={(e) => {
                //   if (!/[0-9]/.test(e.key)) {
                //     e.preventDefault(); // Prevents non-numeric characters
                //   }
                // }}            
                placeholder="Enter 10 Digit Mobile Number"
                style={{ fontSize:"12px",borderColor: formErrors.employeeMobile ? 'red' : '#2B3497' }}
              />
              {formErrors.employeeMobile && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeMobile && <span>{formErrors.employeeMobile}</span>}
                </span>
              )}
            </InputField>
            <InputField>
              <Label>Employee Location</Label>
              <Dropdown
                name="employeeLocation"
                value={formValues.employeeLocation}
                onChange={handleInputChange}
                style={{ fontSize:"12px",borderColor: formErrors.employeeLocation ? 'red' : '#2B3497' }}

              >
                <option value="">Select Location</option>
                {locations.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.locationName}
                  </option>
                ))}
              </Dropdown>
              <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeLocation && <span>{formErrors.employeeLocation}</span>}
              </span>
            </InputField>
            <InputField>
              <Label>Employee Department</Label>
              <Dropdown
                name="employeeDepartment"
                value={formValues.employeeDepartment}
                onChange={handleInputChange}
                style={{ fontSize:"12px",borderColor: formErrors.employeeDepartment ? 'red' : '#2B3497' }}

              >
                <option value="">Select Department</option>
                {departments.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.deptDescription}
                  </option>
                ))}
              </Dropdown>
              <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeDepartment && <span>{formErrors.employeeDepartment}</span>}
              </span>
            </InputField>
            <InputField>
              <Label>Employee Designation</Label>
              <Dropdown
                name="employeeDesignation"
                value={formValues.employeeDesignation}
                onChange={handleInputChange}
                style={{ fontSize:"12px",borderColor: formErrors.employeeDesignation ? 'red' : '#2B3497' }}
              >
                <option value="">Select Designation</option>
                {designations.map((designation) => (
                  <option key={designation.id} value={designation.id}>
                    {designation.designationName}
                  </option>
                ))}
              </Dropdown>
              <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeDesignation && <span>{formErrors.employeeDesignation}</span>}
              </span>
            </InputField>
            <InputField>
              <Label>Reporting To</Label>
              <Dropdown
                name="managerMailID"
                value={formValues.managerMailID}
                onChange={(e) => {
                  const selectedManager = managerMails.find((managerMail) => managerMail.employeeMailId === e.target.value);
                  setFormValues({
                    ...formValues,
                    managerMailID: e.target.value,
                    employeeReportingTo: selectedManager ? selectedManager.id : '' // Set employeeReportingTo
                  });
                }}
                              style={{ fontSize:"12px",borderColor: formErrors.managerMailID ? 'red' : '#2B3497' }}
              >
                <option value="">Select Manager MailID</option>
                {managerMails.map((managerMail) => (
                  <option key={managerMail.id} value={managerMail.employeeMailId}>
                    {managerMail.employeeName}
                  </option>
                ))}
              </Dropdown>
              <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.managerMailID && <span>{formErrors.managerMailID}</span>}
              </span>

            </InputField>
            <InputField>
              <Label>Date of Joining</Label>
              <DatePicker
                name="employeeDOJ"
                value={formValues.employeeDOJ}
                onChange={handleInputChange}
                style={{ fontSize:"12px",borderColor: formErrors.employeeDOJ ? 'red' : '#2B3497' }}
              />
              {formErrors.employeeNo && (
                <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.employeeDOJ && <span>{formErrors.employeeDOJ}</span>}
                </span>
              )}

            </InputField>
            <InputField>
              <Label>BU Head</Label>
              <Dropdown
                name="buHeadMailId"
                value={formValues.buHeadMailId}
                onChange={(e) => {
                  const selectedManager = managerMails.find((managerMail) => managerMail.employeeMailId === e.target.value);
                  setFormValues({
                    ...formValues,
                    buHeadMailId: e.target.value,
                    buHeadId: selectedManager ? selectedManager.id : '' // Set buHeadId
                  });
                }}
                              style={{ fontSize:"12px",borderColor: formErrors.buHeadMailId ? 'red' : '#2B3497' }}
              >
                <option value="">Select BU Head</option>
                {managerMails.map((managerMail) => (
                  <option key={managerMail.id} value={managerMail.employeeMailId}>
                    {managerMail.employeeName}
                  </option>
                ))}
              </Dropdown>
              <span style={{ color: 'red', fontSize: '12px' }}>{formErrors.buHeadMailId && <span>{formErrors.buHeadMailId}</span>}
              </span>


            </InputField>
          </InputGroup>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </ModalContainer>
      )}
            <ToastContainer /> 
            </Container>
  );
};

export default AddEmployeePage;
