// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css'; // If you have global styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome CSS

const container = document.getElementById('root');
const root = createRoot(container); // createRoot instead of ReactDOM.render

root.render(
  <Router>
    <App />
  </Router>
);
