import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Container = styled.div`
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const PageHeader = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
  margin: 0;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

const Card = styled.div`
  width: 80%;
  max-width: 600px;
  background-color: #F3F3F3;
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 25px;
  opacity: 1;
  padding: 20px;
  margin-top: 20px;
`;

const CardHeader = styled.h2`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Segoe UI', 'Semibold';
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
  width: 100%;
  display:contents;
  justify-content: center;
`;

const Input = styled.input`
  padding: 10px;
  border: none;
  border-bottom: 2px solid #293495;
  font-family: 'Segoe UI', 'Semibold';
  text-align: center;
  width: 50%;
  outline: none;
  background-color: transparent;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 15px;
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SubmitButton = styled.button`
  background: linear-gradient(#2B3497, #161A4C);
  box-shadow: 0px 10px 20px #2B349747;
  border: 1px solid #FFFFFF;
  border-radius: 7px;
  padding: 9px;
  color: white;
  font-family: 'Segoe UI', 'Semibold';
  cursor: pointer;
  width: 114px;
`;

const ReprintPassPage = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [baggageTag, setBaggageTag] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate the mobile number
    if (mobileNumber.length !== 10) {
      setError('Mobile number must be exactly 10 digits.');
      return;
    }

    // Validate if the checkbox is checked
    if (!baggageTag) {
      setError('Please check the Baggage Tag checkbox.');
      return;
    }

    // If validation passes, log the mobile number
    console.log('Mobile Number:', mobileNumber);
    try {

      const response = await axios.get(`/Appointment/Appointments/${mobileNumber}`);
      console.log("response",response);
      toast.success('Reprint Submitted');
      

    } catch (error) {
      console.error('Error fetching appointment details:', error);
      // Handle error scenarios
    }

    // Reset the form fields
    setMobileNumber('');
    setBaggageTag(false);
  };

  return (
    <Container>
      <Header>
        <PageHeader>Reprint Pass</PageHeader>
      </Header>
      <CardContainer>
        <Card>
          <CardHeader>Enter Mobile Number</CardHeader>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Enter 10 Digit Mobile Number"
                maxLength="10"
                value={mobileNumber}
                onChange={(e) => {
                  const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
                  setMobileNumber(onlyNumbers);
                }}
                              required
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </FormGroup>
            <CheckboxContainer>
              <input
                type="checkbox"
                id="baggageTag"
                name="baggageTag"
                checked={baggageTag}
                onChange={() => setBaggageTag(!baggageTag)}
              />
              <CheckboxLabel htmlFor="baggageTag">Baggage Tag</CheckboxLabel>
            </CheckboxContainer>
            <SubmitButton type="submit">Submit</SubmitButton>
          </Form>
        </Card>
      </CardContainer>
      <ToastContainer />
    </Container>
  );
};

export default ReprintPassPage;
