// App.js
import React, { useState, useEffect, useContext } from 'react';
import Layout from './components/layout/Layout';
import Dashboard from './pages/dashboard/dashboardpage';
import BookAppointment from './pages/bookappointment/BookAppointmentForm';
import CheckIn from './pages/checkin/checkin';
import CheckOut from './pages/checkout/checkout';
import Login from './components/auth/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import OTPVerification from './pages/bookappointment/otpverification'
import Visitorapplicationform from './pages/bookappointment/Visitorapplicationform'
import Otplinkexpired from './pages/bookappointment/Otplinkexpired'
import Submissionform from './pages/bookappointment/Submissionform'
import CheckinVisitorFormC from './pages/checkin/CheckinVisitorForm';
import { UsernameProvider } from './components/auth/UsernameContext';
import { VisitorProvider } from './pages/bookappointment/VisitorContext';
import { AppIdProvider } from './pages/bookappointment/AppIdContext';
import VisitorReportPage from './pages/reports/VisitorReports';
import AppoinmentReportPage from './pages/reports/AppointmentReports'
import GrouptReportPage from './pages/reports/GroupReports'
import GroupAppoinmentReportsPage from './pages/reports/GroupAppoinmentReports'
import BlackListReportPage from './pages/reports/BlacklistReports';
import FrequentVisitorPage from './pages/reports/FrequentVisitor';
import MonthwiseVisitorPage from './pages/reports/MonthwiseVisitor';
import DatewiseReportPage from './pages/reports/DatewiseReport';
import BlacklistPage from './pages/blackList/Blacklist';
import AddlocatonPage from './pages/masterUpdate/Addlocation';
import AddEmployeePage from './pages/masterUpdate/AddEmployee';
import AddidentityProofPage from './pages/masterUpdate/AddidentityProof';
import ConfigurationPage from './pages/masterUpdate/Configuration';
import EmployeeRoleMapPage from './pages/masterUpdate/EmployeeRoleMap';
import RoleMapPage from './pages/masterUpdate/RoleMenuMap';
import VisitorTypeMapPage from './pages/masterUpdate/VisitorTypeMap';
import MonthwiseFeedbackPage from './pages/reports/MonthwiseFeedback';
import DatewiseFeedbackPage from './pages/reports/DateWiseFeedback';
import FoodapprovalPage from './pages/foodapproval/FoodapprovalReport';
import ReprintPassPage from './pages/reprint/ReprintPass';
import ReportBugPage from './pages/help/ReportBug';
import GroupAppoinmentDetailsPage from './pages/groupAppoinment/GroupAppoinmentDetails';
import AppoinmentFormPage from './pages/groupAppoinment/appoinmentform';
import AppoinmentStatusPage from './pages/groupAppoinment/appoinmentStaus';
import GroupCheckInPage from './pages/groupCheck/GroupCheckIn';
import GroupCheckoutPage from './pages/groupCheck/GroupCheckOut';
import GroupAppoinmentFormPage from './pages/groupCheck/GroupAppoinmentForm';
import GroupMemberDetailsUploadPage from './pages/groupCheck/GroupMemberDetails';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <UsernameProvider> {/* Ensure UsernameProvider wraps your routes */}
        <VisitorProvider>

          <Routes>
            <Route path="/login" element={<Login />} />
          </Routes>
          <Routes>

            <Route path="/layout" element={<Layout />}>
              <Route path="/layout/Dashboard/Dashboard" element={<Dashboard />} />
              <Route path="/layout/BookAppointment/BookAppointment" element={<BookAppointment />} />
              <Route path="/layout/LinkToVisitor/LinkToVisitor" element={<BookAppointment />} />
              <Route path="/layout/CheckIn/MenuItem" element={<CheckIn />} />
              <Route path="/layout/check-visitoriForm" element={<CheckinVisitorFormC />} />
              <Route path="/layout/Reports/Report" element={<VisitorReportPage />} />
              <Route path="/layout/AppointmentReport/AppointmentReport" element={<AppoinmentReportPage />} />
              <Route path="/layout/groupAppointmentReport/groupAppointmentReport" element={<GrouptReportPage />} />
              <Route path="/layout/Reports/groupAppointmentReport" element={<GroupAppoinmentReportsPage />} />
              <Route path="/layout/blacklist-reports" element={<BlackListReportPage />} />
              <Route path="/layout/Reports/FrequentVisitorReport" element={<FrequentVisitorPage />} />
              <Route path="/layout/Reports/MonthWiseVisitorReport" element={<MonthwiseVisitorPage />} />
              <Route path="/layout/Reports/DayWiseVisitorReport" element={<DatewiseReportPage />} />
              <Route path="/layout/monthwise-feedback" element={<MonthwiseFeedbackPage />} />
              <Route path="/layout/datewise-feedback" element={< DatewiseFeedbackPage />} />
              <Route path="/layout/BlackList/BlackList" element={<BlacklistPage />} />
              <Route path="/layout/AddLocation/AddLocation" element={<AddlocatonPage />} />
              <Route path="/layout/AddIdentityProof/AddIdentityProof" element={<AddidentityProofPage />} />
              <Route path="/layout/AddEmployee/AddEmployee" element={<AddEmployeePage />} />
              <Route path="/layout/configuration" element={<ConfigurationPage />} />
              <Route path="/layout/EmployeeRoleMapping/EmployeeRoleMapping" element={<EmployeeRoleMapPage />} />
              <Route path="/layout/RoleMenuMap/RoleMenuMap" element={<RoleMapPage />} />
              <Route path="/layout/VisitorType/VisitorType" element={<VisitorTypeMapPage />} />
              <Route path="/layout/Checkout/MenuItem" element={<CheckOut />} />
              <Route path="/layout/food-approval" element={<FoodapprovalPage />} />
              <Route path="/layout/Reprint/ReprintVisitor" element={<ReprintPassPage />} />
              <Route path="/layout/report-bug" element={<ReportBugPage />} />
              <Route path="/layout/group-appoinment-details" element={<GroupAppoinmentDetailsPage />} />
              <Route path="/layout/GroupAppointment/GroupAppointment" element={<AppoinmentFormPage />} />
              <Route path="/layout/AppointmentStatus/AppointmentStatus" element={<AppoinmentStatusPage />} />
              <Route path="/layout/GroupCheckIn/MenuItem" element={<GroupCheckInPage />} />
              <Route path="/layout/GroupCheckIn/GroupCheckout" element={<GroupCheckoutPage />} />
              <Route path="/layout/group-CheckIn-form" element={<GroupAppoinmentFormPage />} />
              <Route path="/layout/group-member-page" element={<GroupMemberDetailsUploadPage />} />
              
            </Route>
            
          </Routes>
          <Routes>
            <Route path="/otp-verification" element={<OTPVerification />} />
          </Routes>
          <Routes>
            <Route path="/visitor-form" element={<Visitorapplicationform />} />
          </Routes>
          <Routes>
            <Route path="/otp-expired" element={<Otplinkexpired />} />
          </Routes>
          <Routes>
            <Route path="/form-submitted" element={<Submissionform />} />
          </Routes>
          
        </VisitorProvider>
      </UsernameProvider>
    </div>
  );
}

export default App;
