import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import cardone from '../../assets/cardone.png';
import cardtwo from '../../assets/cardtwo.png';
import cardthree from '../../assets/cardthree.png';
import { AiOutlineQrcode } from 'react-icons/ai';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
  margin-bottom: 20px;
`;

const SubHeading = styled.h2`
  font-size: 20px;
  font-family: 'Segoe UI', 'Semibold';
  margin: 5px 0;
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Card = styled.div`
  width: 288px;
  height: 101px;
  background-color: ${(props) => props.bgColor || '#fff'};
  box-shadow: 0px 10px 10px #00000038;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 320px) {
    width: 90%;
  }
        @media (max-width: 1920px ) and (min-width: 1720px ) {
    width: 30%;

  }
       @media (max-width: 1720px ) and (min-width: 1520px ) {
    width: 30%;

  }
       @media (max-width: 1520px ) and (min-width: 1320px ) {
    width: 30%;

  }
      @media (max-width: 1320px ) and (min-width: 1120px ) {
    width: 30%;

  }
      @media (max-width: 1120px ) and (min-width: 920px ) {
    width: 30%;

  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

const CardImage = styled.img`
  width: 35px;
  height: 40px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Segoe UI', 'Semibold';
`;

const CardTitle = styled.h2`
  font-size: 18px;
  color: ${(props) => props.textColor || '#000'};
`;

const CardSubtitle = styled.p`
  font-size: 14px;
  color: ${(props) => props.textColor || '#000'};
  margin-top: -1px;
`;

const CardCount = styled.div`
  font-size: 24px;
  color: ${(props) => props.textColor || '#000'};
  font-family: 'Segoe UI', 'Semibold';
`;

const RadioGroup = styled.div`
  display: flex;
  margin: 20px 0;
`;

const RadioLabel = styled.label`
  font-size: 16px;
  font-family: 'Segoe UI', 'Semibold';
  color: #293495;
  margin-right: 20px;
  font-weight: 600;
`;

const RadioInput = styled.input`
  margin-right: 10px;
`;

const InputCard = styled.div`
  width: 374px;
  height: 182px;
  box-shadow: 0px 10px 20px #00000038;
  background: #E6E6E6;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin: 20px auto 0;
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #2B3497;
  padding-bottom: 5px;
  margin-bottom:10px;
`;

const QRLabel = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: #000000;
`;

const Button = styled.button`
  background-color: #2B3497;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 7px;
  box-shadow: 0px 10px 20px #2B349747;
  opacity: 1;
  cursor: pointer;
  width: 150px;
`;


const InputField = styled.input`
  background-color: transparent;
  width: 80%;
  height: 40px;
  padding: 1px;
  font-size: 14px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #2B3497;
  border-radius: 0;
  text-align: left;
  outline: none;

  &::placeholder {
    color: #000000;
  }

  /* Remove up and down arrows for number input */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-moz-appearance {
    textfield;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  background: linear-gradient(#FFFFFF, #F5F5F5, #FFFFFF);
  box-shadow: 0px 0px 45px #0000004A;
  border-radius: 30px;
  padding: 30px;
  text-align: center;
  opacity: 1;
  z-index: 1000;
  width: 400px;
  height: 125px;
`;

const PopupText = styled.p`
  font-size: 18px;
  color: #2B3497;
  margin-bottom: 20px;
`;

const PopupButton = styled.button`
  background-color: #2B3397;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 10px 20px #2B349754;
  opacity: 1;
  cursor: pointer;
`;

const GroupCheckInPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const [selectedOption, setSelectedOption] = useState('mobile');
  const [mobileNumber, setMobileNumber] = useState('');
  const [qrumber, setQrNumber] = useState('');
  const [vehicleNo, setVehicleno] = useState('');
  const [meetingOn, setMeetingon] = useState('');
  const [meetingTo, setMeetingto] = useState('');
  const [gate, setGate] = useState('');
  const [building, setBuilding] = useState('');
  const [purpose, setPurpose] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const [cardCounts, setCardCounts] = useState({
    mobileCheckInCount: 0,
    qrcodeCheckInCount: 0,
    totalCheckInCount: 0,
  });

  useEffect(() => {
    // Fetch the card counts from the API
    const fetchCardCounts = async () => {
      try {
        const response = await axios.get('https://vmsdevapi.azurewebsites.net/api/Dashboard');
        setCardCounts({
          mobileCheckInCount: response.data.groupCHKin,
          qrcodeCheckInCount: response.data.groupWQChkin,
          totalCheckInCount: response.data.groupCHKin,
        });
      } catch (error) {
        console.error('Error fetching card counts:', error);
      }
    };

    fetchCardCounts();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleInputChange = (e) => {
      // Only allow numeric input
  if (/^\d*$/.test(e.target.value)) {
    setMobileNumber(e.target.value);
  }

  //  setMobileNumber(e.target.value);
  };
  const handleInputQrChange = (e) => {
    setQrNumber(e.target.value);
  };


  const handleNextButtonClick = async () => {
    try {

      const response = await axios.get(`/GroupAppointment/GroupAppointment/${mobileNumber}`);
      localStorage.setItem('mobileNumber', mobileNumber);

      console.log('appointment details with mobile validation:', response.data);
      const data = response.data;
      localStorage.setItem('vehicleNo', data.vehicleNo || '');
      localStorage.setItem('meetingOn', data.meetingOn || '');
      localStorage.setItem('meetingTo', data.meetingTo || '');
      localStorage.setItem('gate', data.gate || '');
      localStorage.setItem('building', data.building || '');
      localStorage.setItem('purpose', data.purpose || '');



      console.log('vehicle', data.vehicleNo);
      console.log('meetingOn', data.meetingOn);
      console.log('meetingTo', data.meetingTo);
      console.log('gate', data.gate);
      console.log('building', data.building);
      console.log('purpose', data.purpose);

      // You can also set the state here if needed
      setVehicleno(data.vehicleNo || '');
      setMeetingon(data.meetingOn || '');
      setMeetingto(data.meetingTo || '');
      setGate(data.gate || '');
      setBuilding(data.building || '');
      setPurpose(data.purpose || '');

      // Update state or perform other actions based on the response
      if (response.status === 200) {

        navigate('/layout/group-CheckIn-form', { state: {  data, checkType:"WM",  } });

      }
      if(response.status === 204){
        setMobileNumber('');
        setShowPopup(true);
        return;
      }
    } catch (error) {
      console.error('Error fetching appointment details:', error);
      // Handle error scenarios
    }
  };

  const handleNextButtonQRClick = async () => {
    const decryptedNumber = decryptQRCode(qrumber);
    console.log("decryptedNumber",decryptedNumber);
    console.log("QrCode",qrumber);
    try {

      const response = await axios.get(`/GroupAppointment/GroupAppointment/${decryptedNumber}`);
      localStorage.setItem('mobileNumber', mobileNumber);

      console.log('appointment details with mobile validation:', response.data);
      const data = response.data;
      localStorage.setItem('vehicleNo', data.vehicleNo || '');
      localStorage.setItem('meetingOn', data.meetingOn || '');
      localStorage.setItem('meetingTo', data.meetingTo || '');
      localStorage.setItem('gate', data.gate || '');
      localStorage.setItem('building', data.building || '');
      localStorage.setItem('purpose', data.purpose || '');



      console.log('vehicle', data.vehicleNo);
      console.log('meetingOn', data.meetingOn);
      console.log('meetingTo', data.meetingTo);
      console.log('gate', data.gate);
      console.log('building', data.building);
      console.log('purpose', data.purpose);

      // You can also set the state here if needed
      setVehicleno(data.vehicleNo || '');
      setMeetingon(data.meetingOn || '');
      setMeetingto(data.meetingTo || '');
      setGate(data.gate || '');
      setBuilding(data.building || '');
      setPurpose(data.purpose || '');

      // Update state or perform other actions based on the response
      if (response.status === 200) {

        navigate('/layout/group-CheckIn-form', { state: {  data, checkType:"WQ",  } });

      }
      if(response.status === 204){
        setQrNumber('');
        setShowPopup(true);
        return;
      }
    } catch (error) {
      console.error('Error fetching appointment details:', error);
      // Handle error scenarios
    }

   
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  function decryptQRCode(qrCode) {
    let decryptedNumber = '';
    for (let i = 0; i < qrCode.length; i += 2) {
      const asciiValue = qrCode.substring(i, i + 2);
      decryptedNumber += String.fromCharCode(parseInt(asciiValue, 10));
    }

    // Use a regular expression to extract only the digits from the decrypted string
    const mobileNumber = decryptedNumber.match(/\d+/)[0];
    return mobileNumber;
  }


  return (
    <Container>
      <Heading>Group Check-In Count</Heading>
      <CardWrapper>
        <Card bgColor="#A2BECB">
          <CardContent>
            <CardImage src={cardone} alt="Card Image" />
            <CardText>
              <CardTitle>Mobile Check-In</CardTitle>
              <CardSubtitle>Today's Count</CardSubtitle>
            </CardText>
            <CardCount>{cardCounts.mobileCheckInCount}</CardCount>
          </CardContent>
        </Card>
        <Card bgColor="#A2BECB">
          <CardContent>
            <CardImage src={cardtwo} alt="Card Image" />
            <CardText>
              <CardTitle>QR Check-In</CardTitle>
              <CardSubtitle>Today's Count</CardSubtitle>
            </CardText>
            <CardCount>{cardCounts.qrcodeCheckInCount}</CardCount>
          </CardContent>
        </Card>
        <Card bgColor="#7C7C7C">
          <CardContent>
            <CardImage src={cardthree} alt="Card Image" />
            <CardText>
              <CardTitle textColor="#fff">Total Check-In</CardTitle>
              <CardSubtitle textColor="#fff">Today's Count</CardSubtitle>
            </CardText>
            <CardCount textColor="#fff">{cardCounts.totalCheckInCount}</CardCount>
          </CardContent>
        </Card>
      </CardWrapper>
      <SubHeading>Check-In</SubHeading>
      <RadioGroup>
        <RadioLabel>
          <RadioInput
            type="radio"
            value="mobile"
            checked={selectedOption === 'mobile'}
            onChange={handleOptionChange}
          />
          Using Mobile Number
        </RadioLabel>
        <RadioLabel>
          <RadioInput
            type="radio"
            value="qr"
            checked={selectedOption === 'qr'}
            onChange={handleOptionChange}
          />
          Using QR Code
        </RadioLabel>
      </RadioGroup>
      <InputCard>
        {selectedOption === 'mobile' && (
          <>
            <InputField
              type="text"
              placeholder="Enter 10 Digit Mobile Number"
              maxLength="10"
              value={mobileNumber}
              onChange={handleInputChange}
           />
            <Button onClick={handleNextButtonClick}>Next</Button>
          </>
        )}
        {selectedOption === 'qr' && (
          <>
          <InputField
            type="number"
            placeholder="Enter QR Code"
            maxLength="20"
            value={qrumber}
            onChange={handleInputQrChange}
          />
          <Button onClick={handleNextButtonQRClick}>Next</Button>
        </>
        )}
      </InputCard>
      {showPopup && (
        <Popup>
          <PopupText>Check In already done for this Visitor</PopupText>
          <PopupButton onClick={handleClosePopup}>OK</PopupButton>
        </Popup>
      )}
    </Container>
  );
};

export default GroupCheckInPage;
