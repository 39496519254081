import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import chartCard1 from '../../assets/chartCard1.png';
import chartCard2 from '../../assets/chartCard2.png';
import chartCard3 from '../../assets/chartCard3.png';
import chartCard4 from '../../assets/chartCard4.png';
import chartCard5 from '../../assets/chartcard5.png'; // Corrected variable name

const DashboardWrapper = styled.div`
  padding: 25px;
`;

const UserName = styled.h1`
  color: #293495;
  font-size: 25px;
`;

const PageHeading = styled.h2`
  text-align: left;
  font-size: 25px;
`;

const ImageRow = styled.div`
  display: flex;
  justify-content: center; /* Center images horizontally */
  gap: 16px; /* Adjusted gap between images */
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  width: 240px; /* Increased width for each image container */
  margin-bottom: 4px;
  text-align: center;
  position: relative;
  @media (max-width: 1920px) and (min-width: 1820px) {
    width: 294px;
  }
  @media (max-width: 1820px) and (min-width: 1720px) {
    width: 254px;
  }
  @media (max-width: 1720px) and (min-width: 1620px) {
    width: 234px;
  }
  @media (max-width: 1620px) and (min-width: 1520px) {
    width: 214px;
  }
  @media (max-width: 1520px) and (min-width: 1420px) {
    width: 204px;
  }
  @media (max-width: 1420px) and (min-width: 1320px) {
    width: 194px;
  }
  @media (max-width: 1320px) and (min-width: 1220px) {
    width: 176px;
  }
  @media (max-width: 1220px) and (min-width: 1120px) {
    width: 151px;
  }
  @media (max-width: 1120px) and (min-width: 1120px) {
    width: 151px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto; /* Ensures images maintain aspect ratio */
  max-height: 121px;
  border-radius: 8px; /* Added border-radius for smoother UI */
  @media (max-width: 1920px) and (min-width: 1720px) {
    width: 100%;
  }
`;

const ImageText = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 1220px) and (min-width: 1120px) {
    font-size: 12px;
  }
  @media (max-width: 1120px) and (min-width: 1120px) {
    font-size: 11px;
  }
`;

const ImageCount = styled.div`
  position: absolute;
  bottom: 39px;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
`;

const ChartRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;
  flex-wrap: wrap;
`;

const ChartBox = styled.div`
  background: linear-gradient(180deg, #f8f8f8 0%, #d9d9d9 100%);
  box-shadow: 10px 10px 28px #00000021;
  border: 3px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  text-align: center;
  flex: 1;
  min-width: 300px;
  max-width: 600px;
`;

const ChartHeader = styled.h3`
  color: #2b3497;
  margin-bottom: 20px;
`;

const Dashboard = () => {
  const [cardCounts, setCardCounts] = useState({
    expectedVisitors: 0,
    checkedIn: 0,
    crossedDeadline: 0,
    upcomingDeadlines: 0,
    checkedOut: 0,
  });

  const [lineChartData, setLineChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Checkin',
        data: [],
        borderColor: '#293495',
        backgroundColor: 'rgba(41, 52, 149, 0.2)',
        fill: true,
      },
      {
        label: 'Checkout',
        data: [],
        borderColor: '#FFA500',
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        fill: true,
      },
    ],
  });

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Counts',
        data: [],
        backgroundColor: '#293495',
      },
    ],
  });

  useEffect(() => {
    const fetchCardCounts = async () => {
      try {
        const response = await axios.get('https://vmsdevapi.azurewebsites.net/api/Dashboard');
        const data = response.data;
        setCardCounts({
          expectedVisitors: data.expectedCount,
          checkedIn: data.checkedin,
          crossedDeadline: data.crossed,
          upcomingDeadlines: data.udCount,
          checkedOut: data.checkedout,
        });
      } catch (error) {
        console.error('Error fetching card counts:', error);
      }
    };

    const fetchMonthCounts = async () => {
      try {
        const responseMonth = await axios.get('https://vmsdevapi.azurewebsites.net/api/VisitorDetails/GetVisitorVIPDetails');
        const data = responseMonth.data;

        const labels = data.map(item => item.onDay);
        const checkinData = data.map(item => parseInt(item.checkin, 10));
        const checkoutData = data.map(item => parseInt(item.checkout || 0, 10)); // Handle empty checkout values

        setLineChartData({
          labels: labels,
          datasets: [
            {
              label: 'Check-In',
              data: checkinData,
              borderColor: '#293495',
              backgroundColor: 'rgba(41, 52, 149, 0.2)',
              fill: true,
            },
            {
              label: 'Check-Out',
              data: checkoutData,
              borderColor: '#FFA500',
              backgroundColor: 'rgba(255, 165, 0, 0.2)',
              fill: true,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching month counts:', error);
      }
    };

    const fetchChartData = async () => {
      try {
        const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format
        const responseChart = await axios.get(`https://vmsdevapi.azurewebsites.net/api/VisitorDetails/GetFVMSCheckInVisitor/${today}`);
        const chartData = responseChart.data;

        const labels = chartData.map(item => item.onTime);
        const data = chartData.map(item => parseInt(item.visitors, 10));

        setBarChartData({
          labels: labels,
          datasets: [
            {
              label: 'Counts',
              data: data,
              backgroundColor: '#293495',
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching Chart counts:', error);
      }
    };

    fetchCardCounts();
    fetchChartData();
    fetchMonthCounts();
  }, []);

  return (
    <DashboardWrapper>
      {/* <UserName>Hello, Rohit!</UserName> */}
      <PageHeading>Dashboard</PageHeading>
      <ImageRow>
        <ImageContainer>
          <Image src={chartCard1} alt="Image 1" />
          <ImageText>Expected Visitors</ImageText>
          <ImageCount>{cardCounts.expectedVisitors}</ImageCount>
        </ImageContainer>
        <ImageContainer>
          <Image src={chartCard2} alt="Image 2" />
          <ImageText>Checked-In</ImageText>
          <ImageCount>{cardCounts.checkedIn}</ImageCount>
        </ImageContainer>
        <ImageContainer>
          <Image src={chartCard3} alt="Image 3" />
          <ImageText>Crossed Deadline</ImageText>
          <ImageCount>{cardCounts.crossedDeadline}</ImageCount>
        </ImageContainer>
        <ImageContainer>
          <Image src={chartCard4} alt="Image 4" />
          <ImageText>Upcoming Deadlines</ImageText>
          <ImageCount>{cardCounts.upcomingDeadlines}</ImageCount>
        </ImageContainer>
        <ImageContainer>
          <Image src={chartCard5} alt="Image 5" />
          <ImageText>Checked-Out</ImageText>
          <ImageCount>{cardCounts.checkedOut}</ImageCount>
        </ImageContainer>
      </ImageRow>
      <ChartRow>
      <ChartBox>
          <ChartHeader>Check-In Visitor For Today</ChartHeader>
          <Bar data={barChartData} />
        </ChartBox>
        <ChartBox>
          <ChartHeader>Checked-In Checked-Out Visitors For The Month</ChartHeader>
          <Line data={lineChartData} />
        </ChartBox>
      </ChartRow>
    </DashboardWrapper>
  );
};

export default Dashboard;
