import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import centerImage from '../../assets/centerImage.png'; // adjust the path as necessary
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100vh;
  background: linear-gradient(#FBFBFB, #E6E6E6);
  margin-top:39px;
`;

const Image = styled.img`
    width: 170px;
    height: 46px;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font: normal normal 600 38px/54px Segoe UI;
  letter-spacing: 0px;
  color: #060000;
  text-transform: capitalize;
  opacity: 1;
  margin: 0;
  margin-bottom: 10px;
`;

const SubHeading = styled.p`
  text-align: left;
  font: normal normal bold 21px/35px Segoe UI;
  letter-spacing: 0px;
  color: #2B3497;
  opacity: 1;
  margin: 0;
  margin-bottom: 10px;
`;
const VerifyButton = styled.button`
  background: linear-gradient(#2B3497, #7177B7, #2B3497);
  box-shadow: 0px 10px 20px #2B34976E;
  border-radius: 6px;
  width: 163px;
  height: 37px;
  font: normal normal 600 21px/26px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;


const Submissionform = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
    const [timeRemaining, setTimeRemaining] = useState(120);
    const [otp, setOtp] = useState(Array(6).fill(""));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleChange = (element, index) => {
        const val = element.value;
        if (/^[0-9]$/.test(val)) {
            let newOtp = [...otp];
            newOtp[index] = val;
            setOtp(newOtp);
            if (index < 5) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };

    const handleResend = () => {
        setTimeRemaining(120);
        setOtp(Array(6).fill(""));
    };
    const redirectClass =  () => {
      navigate('/layout/Dashboard/Dashboard');
    }

    return (
        <Container>
            <Image src={centerImage} alt="OTP Image" />
            <SubHeading>Thanks for submitting the form. please wait for the confirmation mail</SubHeading>
            <VerifyButton onClick={redirectClass}>Close</VerifyButton>
        </Container>
    );
};

export default Submissionform;
