import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { FaAsterisk } from 'react-icons/fa';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0px);
  z-index: 1000; /* Ensure it's above other content */
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
  width: 468px;
  height: 563px;
  border-radius: 8px;
  opacity: 1;
  z-index: 1001; /* Ensure it's above the overlay */
  display: ${props => props.isOpen ? 'block' : 'none'};
  padding: 20px;
  overflow: hidden; /* Ensure content does not overflow */
`;

const ModalHeader = styled.h2`
  text-align: center;
  color: #293495;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #293495;
`;

const Screenshot = styled.img`
  display: block;
  max-width: 100%;
  max-height: 200px; /* Set the maximum height */
  height: auto;
  margin: 20px auto;
`;

const DescriptionLabel = styled.label`
  display: block;
  text-align: center;
  margin: 20px auto;
  font-weight: bold;
`;

const DescriptionInput = styled.textarea`
  display: block;
  margin: 0 auto 20px auto;
  width: 320px;
  height: 118px;
  border: 2px solid #293495;
  opacity: 1;
  resize: none; /* Prevent resizing */
  padding: 10px;
`;

const InfoText = styled.p`
  text-align: center;
  margin: 20px auto;
`;

const SubmitButton = styled.button`
  display: block;
  margin: 20px auto;
  background: linear-gradient(90deg, #2B3497 0%, #3D46B4 100%);
  box-shadow: 0px 10px 20px #2B349752;
  border-radius: 12px;
  border: none;
  color: #FFF;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 1;
`;
const Asterisk = styled.span`
  color: red;
`;

const ReportBugModal = ({ isOpen, onClose }) => {
  const [screenshot, setScreenshot] = useState(null);
  const [description, setDescription] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const captureScreenshot = async () => {
    const modalBackground = document.querySelector('#modal-background');
    if (modalBackground) modalBackground.style.backdropFilter = 'none';

    await new Promise(resolve => setTimeout(resolve, 10)); // Small delay to ensure modal is hidden
    html2canvas(document.body).then(canvas => {
      setScreenshot(canvas.toDataURL('image/png'));

      // Restore blur effect if modal is open
      if (modalBackground) modalBackground.style.backdropFilter = 'blur(0px)';
      if (!isOpen && modalBackground) {
        modalBackground.style.backdropFilter = 'none';
      }

      setModalOpen(true); // Open modal after capturing screenshot
    });
  };

  const handleSubmit = async () => {
    const username = localStorage.getItem('employeeName')
    const requestData = {
      id: 0,
      description: description,
      image: screenshot.split(',')[1],
      createdBy: username,
      createdOn: new Date().toISOString(),
      modifiedBy: username,
      modifiedOn: new Date().toISOString(),
      isMail: true,
    };


    try {
      const response = await axios.post('/BugReport/AddBugReport', requestData);
      console.log("response",response);
      onClose(); // Close the modal on success
    } catch (error) {
      console.error('Error submitting bug report:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      captureScreenshot();
      document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
    } else {
      setModalOpen(false);
      document.body.style.overflow = 'unset'; // Re-enable scrolling when modal is closed
    }
  }, [isOpen]);

  return (
    <ModalBackground id="modal-background" isOpen={modalOpen} onClick={onClose}>
      <ModalContainer id="report-bug-modal" isOpen={modalOpen} onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <ModalHeader>Report A Bug</ModalHeader>
        {screenshot && <Screenshot src={screenshot} alt="Current Page Screenshot" />}
        <DescriptionLabel>Description (Max 250 Characters)<Asterisk> *</Asterisk></DescriptionLabel>
        <DescriptionInput 
          maxLength="250" 
          value={description}
          onChange={(e) => setDescription(e.target.value)} 
        />
        <InfoText>This Bug Will Be Sent To Technical Support Team</InfoText>
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ReportBugModal;
