import React, { useState, useEffect,useContext } from 'react';
import styled from 'styled-components';
import { FaUser, FaLock } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import leftBackground from '../../assets/leftBackground.png';
import rightBackground from '../../assets/rightBackground.png';
import centerImage from '../../assets/centerImage.png';
import {storeUserData } from '../../services/Storage'
import { isAuthenticated } from '../../services/Auth';
import {  useNavigate } from 'react-router-dom';
import { LoginApi } from '../../services/Api';
import UsernameContext from './UsernameContext';
import Modal from 'react-modal';
import UpdatePasswordModal from './UpdatePasswordModal';
import ForgotPasswordModal from './ForgotPasswordModal';

Modal.setAppElement('#root'); // Set the app element for accessibility





const Container = styled.div`
  display: flex;
  height: 100vh;
  font-family: 'Segoe UI', sans-serif; /* Set font family for the whole login page */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 0 0 40%;
  background: url(${leftBackground}) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex: 0 0 30%;
  }
`;

const CenterImage = styled.img`
  max-width: 50%;
  max-height: 50%;
`;

const RightColumn = styled.div`
  flex: 0 0 60%;
  background: url(${rightBackground}) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center; /* Align content to the bottom */
  align-items: center; /* Align content to the bottom */
  @media (max-width: 768px) {
    flex: 0 0 70%;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
  }
`;

const Card = styled.div`
  background: #fff;
  box-shadow: 0px 0px 50px #E3E3E3E0;
  border-radius: 33px;
  opacity: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;

  height: auto;
  text-align: center;
  width: 40%; /* Adjusted width for better responsiveness */
  max-width: 400px; /* Added max-width for smaller screens */
  margin-bottom: 2rem; /* Space between card and bottom */
  @media (max-width: 768px) {
    width: 80%;
    padding: 2rem;
  }
`;

const MainHeading = styled.h1`
  margin-top: 0px;
  color: #293495;
  text-align: center; /* Ensure the heading is centered */
`;

const SubHeading = styled.p`
  color: #4D528D;
  font-size: 8px;
  font-weight: 500;
  font-family: 'Segoe UI', sans-serif; /* Set font family for the whole login page */
  text-align: center; /* Ensure the subheading is centered */
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(43, 52, 151, 0.5); /* Icon color with 50% opacity */
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  padding-left: 30px; /* Added padding to accommodate icon */
  border: none;
  border-bottom: 2px solid rgba(43, 52, 151, 0.5); /* Bottom border color with 50% opacity */
  background: none;
  outline: none;
  font-family: 'Segoe UI', sans-serif; /* Set font family for input fields */
  &::placeholder {
    color: rgba(43, 52, 151, 0.5); /* Placeholder color with 50% opacity */
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Link = styled.a`
  color: #293495;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;

`;

const Button = styled.button`
  background: linear-gradient(to right, #575FC1, #1F2884); /* Linear gradient background */
  color: #fff;
  padding: 0.5rem 1rem;
  border: 2px solid #fff;
  border-radius: 8px;
  cursor: pointer;
  width: 212px; /* Width of the button */
  height: 39px; /* Height of the button */
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.65); /* Drop shadow */
  &:disabled {
    background: gray; /* Disabled button background */
    cursor: not-allowed; /* Disabled button cursor */
  }
`;

const UpdatePasswordLink = styled.div`
  margin-top: 1rem;
  color: #293495;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9em;
`;


const Login = () => {
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false); // State for ForgotPasswordModal


  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [formValues, setFormValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    let errors = {};

    if (!formValues.oldPassword.trim()) {
      errors.oldPassword = 'Old Password is required';
    }

    if (!formValues.newPassword.trim()) {
      errors.newPassword = 'New Password is required';
    }

    if (!formValues.confirmNewPassword.trim()) {
      errors.confirmNewPassword = 'Confirm New Password is required';
    }

    if (formValues.newPassword !== formValues.confirmNewPassword) {
      errors.confirmNewPassword = 'New Password and Confirm New Password must match';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Handle the form submission
    console.log('Form submitted', formValues);
    setIsUpdatePasswordModalOpen(false);

  };


  const [username, setUsernameLocal] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputs,setInputs] = useState({
    username:"",
    password:"",
  })
  const { setUsername } = useContext(UsernameContext); // Access setUsername function from context

  const initialStateErrors = {
    username:{required:false},
    password:{required:false},
};
const navigate = useNavigate(); // Initialize useNavigate hook

  const [errors,setErrors] = useState(initialStateErrors);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);


  const handleInput = (event)=>{
    setInputs({...inputs,[event.target.name]:event.target.value})
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });

  }
  
  const handleSubmit = (event)=>{
    console.log(inputs);
    event.preventDefault();
    let errors =initialStateErrors; 
    let hasError = false; 
    
    if (inputs.username == "") {
        errors.username.required =true;
        hasError=true;
    }
    if (inputs.password == "") {
        errors.password.required =true;
        hasError=true;
    }
    if (!hasError) {
      LoginApi(inputs).then((response)=>{
        console.log(response)
         //storeUserData(response.data.token);
         //localStorage.setItem('token', response.data);
        // localStorage.setItem('isAuthenticated', 'true');
         if (response.data===true)
          {
            localStorage.setItem('isAuthenticated', 'true');
            setUsername(inputs.username); // Set username to context
            navigate('/layout/Dashboard/Dashboard');

          }
          else{
            localStorage.setItem('isAuthenticated', 'false');
            toast.error('Login Failed. Please check your credentials.');
            console.log('Login Failed', response.data);
          }

      }).catch((err)=>{
        console.log(err);
        localStorage.setItem('isAuthenticated', 'false');
        toast.error('An error occurred during login. Please try again.');

        //  if (err.code="ERR_BAD_REQUEST") {
        //       setErrors({...errors,custom_error:"Invalid Credentials."})
        //  }

      })
  }
  setErrors({...errors});

}
const handleUpdatePasswordLinkClick = () => {
  if (inputs.username === '') {
    setErrors({ ...errors, username: { required: true } });
  } else {
    setIsUpdatePasswordModalOpen(true);
  }
};


const handleForgotPasswordLinkClick = () => {
  setIsForgotPasswordModalOpen(true);
};


  return (
    <Container>
      <ToastContainer />
      <LeftColumn>
        <CenterImage src={centerImage} alt="Centered" />
      </LeftColumn>
      <RightColumn>
        <Card>
        <form onSubmit={handleSubmit}>

          <MainHeading>Welcome</MainHeading>
          {/* <SubHeading>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At</SubHeading> */}
          <InputContainer>
            <Icon><FaUser /></Icon>
            <Input
              type="text"
              placeholder="User Name"
              name="username"
              onChange={handleInput}            />
          </InputContainer>
          {errors.username.required && <ErrorMessage>Username is required</ErrorMessage>}

          <InputContainer>
            <Icon><FaLock /></Icon>
            <Input
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleInput}            />
          </InputContainer>
          {errors.password.required && <ErrorMessage>Password is required</ErrorMessage>}

          <LinksContainer>
          <Link onClick={handleForgotPasswordLinkClick}>Forgot password?</Link>
          <Link href="#">Sign In With Azure Directory</Link>
          </LinksContainer>
          {/* <Button onClick={handleLogin} disabled={isButtonDisabled}>Login</Button> */}
          <Button type='submit' >Login</Button>
          <UpdatePasswordLink onClick={handleUpdatePasswordLinkClick}>Update Password</UpdatePasswordLink>

          </form>
        </Card>
      </RightColumn>
      <UpdatePasswordModal
        isOpen={isUpdatePasswordModalOpen}
        setIsOpen={setIsUpdatePasswordModalOpen}
        handleUpdatePassword={handleUpdatePassword}
        username={inputs.username}
      />
            <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        setIsOpen={setIsForgotPasswordModalOpen}
      />

    </Container>
  );
};

export default Login;
