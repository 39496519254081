import React, { createContext, useState } from 'react';

const VisitorContext = createContext();

export const VisitorProvider = ({ children }) => {
    const [visitormobile, setVisitorMobile] = useState('');

    return (
        <VisitorContext.Provider value={{ visitormobile, setVisitorMobile }}>
            {children}
        </VisitorContext.Provider>
    );
};

export default VisitorContext;
