import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchVisitorTypes, fetchIdProofTypes, fetchMeetingVenues, insertAppointment, fetchGateDetails, fetchBuildingsByGate } from '../../services/Api';
import Webcam from 'react-webcam'; // Import react-webcam
import { User } from 'react-feather'; // Import the User icon
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Heading = styled.h1`
  font-size: 25px;
  font-family: 'Segoe UI', Semibold;
  color: #060000;
  margin: 0;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 81px;
  height: 32px;
  border-radius: 7px;
  box-shadow: 0px 5px 10px #2b349754;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI', Semibold;
`;

const ResetButton = styled(Button)`
  background-color: red;
`;

const SubmitButton = styled(Button)`
  background-color: #2b3497;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  flex: 1;
  background: radial-gradient(circle, #ffffff, #e3e3e3);
  box-shadow: 0px 10px 20px #00000038;
  border-radius: 15px;
  padding: 27px;
  opacity: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Segoe UI', Semibold;
  color: #000000;
  margin-bottom: 5px;
`;

const Asterisk = styled.span`
  color: red;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 335px;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #2b3497;
  font-family: 'Segoe UI', Regular;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 2px solid #2b3497;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 0;
    border: none;
    font-family: 'Segoe UI', Regular;
    background: transparent;
    border-bottom: 1px solid #2b3497;
    &:focus {
      outline: none;
      border-bottom: 2px solid #2b3497;
    }
  }
     @media (max-width: 1920px) and (min-width: 1720px) {
       width: 545px;

  }
       @media (max-width: 1720px) and (min-width: 1420px) {
       width: 391px;

  }
         @media (max-width: 1420px) and (min-width: 1220px) {
       width: 260px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
       width: 200px;

  }
`;

const ProfileCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  width: 131px;
  height: 131px;
  background: linear-gradient(#ffffff, #f6f6f6);
  border-radius: 50%;
  box-shadow: 0px 10px 10px #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const ProfileIcon = styled.div`
  width: 134px;
  height: 131px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2b3497;
  font-size: 30px;
  font-weight: bold;
  position: relative;
`;

const PlusIcon = styled.div`
  width: 47px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BFBFBF;
  font-size: 44px;
  font-weight: 700;
`;

const ProfileText = styled.div`
  font-family: 'Segoe UI', Semibold;
  font-size: 18px;
  color: #ff3030;
  margin-bottom: 20px;
`;

const PhotoButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: #343d9b;
  box-shadow: 0px 10px 10px #2b349733;
  border: 2px solid #343d9b;
  border-radius: 8px;
  opacity: 1;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  width: 160px;
  height: 35px;
  background-color: transparent;
  border: 2px solid #343d9b;
  border-radius: 8px;
  box-shadow: 0px 10px 10px #2b349733;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 20px;
           @media (max-width: 1420px) and (min-width: 1220px) {
  width: 125px;

  }
              @media (max-width: 1220px) and (min-width: 1020px) {
  width: 100px;

  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-family: 'Segoe UI', Semibold;
  font-size: 14px;
`;

const PrivacyLink = styled.a`
  color: #2b3497;
  margin-left: 5px;
  text-decoration: none;
`;
const WebcamContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
`;
const ButtonRow = styled.div`
  display: flex;
  gap: 10px; /* Adjust gap between buttons */
  margin-bottom: 20px; /* Optional: Adjust margin bottom */
`;

const GroupAppoinmentFormPage = () => {
    const location = useLocation();
    const { checkType } = location.state || {};
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [selectedGate, setSelectedGate] = useState('');
    const [selectedVisitorType, setSelectedVisitorType] = useState('');
    const [selectedIdProofType, setSelectedIdProofType] = useState('');

    const [visitorTypes, setVisitorTypes] = useState([]);
    const [idProofTypes, setIdProofTypes] = useState([]);

    const [meetingvenues, setMeetingvenues] = useState([]);
    const [gates, setGates] = useState([]);
    const [buildings, setBuildings] = useState([]);


    useEffect(() => {
        const getVisitorTypes = async () => {
            try {
                const types = await fetchVisitorTypes();
                setVisitorTypes(types);
            } catch (error) {
                console.error('Error fetching visitor types:', error);
            }
        };
        const getIdProofTypes = async () => {
            try {
                const types = await fetchIdProofTypes();
                setIdProofTypes(types);
            } catch (error) {
                console.error('Error fetching visitor types:', error);
            }
        };
        const getMeetingvenues = async () => {
            try {
                const venues = await fetchMeetingVenues();
                setMeetingvenues(venues);
            } catch (error) {
                console.error('Error fetching meeting venues:', error);
            }
        };
        const getGateDetails = async () => {
            try {
                const venues = await fetchGateDetails();
                setGates(venues);
            } catch (error) {
                console.error('Error fetching Gate venues:', error);
            }
        };

        getVisitorTypes();
        getIdProofTypes();
        getMeetingvenues();
        getGateDetails();
    }, []);
    const { data } = location.state || {}; // Destructure
    console.log("data", data);

    useEffect(() => {
        if (data) {
            setFormData({
                groupLeaderName: data?.groupLeaderName || '',
                visitorEmail: data?.visitorEmail || '',
                visitorMobile: data?.visitorMobile || '',
                meetingVenue: data?.meetingVenue || '',
                company: data?.visitorCompany || '',
                meetingFrom: data?.appointmentDate || '',
                meetingTo: data?.validTill || '',
                selectedVisitorTypeId: data?.selectedVisitorTypeId || '',
                selectedIdProofTypeId: data?.idProofType || '',
                idProofNo: data?.idProofNo || '',
                gate: data?.gate || '',
                building: data?.building || '',
            })
        }

    }, [data])


    useEffect(() => {
        if (selectedGate) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                gate: selectedGate.name, // Use the gate name in the form data
            }));
        }
    }, [selectedGate]);

    const handleGateChange = async (event) => {
        const gateID = parseInt(event.target.value, 10); // Convert to integer if gate.id is a number
        const selectedGateObject = gates.find(gate => gate.id === gateID);

        setSelectedGate(selectedGateObject); // Store the entire gate object


        try {
            const buildings = await fetchBuildingsByGate(gateID);
            setBuildings(buildings);
        } catch (error) {
            console.error('Error fetching buildings:', error);
        }
    };



    const [formData, setFormData] = useState({
        groupLeaderName: '',
        visitorEmail: '',
        visitorMobile: '',
        company: '',
        meetingFrom: null,
        meetingTo: null,
        selectedVisitorTypeId: selectedVisitorType,
        selectedIdProofTypeId: selectedIdProofType,
        idProofNo: '',
        gate: '',
        building: '',
    });

    const [formErrors, setFormErrors] = useState({
        groupLeaderName: false,
        visitorEmail: false,
        visitorMobile: false,
    });


    const handleReset = () => {
        setFormData({
            groupLeaderName: '',
            visitorEmail: '',
            visitorMobile: '',
            meetingVenue: '',
            company: '',
            meetingFrom: null,
            meetingTo: null,
            selectedVisitorTypeId: '',
            selectedIdProofTypeId: '',
            idProofNo: '',
            gate: '',
            building: '',
        });
        setFormErrors({
            groupLeaderName: false,
            visitorEmail: false,
            visitorMobile: false,
        });
        console.log('Form reset');
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear error when user starts typing
        if (formErrors[name]) {
            setFormErrors({
                ...formErrors,
                [name]: false,
            });
        }
    };

    const handleSubmit = () => {
        let valid = true;
        const errors = {};

        if (formData.groupLeaderName === '') {
            errors.groupLeaderName = true;
            valid = false;
        }
        if (formData.visitorEmail === '') {
            errors.visitorEmail = true;
            valid = false;
        }
        if (formData.visitorMobile === '') {
            errors.visitorMobile = true;
            valid = false;
        }

        if (formData.visitorEmail !== '') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.visitorEmail)) {
                errors.visitorEmail = true;
                valid = false;
            }
        }

        if (formData.visitorMobile !== '') {
            const mobilePattern = /^\d{10}$/;
            if (!mobilePattern.test(formData.visitorMobile)) {
                errors.visitorMobile = true;
                valid = false;
            }
        }

        if (!valid) {
            setFormErrors(errors);
            console.log('Form has errors:', errors);
        } else {
            console.log('Form data:', formData);
            const fetchHostDetails = async () => {
                try {
    
                    const responseGroupCheckin = await axios.get(`/GroupMember/${data.id}`);
                    console.log("responseGroupCheckin", responseGroupCheckin);
                    const memberDetails = responseGroupCheckin.data
                    navigate('/layout/group-member-page', { state: { formData, memberDetails , checkType} });
    
                } catch (error) {
                    console.error('Error fetching appointment details:', error);
                    // Handle error scenarios
                }
            };
            fetchHostDetails();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const webcamRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Take photo');

    const handleCapture = () => {
        if (!isCameraOn) {
            setIsCameraOn(true);
            setButtonLabel('Capture');
        } else {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log('Captured Image:', imageSrc);
            setIsCameraOn(false);
            setButtonLabel('Take photo');
        }
    };


    return (
        <Container>
            <Header>
                <Heading>Group Appointment</Heading>
                <ButtonGroup>
                    <ResetButton>Reset</ResetButton>
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </ButtonGroup>
            </Header>
            <CardContainer>
                <Card>
                    <FormGroup>
                        <Label>
                            Group Leader Name<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            name="groupLeaderName"
                            value={formData.groupLeaderName}
                            onChange={handleInputChange}
                            placeholder="Enter Group Leader Name"
                            style={{
                                borderBottomColor: formErrors.groupLeaderName ? 'red' : '#2b3497',
                            }}
                            disabled={formData.groupLeaderName ? true : false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Email<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="email"
                            name="visitorEmail"
                            value={formData.visitorEmail}
                            placeholder="Enter Email"
                            onChange={handleInputChange}
                            style={{
                                borderBottomColor: formErrors.visitorEmail ? 'red' : '#2b3497',
                            }}
                            disabled={formData.visitorEmail ? true : false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Mobile<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="tel"
                            name="visitorMobile"
                            value={formData.visitorMobile}
                            placeholder="Enter 10 Digit Mobile number"
                            maxLength="10"

                            onChange={handleInputChange}
                            style={{
                                borderBottomColor: formErrors.visitorMobile ? 'red' : '#2b3497',
                            }}
                            disabled={formData.visitorMobile ? true : false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Meeting Venue
                            <Asterisk> *</Asterisk>
                        </Label>
                        <Select
                            name="meetingvenue"
                            value={formData.meetingVenue}
                            onChange={handleChange}
                            disabled={formData.meetingVenue ? true : false}
                        >
                            <option value="">Select Meeting Venue</option>
                            {meetingvenues.map((type) => (
                                <option key={type.locationName} value={type.locationName}>
                                    {type.locationName}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Company<Asterisk> *</Asterisk>
                        </Label>
                        <Input
                            type="text"
                            name="company"
                            placeholder="Enter Company"
                            value={formData.company}
                            onChange={handleInputChange}
                            disabled={formData.company ? true : false}

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Visitor Type
                            <Asterisk> *</Asterisk>
                        </Label>
                        <Select
                            name="selectedVisitorTypeId"
                            value={formData.selectedVisitorTypeId}
                            onChange={handleChange}
                            disabled={formData.selectedVisitorTypeId ? true : false}
                        >
                            <option value="">Select type</option>
                            {visitorTypes.map((type) => (
                                <option key={type.id} value={type.id}>{type.visitorTypeDesc}</option>
                            ))}
                        </Select>
                    </FormGroup>
                </Card>
                <Card>
                    <FormGroup>
                        <Label>
                            Meeting From<Asterisk> *</Asterisk>
                        </Label>
                        <StyledDatePicker
                            selected={formData.meetingFrom}
                            onChange={(date) =>
                                setFormData((prevData) => ({ ...prevData, meetingFrom: date }))
                            }
                            showTimeSelect
                            dateFormat="Pp"
                            disabled={formData.meetingFrom ? true : false}

                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Meeting To<Asterisk> *</Asterisk>
                        </Label>
                        <StyledDatePicker
                            selected={formData.meetingTo}
                            onChange={(date) =>
                                setFormData((prevData) => ({ ...prevData, meetingTo: date }))
                            }
                            showTimeSelect
                            dateFormat="Pp"
                            disabled={formData.meetingTo ? true : false}
                        />
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>
                            Gate
                            <Asterisk>*</Asterisk>
                        </Label>
                        <Select
                            name="gate"
                            value={selectedGate ? selectedGate.id : ''}
                            onChange={handleGateChange}
                            // disabled={formData.meetingTo ? true : false} 
                        >
                            <option value="">Select Gate</option>
                            {gates.map((gate) => (
                                <option key={gate.id} value={gate.id}>
                                    {gate.name}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Building
                            <Asterisk>*</Asterisk>
                        </Label>
                        <Select
                            name="building"
                            value={formData.building}
                            onChange={handleChange}
                        >
                            <option value="">Select Building</option>
                            {Array.isArray(buildings) && buildings.map((building) => (
                                <option key={building.id} value={building.name}>
                                    {building.name}
                                </option>
                            ))}
                        </Select>
                    </FormGroup> */}

                    <FormGroup>
                        <Label>
                            Id Proof Type
                        </Label>
                        <Select
                            name="selectedIdProofTypeId"
                            value={formData.selectedIdProofTypeId}
                            onChange={handleChange}
                            disabled={formData.selectedIdProofTypeId ? true : false}
                        >
                            <option value="">Select type</option>
                            {idProofTypes.map((type) => (
                                <option key={type.id} value={type.id}>{type.idProofDescription}</option>
                            ))}
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Id Proof Number
                        </Label>
                        <Input
                            type="text"
                            name="idProofNo"
                            placeholder="Enter Id Proof Number"
                            value={formData.idProofNo}
                            onChange={handleInputChange}
                            disabled={formData.idProofNo ? true : false}
                        />
                    </FormGroup>
                </Card>
                <ProfileCard>
                    <ProfileText>(Only png images)</ProfileText>
                    <Circle>
                        <WebcamContainer>
                            {isCameraOn && (
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/png"
                                    width="100%"
                                    height="100%"
                                    videoConstraints={{
                                        width: 200,
                                        height: 200,
                                        facingMode: 'user',
                                    }}
                                />
                            )}
                            {!isCameraOn && (
                                <ProfileIcon>
                                    <User size={40} />
                                </ProfileIcon>
                            )}
                        </WebcamContainer>
                        {/* <ProfileIcon>
                            <User size={40} />
                        </ProfileIcon>
                        <PlusIcon>+</PlusIcon> */}
                    </Circle>
                    <PhotoButton onClick={handleCapture}>{buttonLabel}</PhotoButton>
                    <ButtonRow>
                        <ActionButton>Photograph</ActionButton>
                        <ActionButton>Upload images</ActionButton>
                    </ButtonRow>

                    <CheckboxContainer>
                        <input type="checkbox" />
                        <span>
                            I agree to the
                            <PrivacyLink href="#">privacy policy</PrivacyLink>
                        </span>
                    </CheckboxContainer>
                </ProfileCard>
            </CardContainer>
        </Container>
    );
};

export default GroupAppoinmentFormPage;