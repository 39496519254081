import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiSearch, FiEye, FiEdit, FiTrash2, FiPlus, FiMinus } from 'react-icons/fi'; // Using react-icons library
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 8px;
  padding: 7px;
  width: 248px;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  flex: 1;
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 10px;
  color: #000;
`;

const Card = styled.div`
  background-color: #f5f5f5;
  box-shadow: 0px 5px 10px #00000038;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Segoe UI', 'Semibold';
  border-radius: 27px;
  opacity: 1;
  color: #fff;

  &.add-proof {
    background-color: #293495;
  }

  &.reset {
    background-color: red;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  &.submit {
    background-color: #3d46b4;
    border-radius: 12px;
    box-shadow: 0px 10px 20px #2b349752;
  }

  & > svg {
    margin-left: 8px;
  }
`;

const LocationInput = styled.input`
  border: none;
  border-bottom: 2px solid #3d46b4;
  outline: none;
  font-size: 16px;
  padding: 10px;
  margin-left: 20px;
  width: 200px;
  background: transparent;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: linear-gradient(to right, #e6e6e6, #f2f2f2, #f5f5f5, #f3f3f3, #e9e9e9);
  box-shadow: 0px 10px 20px #0000001a;
  border-radius: 10px;
`;

const TableHeader = styled.th`
  background-color: #e6e6e6;
  color: #000;
  padding: 10px;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #3d46b4;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #293495;
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #293495;
  }
`;
const IconWrapper = styled.span`
  cursor: pointer;
  color: #2B3497;
  margin: 0 5px;
`;

const AddIdentityProofPage = () => {
  const [showInput, setShowInput] = useState(false);
  const [proofName, setProofName] = useState('');
  const [proofs, setProofs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPagination, setShowPagination] = useState(false);

  const rowsPerPage = 7;


  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get('/Idproof');
        if (response.data) {
          // Assuming the API returns an array of proofs
          setProofs(response.data);
        } else {
          setProofs([]); // Ensure proofs is always an array
        }
      } catch (error) {
        console.error('Error fetching proofs:', error);
        setProofs([]); // Ensure proofs is always an array even if the request fails
      }
    };

    fetchLocations();
  }, []);



  const handleAddLocationClick = () => {
    setShowInput(!showInput);
  };

  const handleLocationSubmit = async () => {
    if (proofName.trim() !== '') {
      try {
        const response = await axios.post('/Idproof', { idProofDescription:proofName });
        if (response.status === 200) {
          // Show success toast message
          toast.success('ID Proof added successfully');

          // Fetch the updated list of locations
          const updatedLocationsResponse = await axios.get('/Idproof');
          setProofs(updatedLocationsResponse.data);
          
          // Clear the input field
          setProofName('');
          setShowInput(false); // Close the input field
      } else {
          // Handle unexpected responses
          toast.error('Unexpected response from the server');
      }
  } catch (error) {
      console.error('Error adding Proof:', error);
      toast.error('Error adding Proof');
  }
} else {
  toast.error('Proof name cannot be empty');
}
};


const handleDelete = async (id) => {
  try {
    // Sending the PUT request to mark the Proof as deleted
    await axios.put('/Idproof', {
      id: id,
      idProofDescription: proofs.find(proof => proof.id === id)?.idProofDescription,
      isDeleted: true
    });

    // Update the local state to remove the deleted proof from the UI
    setProofs((prevData) => prevData.filter(proof => proof.id !== id));
        // Show success toast message
  toast.success('ID Proof Deleted Successfully');

  } catch (error) {
    console.error('Error deleting proof:', error);
    // Handle the error (e.g., show a notification to the user)
        // Show error toast message
  toast.error('Failed to Delete proof');

  }
};

  const handleReset = () => {
    setSearchTerm('');
    setProofName('');
    setCurrentPage(1); // Reset pagination to the first page

  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };


  const filteredLocations = proofs.filter(proof => {
    const name = proof.idProofDescription || ''; // Ensure a default value if ProofName is null or undefined
    return name.toLowerCase().includes(searchTerm?.toLowerCase() ?? '');
  });
  

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  

  // Pagination logic
  const itemsPerPage = 7;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLocations.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredLocations.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredLocations.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPageNumbers = Math.min(totalPages, 10); // Show up to 10 page numbers

    let startPage = Math.max(currentPage - 5, 1);
    let endPage = Math.min(startPage + totalPageNumbers - 1, totalPages);

    // Adjust startPage if endPage exceeds totalPages
    if (endPage === totalPages) {
      startPage = Math.max(totalPages - totalPageNumbers + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <PaginationButton
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    return pageNumbers;
  };

  return (
    <Container>
      <Header>
        <Heading>Add Identity Proof</Heading>
        <SearchBox>
          <SearchIcon size={20} />
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </SearchBox>
      </Header>
      <Card>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button className="add-proof" onClick={handleAddLocationClick}>
            Add Identity Proof {showInput ? <FiMinus size={20} /> : <FiPlus size={20} />}
          </Button>
          {showInput && (
            <LocationInput
              type="text"
              placeholder="Enter Identity Proof"
              value={proofName}
              onChange={(e) => setProofName(e.target.value)}
            />
          )}
        </div>
        <ButtonGroup>
          <Button className="reset" onClick={handleReset}>
            Reset
          </Button>
          <Button className="submit" onClick={handleLocationSubmit}>
            Submit
          </Button>
        </ButtonGroup>
      </Card>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <TableHeader>Identity Proof</TableHeader>
              <TableHeader>Action</TableHeader>

            </tr>
          </thead>
          <tbody>
            {currentRows.map((proof, index) => (
              <TableRow key={index}>
                <TableCell>{proof.idProofDescription}</TableCell>
                <TableCell>
                    <IconWrapper onClick={() => alert(`Edit ${proof.idProofDescription}`)}>
                      <FiEdit />
                    </IconWrapper>
                    <IconWrapper onClick={() => handleDelete( proof.id)}>
                      <FiTrash2 />
                    </IconWrapper>
                  </TableCell>

              </TableRow>
            ))}
          </tbody>
        </StyledTable>
        <PaginationContainer showPagination={filteredLocations.length > rowsPerPage}>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              &laquo; Prev
            </PaginationButton>
            {renderPageNumbers()}
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next &raquo;
            </PaginationButton>
          </PaginationContainer>
      </TableContainer>
      <ToastContainer /> 

    </Container>
  );
};

export default AddIdentityProofPage;
