import React, { useState,useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';
const Container = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; /* Adjusted margin-bottom for spacing */
`;

const Heading = styled.h1`
  font-size: 24px;
  font-family: 'Segoe UI', 'Semibold';
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ButtonGroupLeft = styled.div`
  display: flex;
  gap: 10px; /* Space between buttons */
`;

const ButtonGroupRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Space between buttons */
`;

const Button = styled.button`
  background-color: #2B3497;
  color: #FFF;
  box-shadow: 0px 5px 10px #2B349754;
  border-radius: 12px;
  opacity: 1;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #1E2A6D;
  }
  &:active {
    background-color: #162055;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  box-shadow: 0px 10px 20px #0000001A;
  border-radius: 6px;
  opacity: 1;
`;

const TableHeader = styled.thead`
  background: linear-gradient(to bottom, #E6E6E6, #F2F2F2, #F5F5F5, #F3F3F3, #E9E9E9);
  color: #000;
`;

const TableRow = styled.tr``;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
`;

const GroupAppoinmentDetailsPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const location = useLocation();
  const { formattedData } = location.state || {};
  const fileInputRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null); // Store the chosen file
  const [fileUploaded, setFileUploaded] = useState(false); // Track if the file has been uploaded

  const handleChooseFileClick = () => {
    fileInputRef.current.click();

  };

  const handleFileChange = (event) => {
    const chosenFile = event.target.files[0];
    if (chosenFile) {
      setFile(chosenFile); // Save the chosen file
      setFileUploaded(false);
      toast.success('File chosen successfully, please click the upload button to update the table data.!');      // Reset file upload status
    }
  };

  const handleUploadFileClick = () => {
    if (file) {
      readExcelFile(file);
      setFileUploaded(true); // Set file upload status to true
      toast.success('Table updated successfully');
    } else {
      toast.error('Please choose a file first');
    }
  };

  const readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setTableData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSampleTemplateClick = () => {
    const sampleData = [
      {
        "Name(eg:Ben)": "",
        "Mobile Number(eg:1234567890)": "",
        "ID Proof(eg:Passport)": "",
        "ID Number(eg:123322442442)": ""
      }
    ];
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SampleTemplate");

    // Generate Excel file and download it
    XLSX.writeFile(workbook, "SampleTemplate.xlsx");
  };
  useEffect(() => {
    console.log("formattedData",formattedData);
  }, []);

  const handleSubmitClick = async () => {
    console.log("formattedData",formattedData);
    console.log('Table Data:', tableData);
    const empID = localStorage.getItem('employeeId');
    const formData = formattedData
    const formatDateForApi = (date) => {
      return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  };
  


    const requestData = [
      {
        groupLeaderName: formData.groupLeaderName,
        visitorMobile: formData.visitorMobile,
        visitorCompany: formData.company,
        visitorEmail: formData.visitorEmail,
        appointmentStatus: "Scheduled",
        createdBy: Number(empID),
        createdDate: formatDateForApi(formData.meetingFrom),
        modifiedBy: Number(empID),
        modifiedDate: new Date().toISOString(),
        appointmentDate:formatDateForApi(formData.meetingFrom),
        meetingVenue: formData.meetingvenue,
        idProofType: formData.selectedIdProofTypeId,
        idProofNo: formData.idProofNo,
        validTill: formData.meetingTo,
        selectedVisitorTypeId:  Number(formData.selectedVisitorTypeId),
        txtManagersID: "null",
        photo: "",
        txtFloor: "null",
        txtMeetingRoom: "null",
        txtCostCenter: "null",
        gate:  formData.gate,
        building: formData.building,
        purpose: "null",
      },
      ...tableData.map((row) => ({
        groupLeaderName: row['Name(eg:Ben)'] || '',
        visitorMobile: String(row['Mobile Number(eg:1234567890)'] || ''),
        visitorCompany: formData.company,
        visitorEmail: formData.visitorEmail, // Assuming this comes from somewhere else or left empty
        appointmentStatus: "Scheduled",
        createdBy: Number(empID),
        createdDate: formatDateForApi(formData.meetingFrom),
        modifiedBy: Number(empID),
        modifiedDate: new Date().toISOString(),
        appointmentDate:  formatDateForApi(formData.meetingFrom), // Assuming this needs to be current date
        meetingVenue: formData.meetingvenue,
        // idProofType: row['ID Proof(eg:Passport)'] || '',
        idProofType: formData.selectedIdProofTypeId,
        idProofNo: String(row['ID Number(eg:123322442442)'] || ''),
        validTill: formData.meetingTo, // Assuming 1 hour from now
        selectedVisitorTypeId:  Number(formData.selectedVisitorTypeId),
        txtManagersID: "null",
        photo: "",
        txtFloor: "null",
        txtMeetingRoom: "null",
        txtCostCenter: "null",
        gate:  formData.gate,
        building: formData.building,
        purpose: "null",
      })),
    ];

    console.log("Request Data:", requestData);
    try {
      const response = await axios.post('/GroupAppointment/GroupAppointment', requestData);
      console.log("response",response);
      if (response.status === 200) {
        navigate('/layout/GroupCheckIn/MenuItem');
        // Handle the response data, e.g., display details or update state
      }
      

    }catch(error){
      console.error('Error submitting form:', error);

    }
  };

  return (
    <Container>
      <Header>
        <Heading>Group Member Details</Heading>
      </Header>
      <ButtonsContainer>
        <ButtonGroupLeft>
          <Button onClick={handleChooseFileClick}>Choose File</Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".xlsx,.xls"
            onChange={handleFileChange}
          />
          <Button onClick={handleUploadFileClick}>Upload File</Button>
          <Button onClick={handleSampleTemplateClick}>Sample Template</Button>
        </ButtonGroupLeft>
        <ButtonGroupRight>
          <Button disabled={!fileUploaded} onClick={handleSubmitClick}>Submit</Button> {/* Enable Submit only after file upload */}
        </ButtonGroupRight>
      </ButtonsContainer>
      <TableContainer>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Group Member Name</TableHeaderCell>
              <TableHeaderCell>Visitor Mobile</TableHeaderCell>
              <TableHeaderCell>Id Proof Type</TableHeaderCell>
              <TableHeaderCell>Id Proof Number</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row['Name(eg:Ben)'] || ''}</TableCell>
                <TableCell>{row['Mobile Number(eg:1234567890)'] || ''}</TableCell>
                <TableCell>{row['ID Proof(eg:Passport)'] || ''}</TableCell>
                <TableCell>{row['ID Number(eg:123322442442)'] || ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer />
    </Container>
  );
};

export default GroupAppoinmentDetailsPage;
